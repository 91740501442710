import React, { useEffect, useContext, useRef } from 'react';
import { DataContext } from '../components/DataContext';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import getMyFlightsV2 from '../services/getMyFlightsV2';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Fade from 'react-bootstrap/Fade';
import FlightProfileContent from "../components/content/FlightProfileContent";
import PhaseOfFlightContent from "../components/content/PhaseOfFlightContent";
import ExceedanceContent from "../components/content/ExceedanceContent";
import ObstacleProximityContent from "../components/content/ObstacleProximityContent";
import LocContent from "../components/content/LocContent";
import PlaybackContent from '../components/content/PlaybackContent';
import Playback3DContent from '../components/content/Playback3DContent';
import Toolbar from '../components/utils/Toolbar';
import './FlightMetricsReact.css';

const drawerWidth = 450; // 240

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  filterToolbar: {
    display: "flex",
  },
  filterButton: {
    margin: 10,
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    flexGrow: 1
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  formControl: {
    margin: theme.spacing(1),
    width: 150
  },
  selectBodyDarkTheme: {
    borderBottom: '1px solid #fff',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  selectBodyLightTheme: {
    borderBottom: '1px solid #424242',
    '& .MuiSvgIcon-root': {
      color: '#424242',
    },
  },
  parentFlexSplit: {
    display: "flex",
    justifyContent: "space-between"
  },
  leftAlignItem: {
    marginRight: "auto"
  },
  rightAlignItem: {
    marginLeft: "auto"
  },
  centerAlignItem: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  button: {
    margin: "10px"
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  bottomControls: {
    margin: 10,
    bottom: 0,
    left: 0,
    right: 0,
    position: "absolute",
  },
  controlButton: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // background: "#000000",
    border: 0,
    borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: 32,
    padding: '0 30px',
  },
  lightTabs: {
    color: '#000 !important',
    background: '#fff !important',
    borderBottom: '1px solid #dee2e6',
    '&.nav-link.active':{
      background: '#E0E0E0 !important'
    }
  },
  darkTabs: {
    color: '#fff !important',
    background: '#121212 !important',
    borderBottom: '1px solid #dee2e6',
    '&.nav-link.active':{
      background: '#383838 !important'
    }
  },
  // summary: {
  //   // pointerEvents: 'none',
  //   pointerEvents: 'auto',
  // },
  // icon: {
  //   pointerEvents: 'auto',
  // },
}));

const propTypes = {
  user: PropTypes.object.isRequired,
  operator: PropTypes.string,
  flight: PropTypes.object.isRequired,
  onBackToFlights: PropTypes.func,
};

const defaultProps = {
  operator: "FAA",
  onBackToFlights: () => alert("Back to flights capability not yet implemented.")
};

function FlightMetricsReact(props) {
  const { user, operator, flight, onBackToFlights } = props;
  const dataContext = useContext(DataContext);
  const classes = useStyles();
  dataContext.classes.current = classes;
  
  if(dataContext.DEBUG_MODE)
    console.log(flight);

  const styles = {
    customScale: {
      display: "flex",
      transform: `scale(${dataContext.appScale})`,
      transformOrigin: "0 0",
      width: `${100 * (1 / dataContext.appScale)}%`,
      height: `${dataContext.appScale * 100}vh`,
    }
  };

  const toolbarEl = useRef(null);
  const toolbarOffsetTop = useRef(0);
  // const [running, setRunning] = useState(false);

  let runtime = 0;
  let startTime = 0;

  const handleSnackClose = () => {
    dataContext.setOpenSnack(false);
  }

  const getRuntime = (msg = "Performance") => {
    let endTime = performance.now();
    // runtime = Math.round((endTime - startTime) * 1000) / 1000;
    runtime = endTime - startTime;
    if (dataContext.DEBUG_MODE) {
      console.log(`${msg}: ${runtime} ms`);
    }
  }

  const onLoadData = (user, operator, flight) => {
    if (!dataContext.baseData) {
      // Notify processing start to render loading modal overlay on map
      dataContext.setLoadingData(true);
      dataContext.setProcessing(true);

      // TODO maintain list of flights from filter controls going forward
      const flightIds = [flight.flightmetadata__id]

      // setRunning(true);
      runtime = 0;
      startTime = performance.now();

      getMyFlightsV2(user, operator, flightIds).then((result) => {
        if (dataContext.DEBUG_MODE) {
          console.log("MyFlights Service V2 Results:", result);
        }

        getRuntime("Lambda Performance");

        // Extract pre-signed url from result
        const { preSignedUrl, filesize } = result;

        //testing area for chunking
        // Assuming we'll create 4 simul downloads, find the quotient of the filesize.
        let simulDowns = 4
        let fileChunk = Math.floor(filesize / simulDowns)
        // console.log(fileChunk)
        // Construct a series of ranges using fileChunk
        let rangeArray = [];
        for (let i = 0; i < simulDowns; i++) {
          rangeArray.push((i === 0 ? 0 : (fileChunk * i) + 1) + "-" + (i === simulDowns - 1 ? '' : fileChunk * (i + 1)))
        }

        // console.log(rangeArray)

        // let rangeArray = ["bytes=0-10981028", "bytes=10981029-21962056", "bytes=21962057-"]

        if (preSignedUrl) {
          // get 0 bytes to act as a head request, insect returned header for content-length

          let chunkPromises = rangeArray.map(range => {
            return fetch(preSignedUrl, {
              headers: {
                Range: "bytes=" + range
              }
            }).then(result => {
              return result.arrayBuffer();
            }).catch(error => {
              console.error(error);
            });
          });

          Promise.all(chunkPromises).then(chunks => {
            if (dataContext.DEBUG_MODE) {
              console.log({ chunks });
            }

            let blob = new Blob(chunks, {
              type: "application/json"
            })
            let jsonValue = blob.text().then(x => { return JSON.parse(x) });
            return jsonValue;
          }).then((data) => {
            let dataTarget = data[flight.flightmetadata__id];

            if (dataContext.DEBUG_MODE) {
              console.log("Base Data", data);
            }

            // Set main data as selected/active flight (default to first flight in data if active flight doesn't exist)
            dataContext.setBaseData(dataTarget);
            dataContext.buildDynamicColorMaps(dataTarget);
            dataContext.buildReducedDataset(dataTarget);

            // applySavedFilters();
            dataContext.setProcessing(false);
            dataContext.setLoadingData(false);

          }).catch((err) => {
            dataContext.showSnack(
              "top",
              "center",
              `Failed to retrieve data for ${flight.flightmetadata__id} (Network Error)`,
              dataContext.SNACK_SEVERITY.ERROR
            );
            console.error(err);
            dataContext.setProcessing(false);
            dataContext.setLoadingData(false);
          }).finally(() => {
            getRuntime("Overall Performance");
            // setRunning(false);
            dataContext.setProcessing(false);
            dataContext.setLoadingData(false);
          });
        } else {
          // setRunning(false);
          dataContext.setProcessing(false);
          dataContext.setLoadingData(false);

          dataContext.showSnack(
            "top",
            "center",
            `V2.0 data not available for ${flight.flightmetadata__id}`,
            dataContext.SNACK_SEVERITY.ERROR
          );
        }
      }).catch((error) => {
        // setRunning(false);
        dataContext.setProcessing(false);
        dataContext.setLoadingData(false);

        dataContext.showSnack(
          "top",
          "center",
          `Failed to retrieve data for ${flight.flightmetadata__id} (Network Error)`,
          dataContext.SNACK_SEVERITY.ERROR
        );
        console.error(error);
      });
    }
  }

  useEffect(() => {
    if (dataContext.processing) {
      dataContext.setOpenSnack(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.processing]);

  useEffect(() => {
    if (toolbarEl.current) {
      const onScroll = () => {
        // Additional ref check in case component is unmounted
        if (toolbarEl.current) {
          // console.log(`Scroll ( window-scroll-y = ${window.scrollY}; toolbar-top-offset = ${toolbarOffsetTop.current})`);
          if (window.scrollY > toolbarOffsetTop.current) {
            toolbarEl.current.classList.add("sticky");
          } else {
            toolbarEl.current.classList.remove("sticky");
          }
        }
      }

      const onResize = () => {
        // Additional ref check in case component is unmounted
        if (toolbarEl.current) {
          // Don't allow 0 offset (breaks dynamic positioning of toolbar component)
          if (toolbarEl.current.offsetTop !== 0) {
            // Track updated toolbar offset
            toolbarOffsetTop.current = toolbarEl.current.offsetTop;
            // console.log(`Window resized. New toolbar top-offset = ${toolbarOffsetTop.current}`);
          }
        }
      }

      // Custom window scroll and resize events for smooth/dynamic position of the toolbar component
      toolbarOffsetTop.current = toolbarEl.current.offsetTop;
      window.onscroll = onScroll;
      window.onresize = onResize;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarEl.current]);

  useEffect(() => {
    // EXPERIMENTAL (Produces odd behavior with map tooltips - usage is not recommended)
    // document.body.style.zoom = 0.80;

    // Invoke data load function on page load/render
    if (dataContext.DEBUG_MODE) {
      console.log(`Loading Data for Flight: ${flight.flightmetadata__id}...`);
    }

    // Set reference to "back to flights" function from props to data context
    dataContext.setBackToFlightsFn(onBackToFlights);

    dataContext.setActiveFlight(flight.flightmetadata__id);
    onLoadData(user, operator, flight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* NOTE: Snackbar must be outside of root div due to scaling transform breaking fixed position of snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: dataContext.snackVertical, horizontal: dataContext.snackHorizontal }}
        open={dataContext.openSnack}
        onClose={handleSnackClose}
        key={dataContext.snackVertical + dataContext.snackHorizontal}
        autoHideDuration={dataContext.snackDuration}
      >
        <Alert
          onClose={handleSnackClose}
          severity={dataContext.snackSeverity}
          style={dataContext.snackColor}
          sx={{ width: '100%' }}
        >
          {dataContext.snackMessage}
        </Alert>
      </Snackbar>

      <Toolbar
        onRef={ref => toolbarEl.current = ref}
        style={{
          transform: styles.customScale.transform,
          transformOrigin: styles.customScale.transformOrigin,
          width: styles.customScale.width,
          background: dataContext.darkMode ? '#121212' : '#fff',
          zIndex: 1000, // Float over all widgets in app
        }}
      />

      <div
        className='content'
        style={styles.customScale}
      >
        <CssBaseline />
        <main
          style={{ width: "100%", marginLeft: 10, marginRight: 10 }}
        >
          <Tabs
            defaultActiveKey="flightProfile"
            id="raise-dashboard-tabs"
            unmountOnExit={true} // Unmount tabs (remove it from the DOM) when it is no longer visible (if true)
            transition={Fade}
            variant="tabs"
            tabindicatorprops={{style:{backgroundColor:'#ff0000'}}}
            // onChange={e => console.log(`Changing tab to ${e.target.value}`)}
            onSelect={dataContext.preventUIBlocking}
          >
            <Tab
              
              eventKey={dataContext.FLIGHT_PROFILE_TAB_KEY}
              title="Flight Profile"
              tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
            >
              <FlightProfileContent />
            </Tab>
            <Tab
              eventKey={dataContext.PHASE_OF_FLIGHT_TAB_KEY}
              title="Phase of Flight"
              tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
            >
              <PhaseOfFlightContent />
            </Tab>
            <Tab
              eventKey={dataContext.EXCEEDANCE_TAB_KEY}
              title="Exceedance"
              tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
            >
              <ExceedanceContent />
            </Tab>
            <Tab
              eventKey={dataContext.OBSTACLE_PROXIMITY_TAB_KEY}
              title="Obstacle Proximity"
              tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
            >
              <ObstacleProximityContent />
            </Tab>
            <Tab
              eventKey={dataContext.LOC_DASHBOARD_TAB_KEY}
              title="Loss of Control"
              tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
            >
              <LocContent />
            </Tab>
            <Tab
              eventKey={dataContext.PLAYBACK_TAB_KEY}
              title="Playback"
              tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
            >
              <PlaybackContent />
            </Tab>
           <Tab
              eventKey={dataContext.PLAYBACK3D_TAB_KEY}
              title="Playback3D"
              tabClassName={dataContext.darkMode ? classes.darkTabs : classes.lightTabs}
            >
              <Playback3DContent />
            </Tab>
             
          </Tabs>
        </main>
      </div>
    </>

  );
}

FlightMetricsReact.propTypes = propTypes;
FlightMetricsReact.defaultProps = defaultProps;

export default FlightMetricsReact;
