import React, { useContext, useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
// import { API } from "aws-amplify";
import PropTypes from 'prop-types';
import { DataContext } from '../DataContext';
import UserContext from '../UserContext'
import getMyFlights from '../../services/getMyFlights';

import SearchFacet from '../SearchFacet';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
// import UndoIcon from '@mui/icons-material/Undo';
// import RedoIcon from '@mui/icons-material/Redo';
// import ReplayIcon from '@mui/icons-material/Replay';
// import Button from "@mui/material/Button";
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
// import SettingsIcon from '@mui/icons-material/Settings';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { Link } from 'react-router-dom';
// import Slide from '@mui/material/Slide';
// import SaveIcon from '@mui/icons-material/Save';
// import VerticalDivider from './VerticalDivider';
// import AddList from '../AddList';

// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';
import { Popper } from '@mui/material';
import getDocument from '../../services/getDocument';
// import circle from '@turf/circle';

// import { makeStyles } from '@mui/styles';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// Placeholder for defining add list prop types
const AddListPropsType = () => null;

AddListPropsType.propTypes = {
  items: PropTypes.array,
  onRemoveItem: PropTypes.func,
  onAddItem: PropTypes.func,
  originalItems: PropTypes.array,
  dashboardKey: PropTypes.string,
}

const propTypes = {
  AddListProps: AddListPropsType,
  onLayoutSave: PropTypes.func,
  onRef: PropTypes.func,
  excludeBackToFlightsLink: PropTypes.bool,
  style: PropTypes.object,
};

const defaultProps = {
  style: {},
  onRef: (ref) => ref.current,
  excludeBackToFlightsLink: false,
};

const styles = {
  container: {
    display: "flex",
  },
  content: {
    flex: 1,
  }
}


const HelipadsRadiusBar = forwardRef((props, ref) => {

  // ForwardRef method
  useImperativeHandle(ref, () => ({
    resetSearch()  {
      console.log("Resetting geo stuff")
      setRadius(0)
      dataContext.setSearchRadius(0);
      setSelHelipad({label: '', id: '', name: '', lat: '', lon: ''})
      dataContext.setHelipadInfo([-98.5795, 39.8283, '', ''])
  }
  }));
  const {
    style,
  } = props;

  const dataContext = useContext(DataContext);
  const userContext = useContext(UserContext);


  // const toolbarRef = useRef(null);

  // const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  const radiusRef = useRef();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selHelipad, setSelHelipad] = React.useState({label: '', id: '', name: '', lat: '', lon: ''})
  const loading = open && options.length === 0;

  const [radius, setRadius] = useState(0);
  // const [statusMsg, setStatusMsg] = useState(null);
  // const [searchedNumber, setSearchedNumber] = useState(0);

  const airhelipadJsonFileName = "aphelipads.json";
  // const defaultHelipad = { label: 'NJ48', id: "10133", name: "Golden Nugget Atlantic City", lat: "39-22-46.4200N", lon: "074-25-39.5360W" };

  // const helipadsList = [
  //   { label: 'NJ48', name: "Golden Nugget Atlantic City", lat: "39-22-46.4200N", lon: "074-25-39.5360W" },
  //   { label: '89NJ', name: "Strawberry Fields", lat: "39-29-21.0000N", lon: "074-43-25.0000W" },
  //   { label: 'MMU', name: "Morristown Muni", lat: "40-47-57.6180N", lon: "074-24-53.5990W" }
  // ];

  const customPopover = function(props){
    return <Popper {...props} placement="bottom-start" container={document.fullscreenElement ? document.fullscreenElement : document.body} />;
  };

  useEffect(() => {
    
    if(dataContext.mapLoadSearch)
      defaultSearchExec()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.mapLoadSearch]);


  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (options.length <= 0) {
      const airhelipadJsonUrl = getDocument(userContext.user, "RUNWAYS")
      (async () => {

        if (active) {
          await fetch(airhelipadJsonUrl).then((result) => result.json()).then((data) => {
            data["rowdata"] = [{label: '', id: '', name: '', lat: '', lon: ''}, ...data["rowdata"]]
            console.log(data["rowdata"])

            setOptions([...data["rowdata"].filter(row => {
              return (row["label"].trim() !== '')
          }).sort((a, b) => a['label'] > b['label'] ? 1 : -1)]);

          });
        }
      })();
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // useEffect(() => {
  //   if (!open) {
  //     // setOptions([]);  
  //   }
  // }, [open]);


  ////////For the event of circle drawing on the map, then update the radius text field///////
  useEffect(() => {

    setRadius(dataContext.searchRadius);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.searchRadius]);


  ////////For the event of circle drawing on the map, then search flights///////
  useEffect(() => {
    if(dataContext.DEBUG_MODE)
      console.log(dataContext.circleGeoCoords);

    if (dataContext.drawMode === dataContext.DRAW_MODE.CIRCLE && dataContext.circleGeoCoords.length > 0) {
      //Make sure remove "no found status msg"
      // setStatusMsg(null);
      //Show the searching circle progress 
      dataContext.setSearchStatus(true);
      //Start to search by coords
      props.geoSearchFlightsByPolygon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.circleGeoCoords]);

 

 
  const convertLatLongStrToDouble = (valueStr) => {

    let valueNs = valueStr.split("-");
    let nw = valueNs[2].slice(-1);
    let latlonV = parseInt(valueNs[0]) + parseFloat(valueNs[1]) / 60 + parseFloat(valueNs[2].slice(0, -1)) / 3600;
    let latlon = Math.round(latlonV * 1000000) / 1000000;

    if (nw === "W" || nw === "S")
      latlon = (-1) * latlon


    return latlon;
  }

  const onHelipadListChange = (event, value) => {

    if (value) {
      // if(dataContext.DEBUG_MODE)
        console.log(value);

      //console.log(value);
      let latS = value["lat"];
      let latV = convertLatLongStrToDouble(latS);

      let lonS = value["lon"];
      let lonV = convertLatLongStrToDouble(lonS);

      if(dataContext.DEBUG_MODE)
        console.log([lonV, latV]);
        props.setLng(lonV)
        props.setLat(latV)
        setSelHelipad(value)
      dataContext.setHelipadInfo([lonV, latV, value["label"], value["name"]]);
      // dataContext.setSearchRadius(0);
      dataContext.setShowNoOperatorFlights(false);
      // setSearchedNumber(0);
      // setStatusMsg(null);
    }

  }

  const onRangeChange = (e) => {
    if(dataContext.DEBUG_MODE)
      console.log(e.target.value);
      
    setRadius(e.target.value);

    dataContext.setDrawMode(dataContext.DRAW_MODE.INPUT); //Set the drawing mode to be text input instead of drawing graphics 
    //dataContext.setRenderFlightIds([]); //Clear rendered flights in memory
    dataContext.setSearchRadius(e.target.value);

    // console.log(dataContext.helipadInfo);

    // let center = [];
    // center[0] = dataContext.helipadInfo[0];
    // center[1] = dataContext.helipadInfo[1];
    // let radius = parseFloat(e.target.value) * 1.60934; //miles to kms
    // let options = {steps: 60, units: 'kilometers', properties: {"ident": dataContext.helipadInfo[2]}};
    // console.log(options);
    // let circle1 = circle(center, radius, options);

    // console.log(circle1);
    
     dataContext.setShowNoOperatorFlights(false);
    //  setStatusMsg(null);
     
  }

  const geoSearchFlightsByPolygonRetired = () => {
    
    dataContext.setSearchStatus(true);

      props.searchObj["polygon"] = dataContext.circleGeoCoords

      console.log(props.searchObj)
      getMyFlights(userContext.user, "", props.searchObj).then((resultsMyF) => {
        if (dataContext.DEBUG_MODE)
          console.log(resultsMyF)

        if (resultsMyF) {
          //Create mimic of original geosearch strucutre for useEffect chain
          let tempResultList = []
          // Scrub nan out of dq_errors
          resultsMyF.forEach((item) => {
            if (item['dq_errors'] === 'nan')
              item['dq_errors'] = '0'

            tempResultList.push(item)

          })
          dataContext.setSearchedFlights(tempResultList);
          dataContext.searchedFlightsMeta.current = resultsMyF

        }
        else {
          onNotFound();
        }

      })
      


  }

  // const onSearchButtonClick = () => {

  //   //Remove the red status message
  //   let curRadius = radiusRef.current.value;
    
  //   if(dataContext.DEBUG_MODE)
  //     console.log(curRadius);

  //   dataContext.setShowNoOperatorFlights(false);
  //   setStatusMsg(null);

  //   if (curRadius > 0) {

  //     dataContext.setSearchRadius(curRadius);
  //     dataContext.setSearchStatus(true);
  //     dataContext.setSearchedFlights([]);
  //     dataContext.setBaseData({});
  //     dataContext.setBackToGeoSearchMode(false);

      
  //   }
  //   geoSearchFlightsByPolygon();
  // }


  // const defaultSearchExec = () => {

  //   //Remove the red status message
  //   let curRadius = 2000;
    
  //   if(dataContext.DEBUG_MODE)
  //     console.log(curRadius);

  //   dataContext.setShowNoOperatorFlights(false);
  //   setStatusMsg(null);
  //   console.log("Status 1")
  //   if (curRadius > 0) {
  //     console.log("Status 2")
  //     dataContext.setSearchRadius(curRadius);
  //     dataContext.setSearchStatus(true);
  //     dataContext.setSearchedFlights([]);
  //     dataContext.setBaseData({});
  //     dataContext.setBackToGeoSearchMode(false);
  //     const waitForCoords = setTimeout(() => {
  //       console.log("Status 3")
  //       // Hack to wait for polygon coords to be done
  //       defaultSearchExec2();
  //     }, 5000);
      
  //   }
  //   else
  //     onNotFound();
  // }

  const defaultSearchExec = () => {
    
    
      // let authUser = userContext.user;

      // let testCoords = [[-74.112, 40.934], [-73.714, 41.107],[-73.416,40.972],[-73.532,40.708],[-73.935,40.699],[-74.074,40.833]];
      // console.log(dataContext.circleGeoCoords);
      // Use a default search package, defaultSearch should only ever be used when making a default search, and left blank otherwise
      // const token = authUser.signInUserSession.idToken.jwtToken;
      const requestInfo = {
          defaultQuery: true
      }
      // console.log(requestInfo)
      dataContext.setSearchStatus(true);
      getMyFlights(userContext.user, "", requestInfo).then((resultsMyF) => {
        if (dataContext.DEBUG_MODE)
          console.log(resultsMyF)

          if (resultsMyF) {
            //Create mimic of original geosearch strucutre for useEffect chain
          let tempResultList = []
        // Scrub nan out of dq_errors
        resultsMyF.forEach((item) => {
          if(item['dq_errors'] === 'nan')
            item['dq_errors'] = '0'

          tempResultList.push(item)  

        })
        dataContext.setSearchedFlights(tempResultList);
        dataContext.searchedFlightsMeta.current = resultsMyF

      }
      else {
        onNotFound();
      }
      
    });

  }

  const onNotFound = () => {
    // let statusStr = `No flight found`;
    // let statusJSX = <p style={{color: 'red', paddingTop: 7}}>{statusStr}</p>
    // setStatusMsg(statusJSX);
    // setSearchedNumber(0);

    dataContext.setSearchStatus(false);
    dataContext.setFoundFlightCount(0);
    dataContext.setSearchedFlights([]);
    dataContext.setBaseData({});
  }

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={styles.content} >
      {/*<Typography variant="caption" display="block" sx={{color: '#eee'}} gutterBottom>
        Select a helipad indent, input radius or draw a circle on the map to search flights
      </Typography>*/}
      
      <SearchFacet title="Geospatial"
                      groupName="geospatial"
                      groupText="Search flights by geography"
                      >
       {/* <Stack spacing={2} direction="column" sx={{pt: 2, pb: 1}}>
        */}
       {/* {dataContext.searchStatus?(
            <div><CircularProgress size={30}/>
              <Typography variant="caption" color="secondary" sx={{pt: -1, pl: 1}} gutterBottom>
                Searching Flights...
              </Typography>
            </div>
          ):<div></div>}
             {dataContext.showNoOperatorFlights? (<p style={{color: 'red', paddingTop: 7}}>{'No flight found'}</p>):statusMsg} */}

        {/*Select A Helipad and Radius:*/}
          <div style={{ display: 'block' }}>

            <div className='inputLabel' style={{ width: '100%', display: 'block', border: 'gray', borderStyle: 'dashed', borderWidth: 'thin' }}>
            <div style={{marginTop:'5px'}}>Point/Radius Search <Tooltip title="Ctrl-Click the map to select a Lat/Lon"><HelpIcon sx={{ fontSize: 17 }} /></Tooltip></div>
              <div className='inputLabel' style={{ width: '100%', display: 'flex' }}>
                <div className='inputLabel' style={{ width: '100%', display: 'block', paddingLeft: '10px' }}>Point
                  <div className='inputLabel' style={{ width: '100%', display: 'flex', paddingLeft: '10px' }}>
                    <div className='inputLabel' style={{ width: '100%' }}>Lat
                      <TextField
                        style={{ width: "100%", marginTop: '8px' }}
                        id="Latitude"
                        
                        sx={{
                          "& .MuiInputBase-input": {
                            outline: "none !important",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={(evt) => {
                          console.log(evt.target.value)
                          props.setLat(evt.target.value)}}
                        value={props.lat}
                      />
                    </div>
                    <div className='inputLabel' style={{ width: '100%' }}>Lon
                      <TextField
                        style={{ width: "100%", marginTop: '8px' }}
                        id="Longitude"
                        sx={{
                          "& .MuiInputBase-input": {
                            outline: "none !important",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={(evt) => {
                          console.log(evt.target.value)
                          props.setLng(evt.target.value)}}
                        value={props.lng}
                      />
                    </div>
                  </div>
                </div>
                <div className='inputLabel' style={{ width: '100%', display: 'block', paddingLeft: '10px' }}>Preset Points
                  <div className='inputLabel' style={{ width: '100%', display: 'block', paddingLeft: '10px' }}>Helipad

                    <Autocomplete
                    PopperComponent={customPopover}
                      value={selHelipad || null}
                      id="helipadsList"
                      style={{ width: "100%", marginTop: '8px' }}
                      size="small"
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      onChange={onHelipadListChange}
                      // defaultValue = {defaultHelipad}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      getOptionLabel={(option) => option.label}
                      options={options}
                      loading={loading}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="searchtext"
                          {...params}

                          sx={{
                            "& .MuiInputBase-input": {
                              outline: "none !important",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            

            {/* </div> */}
            <div style={{ display: 'flex' }}>

              <div className='inputLabel'>Radius (miles)</div>
              <TextField
                style={{ width: "100%", marginTop: '8px' }}
                id="searchRadius"
                inputRef={radiusRef}
                sx={{
                  "& .MuiInputBase-input": {
                    outline: "none !important",
                  },
                }}
                size="small"
                variant="outlined"
                onChange={onRangeChange}
                value={radius}
              />

              {/* <TextField 
            id="searchResults" 
            label="Searched Flights Number"
            size="small" 
            variant="outlined"
            sx={{
                  "& .MuiInputBase-input": {
                     outline: "none !important",
                  },
                }}
            InputProps={{
                readOnly: true,
              }}
            value={searchedNumber}
            /> */}
              {/*<p style={{color: 'red', paddingTop: 7}}>{statusMsg}</p>*/}
            </div>
            </div>
          </div>
        </SearchFacet>
        {/* Header Components Start */}
        {/* Reference: https://mui.com/components/menus/#account-menu */}
        {/* Header Components End */}

      </div>


    </div>
  );
})

HelipadsRadiusBar.propTypes = propTypes;
HelipadsRadiusBar.defaultProps = defaultProps;

export default HelipadsRadiusBar;
