import React, { useState, useEffect, useContext, useRef } from 'react'
import { Auth, Hub } from 'aws-amplify'
import Router from './components/Router'
import UserContext, { UserProvider } from './components/UserContext'
import getUserGroups from './services/getUserGroups'
import { IdleTimeOutModal } from './components/IdleModal'
import {useIdleTimer} from 'react-idle-timer';
// import CustomThemeProvider from './components/styles/CustomThemeProvider';

import './App.css'

function App(props) {

  const timeToWarn = 1000 * 60 * 13 // 13 minutes
  const timeToLogout = 1000 * 60 * 2 // 2 minutes
  // const [timeout, setTimeout] = useState(timeToWarn)

  const [showModal, setShowModal] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false)
  const lastRefTime = useRef(new Date().getTime())

  // var idleTimer = null

  const userContext = useContext(UserContext);

  useEffect(() => {
    Hub.listen('auth', (data) =>{
      const { payload } = data;
      onAuthEvent(payload)
    }); 

    // Check if the user should be kicked out for having been away for a while
    let lastLoggedTime = localStorage.getItem("raiseLastActiveTime")
    // console.log("Haha test" + lastLoggedTime)
    if (!lastLoggedTime) {
      // console.log("No Last Logged Logout");
      handleLogout();
    }
    else { // Kick off user if the last active time was more than 15 minutes
      // console.log(lastLoggedTime)
      let timeDiff = Math.abs(new Date().getTime() - new Date(lastLoggedTime).getTime())
      // console.log(timeDiff)
      // console.log(timeToWarn + timeToLogout)
      // console.log(timeDiff)
      // If the stamp is older than the logout time, execute logout
      if (timeDiff > timeToWarn + timeToLogout) {
        // console.log("Timeout Logout")
        handleLogout();
      }
    }



    return () => {

    }
  }, []);

  useEffect(() => {
    // console.log("Boy Howdy Now")
    userContext.setPauseTimer(() => pause)
    userContext.setResumeTimer(() => resume)
  }, [userContext.isLoaded]);


  function onAuthEvent(payload){
    // console.log(payload)
    if(payload['event'] === "signIn"){
      userContext.updateCurrentUser()
    }
  }

  

  const onAction = (e) => {
    // console.log("Action");
    if (showModal) {
      setShowModal(false)
      
    }
    userContext.updateUserInter()
    refreshJwt()
    reset()
    localStorage.setItem("raiseLastActiveTime", new Date())
  }

  const onActive = (e) => {
    // console.log("Active")
    if (showModal) {
      setShowModal(false)
      
    }
    userContext.updateUserInter()
    refreshJwt()
    reset()
    localStorage.setItem("raiseLastActiveTime", new Date())
  }

  const refreshJwt = () => {
    if(new Date().getTime() - lastRefTime.current > 300000){
      Auth.currentSession()
      lastRefTime.current = new Date().getTime()
    }

  }

  const onIdle = () => {
    // console.log('user is idle', isTimedOut)
    // console.log('User is ', userContext.user)
    // console.log("Idle Logout")
    handleLogout();
    }

  

  const onPrompt = () => {
    // console.log("Prompt")
    if (userContext.user) {
      // Set the timeout to the warning time out so it waits 2 more minutes, display the modal warning
      // console.log(userContext.user)
      // setTimeout(timeToLogout)
      setShowModal(true)
      // if(idleTimer){
      //   console.log("Can reset")
      //   idleTimer.reset();
      // }
      
      // setIsTimedOut(true)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleLogout = () => {
    // console.log("handleLogout Function Logout");
    Auth.signOut();
    // this.context.handleLogout();
    // console.log(this.state.currentUser)
    setShowModal(false)

    setShowModal(false)
    if (userContext.isLoaded) {
      // TODO Fix this
      // userContext.setIsLoaded(false)
      window.location.reload();
    }
  }

  const {reset, pause, resume} = useIdleTimer({
    onIdle, 
    onActive, 
    onAction, 
    onPrompt, 
    timeout: timeToWarn, 
    promptTimeout: timeToLogout,
    crossTab:true,
    element:document,
    throttle:500,
  })



    return (
      <>

        <div className="App">
          <Router />
        </div>

        <IdleTimeOutModal
          showModal={showModal}
          handleClose={handleClose}
          handleLogout={handleLogout}
        />
      </>

    );
  
}

export default App

