/**
 * Author: Brett Allen
 * Created: 2021-12-31
 * Description:
 *  Data context provider to serve data from a global scope with a state-like fashion.
 *  Using this context will enable the use of the same data across all child components
 *  that subscribe to the context.
 */
import { ConstructionOutlined } from "@mui/icons-material";
import React, { createContext, useState, useRef, useEffect } from "react";
import tinycolor from "tinycolor2"; // For color conversions

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const DataContext = createContext();

// This context provider is passed to any component requiring the context
export const DataProvider = ({ children }) => {
  // const classes = useStyles();
  const classes = useRef(null);

  const isMounted = useRef(false);
  const controlsExpandedRef = useRef(false);
  const uiBlockTimeoutRef = useRef(null);
  const filterWidgetNotifierRef = useRef({});
  const groupedDataRef = useRef({});
  const selectedFiltersRef = useRef({});
  const backToFlightsRef = useRef(null);

  // Maps (required references for syncing viewports)
  const currentMapRef = useRef(null); // Track which map is currently moving
  const groundSpeedMapRef = useRef(null);
  const aglMapRef = useRef(null);
  const exceedanceTypeMapRef = useRef(null);
  const exceedanceSeverityMapRef = useRef(null);
  const locTypeMapRef = useRef(null);
  const locSeverityMapRef = useRef(null);

  // Constants
  const DEBUG_MODE = false && process.env.REACT_APP_USER_BRANCH === 'dev'; // Only allow debug mode in dev
  const BASE_APP_SCALE = 0.8; // Ratio for how large the app widgets render (e.g., 0.8 = 80% scale)
  const LS_KEY = "my-flights-metrics-react";

  const MAP_MARKER_RADIUS = 6; // Controls base size of markers/points on maps
  const MAP_MARKER_WEIGHT = 1; // Size muliplier that controls how small/large the markers/points are on maps at varying zoom levels
  const MAP_MAX_ZOOM = 27; // Maximum zoom-in level for maps

  const TRACK_POINT_NOISE_THRESHOLD = 1000; // In milliseconds; e.g., 1000 = 1 second(s)
  const SCATTER_PLOT_NOISE_THRESHOLD = 250; // In milliseconds; e.g., 250 = 0.25 second(s)
  const MAX_BARCHART_DATAPOINT_WIDTH = 30; // Control maximum size of bars in bar charts

  const END_POINT_RADIUS_ADD = 5;
  const END_POINT_WEIGHT_ADD = 2;
  const FLIGHT_START_POINT_COLOR = '#32cd32';
  const FLIGHT_END_POINT_COLOR = '#FF0000';
  const START_END_SYMBOL_FLAG = false;

  const DEFAULT_SNACK_DURATION = 6000; // Milliseconds
  const FILTER_TIMEOUT_MILLIS = 2000; // Milliseconds

  // FOR DEMO PURPOSES ONLY
  const flightIds = [
    "S76D1_20210804T193025_20210804T195524",
    // "S76D1_20210804T185139_20210804T185436",
  ];

  const SNACK_SEVERITY = {
    ERROR: 0,
    WARNING: 1,
    INFO: 2,
    SUCCESS: 3
  };

  const SNACK_COLORS = {
    ERROR: {
      color: '#FFF',
      backgroundColor: '#D32F2F'
    },
    WARNING: {
      color: '#FFF',
      backgroundColor: '#ED6C02'
    },
    INFO: {
      color: '#FFF',
      backgroundColor: '#0288D1'
    },
    SUCCESS: {
      color: '#FFF',
      backgroundColor: '#2E7D32'
    },
  }

  const CHART_FONTS = {
    fontFamily: 'Roboto',
    fontSize: 14,
    legendFontSize: 12,
    fontWeight: 400,
  }

  const styles = {
    darkTheme: {
      background: "#424242",
      color: "#fff",
    },
    lightTheme: {
      background: "#fff",
      color: "#000",
    },
    buttonPrimary: {
      ':hover': {
        bgcolor: '#2E3B84', // theme.palette.primary.main
      },
      '&.Mui-disabled': {
        bgcolor: '#E0E0E0'
      },
      color: 'white',
      background: "#3F51B5",
    },
    buttonSecondary: {
      ':hover': {
        bgcolor: '#B3003E', // theme.palette.secondary.main
      },
      '&.Mui-disabled': {
        bgcolor: '#E0E0E0'
      },
      color: 'white',
      background: "#F50057",
    },
  };

  // Dashboard filter keys for saving/loading filters to/from local storage
  const DARK_MODE_KEY = "darkMode";
  const VRS_FILTERS_KEY = "savedVrsFilters";
  const FLIGHT_PROFILE_FILTERS_KEY = "savedFlightProfileFilters";
  const EXCEEDANCE_FILTERS_KEY = "savedExceedanceFilters";

  // Flight Metrics Dashboard Tabs
  const FLIGHT_PROFILE_TAB_KEY = "flightProfile";
  const PHASE_OF_FLIGHT_TAB_KEY = "phaseOfFlight";
  const EXCEEDANCE_TAB_KEY = "exceedance";
  const OBSTACLE_PROXIMITY_TAB_KEY = "obstacleProximity";
  const LOC_DASHBOARD_TAB_KEY = "loc";
  const LTE_DASHBOARD_TAB_KEY = "lte";
  const PLAYBACK_TAB_KEY = "playback";
  const PLAYBACK3D_TAB_KEY = "playback3d"

  // UAS Aggregate Metrics Dashboard Tabs
  const UAS_RISK_ANALYSIS_TAB_KEY = "uasRiskAnalysis";
  const SUAS_SIGHTINGS_TAB_KEY = "sUasSightings";

  // UIMC Aggregate Metrics Dashboard Tabs
  const UIMC_EVENTS_TAB_KEY = "uimcEvents";
  
  // Approach Flights Metrics Dashboard Tabs
  const APPROACH_FLIGTHS_TAB_KEY = "approachFlightsAnalysis";

  // Keys to track and manage selected filters for each FilterSelectWidget instance
  const PHASE_OF_FLIGHT_SELECT_KEY = "phaseOfFlightSelect";
  const EXCEEDANCE_TYPE_SELECT_KEY = "exceedanceTypeSelect";
  const EXCEEDANCE_SEVERITY_SELECT_KEY = "exceedanceSeveritySelect";
  const LOC_TYPE_SELECT_KEY = "locTypeSelect";
  const LOC_SEVERITY_SELECT_KEY = "locSeveritySelect";
  const LTE_VALUE_SELECT_KEY = "lteValueSelect";
  const LTE_DRIFT_ANGLE_SELECT_KEY = "lteDriftAngleSelect";
  const OBSTACLE_TYPE_SELECT = "obstacleTypeSelect";
  const OBSTACLE_THREAT_SELECT = "obstacleThreatSelect";
  const VRS_SELECT_KEY = "vrsSelect";

  // UAS-RA
  const UAS_RISK_ANALYSIS_SELECT_KEY = "uasRiskAnalysisSelect";
  const UAS_RISK_ANALYSIS_CONTENT_SELECT_KEY = "uasRiskAnalyisContentSelect";

  // UIMC
  const UIMC_EVENTS_CONTENT_SELECT_KEY = "uimcEventsContentSelect";
  const UIMC_EVENTS_ACFT_TYPE_SELECT_KEY = "uimcEventsAcftTypeSelect";
  const UIMC_EVENTS_YEAR_MONTH_SELECT_KEY = "uimcYearMonthSelect"
  const UIMC_EVENTS_FACILITY_SELECT_KEY = "uimcEventsFacilitySelect";
  const UIMC_EVENTS_ICAO_CAT_SELECT_KEY = "uimcEventsIcaoCategorySelect";
  const UIMC_EVENTS_ICAO_SUB_CAT_SELECT_KEY = "uimcEventsIcaoSubCategorySelect";
  const UIMC_EVENTS_DAYLIGHT_SELECT_KEY = "uimcEventsDaylightSelect";
  const UIMC_EVENTS_STATE_SELECT_KEY = "uimcEventsStateSelect";
  const UIMC_EVENTS_REGION_SELECT_KEY = "uimcEventsRegionSelect";
  const UIMC_EVENTS_SEASON_SELECT_KEY = "uimcEventSeasonSelect";
  const UIMC_EVENTS_TIME_SELECT_KEY = "uimcEventsTimeSelect";
  const UIMC_EVENTS_AIRSPACE_CLASS_SELECT_KEY = "uimcEventsAirspaceClassSelect";
  const UIMC_EVENTS_AIRSPACE_NAME_SELECT_KEY = "uimcEventsAirspaceNameSelect";
  const UIMC_EVENTS_SUBCATEGORY_SELECT_KEY = "uimcEventsSubcategorySelect";
  const UIMC_EVENTS_FLIGHT_RULE_SELECT_KEY = "uimcEventsFlightRuleSelectKey";

  const UIMC_EVENTS_CONFIDENCE_RANGE_SELECT_KEY = "uimcConfidenceIntervalKey";

  // sUAS Sightings
  const SUAS_SIGHTINGS_TOP_AIRPORT_SELECT_KEY = "suasSightingsTopAirportSelect";
  const SUAS_SIGHTINGS_TOP_ICAO_SELECT_KEY = "suasSightingsTopIcaoSelect";
  const SUAS_SIGHTINGS_CONTENT_SELECT_KEY = "suasSightingsContentSelect";
  const SUAS_SIGHTINGS_STATE_SELECT_KEY = "suasSightingsStateSelect";
  const SUAS_SIGHTINGS_CITY_SELECT_KEY = "suasSightingsCitySelect";

  // Track related filters based on select keys
  const FILTER_GROUP_ID_LOOKUP = {
    [EXCEEDANCE_TYPE_SELECT_KEY]: 0,
    [EXCEEDANCE_SEVERITY_SELECT_KEY]: 0,
    [LOC_TYPE_SELECT_KEY]: 1,
    [LOC_SEVERITY_SELECT_KEY]: 1,
    [OBSTACLE_TYPE_SELECT]: 2,
    [OBSTACLE_THREAT_SELECT]: 2,
    [LTE_VALUE_SELECT_KEY]: 3,
    [LTE_DRIFT_ANGLE_SELECT_KEY]: 3,
    [UIMC_EVENTS_FACILITY_SELECT_KEY]: 4,
    [UIMC_EVENTS_TIME_SELECT_KEY]: 4,
    [UIMC_EVENTS_DAYLIGHT_SELECT_KEY]: 4,
    [UIMC_EVENTS_STATE_SELECT_KEY]: 4,
    [UIMC_EVENTS_REGION_SELECT_KEY] : 4,
    [UIMC_EVENTS_SEASON_SELECT_KEY] : 4,
    [UIMC_EVENTS_ACFT_TYPE_SELECT_KEY]: 4,
    [UIMC_EVENTS_YEAR_MONTH_SELECT_KEY]: 4,
    [UIMC_EVENTS_ICAO_SUB_CAT_SELECT_KEY]: 4,

    [UIMC_EVENTS_AIRSPACE_CLASS_SELECT_KEY]: 4,
    [UIMC_EVENTS_AIRSPACE_NAME_SELECT_KEY]: 4,
    [UIMC_EVENTS_SUBCATEGORY_SELECT_KEY]: 4,
    [UIMC_EVENTS_FLIGHT_RULE_SELECT_KEY]: 4,
    [UIMC_EVENTS_CONFIDENCE_RANGE_SELECT_KEY]: 4,

  };

  //Drawing mode on the map
  const DRAW_MODE = {
    NODRAWING: -1,
    INPUT: 0,
    CIRCLE: 1,
    LINE: 2,
    POLYGON: 3
  };

  // Configure dashboard widgets default layout
  // NOTE: Modify this configuration object to control which widgets are included in each dashboard tab and how they're placed by default.
  //       The users' app cache takes precedence over this default layout. Be sure to clear the app cache if testing any default layout changes.
  const flightMetricsDashboardConfig = {
    [FLIGHT_PROFILE_TAB_KEY]: {
      flightMetadata: {
        w: 4,
        h: 4,
        x: 0,
        y: 0,
        i: "flightMetadata",
        moved: false,
        static: false,
        name: "Flight"
      },
      aircraftMetadata: {
        w: 2,
        h: 4,
        x: 4,
        y: 0,
        i: "aircraftMetadata",
        moved: false,
        static: false,
        name: "Aircraft"
      },
      recorderMetadata: {
        w: 2,
        h: 4,
        x: 6,
        y: 0,
        i: "recorderMetadata",
        moved: false,
        static: false,
        name: "Recorder"
      },
      recordDetails: {
        w: 4,
        h: 4,
        x: 8,
        y: 0,
        i: "recordDetails",
        moved: false,
        static: false,
        name: "Record Details"
      },
      groundSpeedMap: {
        w: 6,
        h: 6,
        x: 0,
        y: 4,
        i: "groundSpeedMap",
        moved: false,
        static: false,
        name: "Ground Speed Map (kts)"
      },
      groundSpeedScatterChart: {
        w: 6,
        h: 6,
        x: 0,
        y: 10,
        i: "groundSpeedScatterChart",
        moved: false,
        static: false,
        name: "Ground Speed (kts)"
      },
      aglMap: {
        w: 6,
        h: 6,
        x: 6,
        y: 4,
        i: "aglMap",
        moved: false,
        static: false,
        name: "AGL Map (ft)"
      },
      aglScatterChart: {
        w: 6,
        h: 6,
        x: 6,
        y: 10,
        i: "aglScatterChart",
        moved: false,
        static: false,
        name: "Above Ground Level (ft)"
      },

      flightProfileDataBrowser: {
        w: 12,
        h: 6,
        x: 0,
        y: 18,
        i: "flightProfileDataBrowser",
        moved: false,
        static: false,
        name: "Flight Profile - Track Points"
      }
    },
    [PHASE_OF_FLIGHT_TAB_KEY]: {
      phaseOfFlightMap: {
        w: 12,
        h: 8,
        x: 0,
        y: 0,
        i: "phaseOfFlightMap",
        moved: false,
        static: false,
        name: "Phase of Flight Map"
      },
      phaseOfFlightPieChart: {
        w: 6,
        h: 8,
        x: 0,
        y: 8,
        i: "phaseOfFlightPieChart",
        moved: false,
        static: false,
        name: "Phase of Flight Pie Chart"
      },
      phaseOfFlightControlTable: {
        w: 6,
        h: 8,
        x: 6,
        y: 8,
        i: "phaseOfFlightControlTable",
        moved: false,
        static: false,
        name: "Phase of Flight - Counts"
      },
      phaseOfFlightDataBrowser: {
        w: 12,
        h: 6,
        x: 0,
        y: 16,
        i: "phaseOfFlightDataBrowser",
        moved: false,
        static: false,
        name: "Phase of Flight - Track Points"
      },
    },
    [EXCEEDANCE_TAB_KEY]: {
      exceedanceRiskFrequencyBarChart: {
        w: 6,
        h: 6,
        x: 0,
        y: 0,
        i: "exceedanceRiskFrequencyBarChart",
        moved: false,
        static: false,
        name: "Exceedance Event Count"
      },
      exceedanceRiskDurationBarChart: {
        w: 6,
        h: 6,
        x: 6,
        y: 0,
        i: "exceedanceRiskDurationBarChart",
        moved: false,
        static: false,
        name: "Exceedance Event Duration (seconds)"
      },
      exceedanceEventsDataBrowser: {
        w: 12,
        h: 5,
        x: 6,
        y: 6,
        i: "exceedanceEventsDataBrowser",
        moved: false,
        static: false,
        name: "Exceedance Events Data"
      },
      exceedanceTypeMap: {
        w: 6,
        h: 6,
        x: 0,
        y: 11,
        i: "exceedanceTypeMap",
        moved: false,
        static: false,
        name: "Exceedance Type Map"
      },
      exceedanceSeverityMap: {
        w: 6,
        h: 6,
        x: 6,
        y: 11,
        i: "exceedanceSeverityMap",
        moved: false,
        static: false,
        name: "Exceedance Severity Map"
      },
      exceedanceTrackPointsDataBrowser: {
        w: 12,
        h: 6,
        x: 6,
        y: 17,
        i: "exceedanceTrackPointsDataBrowser",
        moved: false,
        static: false,
        name: "Exceedance - Track Points"
      },
    },
    [OBSTACLE_PROXIMITY_TAB_KEY]: {
      obstacleProximityFrequencyBarChart: {
        w: 6,
        h: 6,
        x: 0,
        y: 0,
        i: "obstacleProximityFrequencyBarChart",
        moved: false,
        static: false,
        name: "Obstacle Proximity Count"
      },
      obstacleProximityMap: {
        w: 6,
        h: 6,
        x: 6,
        y: 0,
        i: "obstacleProximityMap",
        moved: false,
        static: false,
        name: "Obstacle Proximity Threat Map"
      },
      obstacleProximityDataBrowser: {
        w: 12,
        h: 5,
        x: 0,
        y: 6,
        i: "obstacleProximityDataBrowser",
        moved: false,
        static: false,
        name: "Obstacle Proximity - Points"
      },
    },
    [LOC_DASHBOARD_TAB_KEY]: {
      locRiskFrequencyBarChart: {
        w: 6,
        h: 6,
        x: 0,
        y: 0,
        i: "locRiskFrequencyBarChart",
        moved: false,
        static: false,
        name: "LOC Event Count"
      },
      locRiskDurationBarChart: {
        w: 6,
        h: 6,
        x: 6,
        y: 0,
        i: "locRiskDurationBarChart",
        moved: false,
        static: false,
        name: "LOC Event Duration (seconds)"
      },
      // lteRiskDurationBigNumber: {
      //   w: 3,
      //   h: 4,
      //   x: 0,
      //   y: 8,
      //   i: "lteRiskDurationBigNumber",
      //   moved: false,
      //   static: false,
      //   name: "Total LTE Duration"
      // },
      // lteRiskFrequencyBigNumber: {
      //   w: 3,
      //   h: 4,
      //   x: 3,
      //   y: 8,
      //   i: "lteRiskFrequencyBigNumber",
      //   moved: false,
      //   static: false,
      //   name: "Total LTE Count"
      // },
      // vrsMap: {
      //   w: 6,
      //   h: 6,
      //   x: 0,
      //   y: 16,
      //   i: "vrsMap",
      //   moved: false,
      //   static: false,
      //   name: "VRS Map"
      // },
      locTypeMap: {
        w: 6,
        h: 6,
        x: 0,
        y: 6,
        i: "locTypeMap",
        moved: false,
        static: false,
        name: "LOC Type Map"
      },
      locSeverityMap: {
        w: 6,
        h: 6,
        x: 6,
        y: 6,
        i: "locSeverityMap",
        moved: false,
        static: false,
        name: "LOC Severity Map"
      },
      locTrackPointsDataBrowser: {
        w: 12,
        h: 5,
        x: 0,
        y: 12,
        i: "locTrackPointsDataBrowser",
        moved: false,
        static: false,
        name: "LOC - Track Points"
      },
      // vrsRiskDurationBigNumber: {
      //   w: 3,
      //   h: 4,
      //   x: 0,
      //   y: 17,
      //   i: "vrsRiskDurationBigNumber",
      //   moved: false,
      //   static: false,
      //   name: "Total VRS Duration"
      // },
      // vrsRiskFrequencyBigNumber: {
      //   w: 3,
      //   h: 4,
      //   x: 3,
      //   y: 17,
      //   i: "vrsRiskFrequencyBigNumber",
      //   moved: false,
      //   static: false,
      //   name: "Total VRS Count"
      // },
      vrsValidationScatterChart: {
        w: 6,
        h: 9,
        x: 6,
        y: 17,
        i: "vrsValidationScatterChart",
        moved: false,
        static: false,
        name: "VRS Validation"
      },
      locDescentRateScatterChart: {
        w: 6,
        h: 9,
        x: 0,
        y: 21,
        i: "locDescentRateScatterChart",
        moved: false,
        static: false,
        name: "LOC Vertical Speed (ft/min)"
      },
      locCyclicRollScatterChart: {
        w: 6,
        h: 5,
        x: 0,
        y: 26,
        i: "locCyclicRollScatterChart",
        moved: false,
        static: false,
        name: "LOC Cyclic Roll (%)"
      },
      locCollectivePositionScatterChart: {
        w: 6,
        h: 5,
        x: 6,
        y: 26,
        i: "locCollectivePositionScatterChart",
        moved: false,
        static: false,
        name: "LOC Collective Position (%)"
      },
      locCyclicPitchScatterChart: {
        w: 6,
        h: 7,
        x: 0,
        y: 31,
        i: "locCyclicPitchScatterChart",
        moved: false,
        static: false,
        name: "LOC Cyclic Pitch (%)"
      },
      lteCompassRoseChart: {
        w: 6,
        h: 7,
        x: 6,
        y: 31,
        i: "lteCompassRoseChart",
        moved: false,
        static: false,
        name: "Relative Wind Azimuth of Proximity to LTE Event"
      },
      locEventsDataBrowser: {
        w: 12,
        h: 5,
        x: 0,
        y: 38,
        i: "locEventsDataBrowser",
        moved: false,
        static: false,
        name: "LOC Event Data"
      },
      ...(process.env.REACT_APP_USER_BRANCH !== 'prod' && {
        locAglScatterChart: {
          w: 12,
          h: 5,
          x: 0,
          y: 38,
          i: "locAglScatterChart",
          moved: false,
          static: false,
          name: "Altitude Profile: True Altitude (ft)"
        }
      }),
      // locVerticalSpeedScatterChart: {
      //   w: 12,
      //   h: 5,
      //   x: 0,
      //   y: 38,
      //   i: "locVerticalSpeedScatterChart",
      //   moved: false,
      //   static: false,
      //   name: "Vertical Speed Profile: Vertical Speed (ft/min)"
      // },
      ...(process.env.REACT_APP_USER_BRANCH !== 'prod' && {
        locGroundSpeedScatterChart: {
          w: 12,
          h: 5,
          x: 0,
          y: 38,
          i: "locGroundSpeedScatterChart",
          moved: false,
          static: false,
          name: "Ground Speed Profile: Ground Speed (kt) "
        }
      }),
    },
    [LTE_DASHBOARD_TAB_KEY]: {
      lteCompassRoseChart: {
        w: 6,
        h: 8,
        x: 0,
        y: 0,
        i: "lteCompassRoseChart",
        moved: false,
        static: false,
        name: "LTE Chart"
      },
    },
    [PLAYBACK_TAB_KEY]: {
      playbackMap: {
        w: 12,
        h: 12,
        x: 0,
        y: 0,
        i: "playbackMap",
        moved: false,
        static: false,
        name: "Playback Map"
      },
    },
    [PLAYBACK3D_TAB_KEY]: {
      playback3DMap: {
        w: 12,
        h: 12,
        x: 0,
        y: 0,
        i: "playback3DMap",
        moved: false,
        static: false,
        name: "Flight 3D Playback"
      },
    },
  };

  const uasAggregateMetricsDashboardConfig = {
    [UAS_RISK_ANALYSIS_TAB_KEY]: {
      uasRiskVectorTilesMap: {
        w: 9,
        h: 11,
        x: 0,
        y: 0,
        i: "uasRiskVectorTilesMap",
        moved: false,
        static: false,
        name: "UAS Risk Vector Tiles Map"
      },
      gridCountByAirport: {
        w: 3,
        h: 6,
        x: 9,
        y: 0,
        i: "gridCountByAirport",
        moved: false,
        static: false,
        name: "Grid Count by Airport"
      },
      riskByAtmAssignedCeilingDataBrowser: {
        w: 3,
        h: 5,
        x: 9,
        y: 6,
        i: "riskByAtmAssignedCeilingDataBrowser",
        moved: false,
        static: false,
        name: "Risk by ATM Assigned Ceiling"
      },
      nmacDurationByGridDataBrowser: {
        w: 5,
        h: 10,
        x: 0,
        y: 11,
        i: "nmacDurationByGridDataBrowser",
        moved: false,
        static: false,
        name: "NMAC Duration (Seconds) by Grid"
      },
      nmacLikelihoodByGridDataBrowser: {
        w: 5,
        h: 10,
        x: 5,
        y: 11,
        i: "nmacLikelihoodByGridDataBrowser",
        moved: false,
        static: false,
        name: "NMAC Likelihood by Grid"
      },
      totalFlightsBigNumber: {
        w: 2,
        h: 5,
        x: 10,
        y: 12,
        i: "totalFlightsBigNumber",
        moved: false,
        static: false,
        name: "Total Flights"
      },
      totalFlightHoursBigNumber: {
        w: 2,
        h: 5,
        x: 10,
        y: 21,
        i: "totalFlightHoursBigNumber",
        moved: false,
        static: false,
        name: "Total Flight Hours"
      },
      gridsummDataBrowser: {
        w: 12,
        h: 10,
        x: 0,
        y: 26,
        i: "gridsummDataBrowser",
        moved: false,
        static: false,
        name: "Gridsumm Data"
      },
    },
    [SUAS_SIGHTINGS_TAB_KEY]: {
      uasSightingsVectorTilesMap: {
        w: 6,
        h: 7,
        x: 0,
        y: 0,
        i: "uasSightingsVectorTilesMap",
        moved: false,
        static: false,
        name: "sUAS Location Uncertainty Map"
      },
      // sUasSightingsByStateDataBrowser: {
      //   w: 3,
      //   h: 7,
      //   x: 9,
      //   y: 0,
      //   i: "sUasSightingsByStateDataBrowser",
      //   moved: false,
      //   static: false,
      //   name: "Sightings by State"
      // },
      uasSightingsHeatMap: {
        w: 6,
        h: 7,
        x: 0,
        y: 7,
        i: "uasSightingsHeatMap",
        moved: false,
        static: false,
        name: "sUAS Sighting Counts by State Map"
      },
      sUasSightingsByCityDataBrowser: {
        w: 3,
        h: 7,
        x: 6,
        y: 0,
        i: "sUasSightingsByCityDataBrowser",
        moved: false,
        static: false,
        name: "Sightings by City"
      },
      countOfSummaryByDist2LocDataBrowser: {
        w: 3,
        h: 7,
        x: 6,
        y: 14,
        i: "countOfSummaryByDist2LocDataBrowser",
        moved: false,
        static: false,
        name: "Count of Summary by Distance from Sighting Reference Point"
      },
      topIcaoDesignatorSightingsBarChart: {
        w: 3,
        h: 7,
        x: 6,
        y: 7,
        i: "topIcaoDesignatorSightingsBarChart",
        moved: false,
        static: false,
        name: "ICAO Designators with Sightings"
      },
      sUasSightingsScatterChart: {
        w: 6,
        h: 7,
        x: 0,
        y: 14,
        i: "sUasSightingsScatterChart",
        moved: false,
        static: false,
        name: "sUAS Sightings Over Time"
      },
      topAirportSightingsBarChart: {
        w: 3,
        h: 7,
        x: 9,
        y: 0,
        i: "topAirportSightingsBarChart",
        moved: false,
        static: false,
        name: "Airports with Sightings"
      },
      sUasSightingsByHelicopterType: {
        w: 3,
        h: 7,
        x: 9,
        y: 7,
        i: "sUasSightingsByHelicopterType",
        moved: false,
        static: false,
        name: "Sightings by Aircraft Type"
      },
      sUasSightingsByNavaid: {
        w: 3,
        h: 7,
        x: 9,
        y: 14,
        i: "sUasSightingsByNavaid",
        moved: false,
        static: false,
        name: "Sightings by NAVAID"
      },
      sUasSightingsDataBrowser: {
        w: 12,
        h: 10,
        x: 0,
        y: 35,
        i: "sUasSightingsDataBrowser",
        moved: false,
        static: false,
        name: "sUAS Sightings Data"
      },
    },
  };

  const uimcAggregateMetricsDashboardConfig = {
    [UIMC_EVENTS_TAB_KEY]: {
      // facilityCountsDataBrowser: {
      //   w: 3,
      //   h: 6,
      //   x: 0,
      //   y: 0,
      //   i: "facilityCountsDataBrowser",
      //   moved: false,
      //   static: false,
      //   name: "Count of Events by Facility"
      // },
      uimcEventsByDateAreaChart: {
        w: 12,
        h: 4,
        x: 0,
        y: 0,
        i: "uimcEventsByDateAreaChart",
        moved: false,
        static: false,
        name: "Count of Events (by Date and Daylight)"
      },
      // uimcEventCountByFacilityAndDaylightBarChart: {
      //   w: 3,
      //   h: 6,
      //   x: 3,
      //   y: 0,
      //   i: "uimcEventCountByFacilityAndDaylightBarChart",
      //   moved: false,
      //   static: false,
      //   name: "Count of Events by Facility and Daylight"
      // },
      uimcEventsClusterMap: {
        w: 6,
        h: 12,
        x: 6,
        y: 0,
        i: "uimcEventsClusterMap",
        moved: false,
        static: false,
        name: "Count of Events (by Location and Daylight)"
      },
      uimcEventCountByAircraftTypeAndDaylightBarChart: {
        w: 4,
        h: 6,
        x: 2,
        y: 0,
        i: "uimcEventCountByAircraftTypeAndDaylightBarChart",
        moved: false,
        static: false,
        name: "Count of Events (by Aircraft Type and Daylight)"
      },
      uimcEventsByDurationHistogram: {
        w: 4,
        h: 7,
        x: 4,
        y: 12,
        i: "uimcEventsByDurationHistogram",
        moved: false,
        static: false,
        name: "Histogram of Event Duration"
      },
      averageDurationByAircraftTypeAndDaylight: {
        w: 4,
        h: 6,
        x: 2,
        y: 6,
        i: "averageDurationByAircraftTypeAndDaylight",
        moved: false,
        static: false,
        name: "Average Duration of Events (by Aircraft Type and Daylight)"
      },
      averageDurationByDate: {
        w: 4,
        h: 7,
        x: 0,
        y: 12,
        i: "averageDurationByDate",
        moved: false,
        static: false,
        name: "Average Duration of Events by Date"
      },
      // uimcEventInsights: {
      //   w: 6,
      //   h: 9,
      //   x: 6,
      //   y: 6,
      //   i: "uimcEventInsights",
      //   moved: false,
      //   static: false,
      //   name: "Event Insights"
      // },
      // uimcEventCountByIcaoCategoryAndDaylight: {
      //   w: 4,
      //   h: 6,
      //   x: 4,
      //   y: 12,
      //   i: "uimcEventCountByIcaoCategoryAndDaylight",
      //   moved: false,
      //   static: false,
      //   name: "Count of Events by ICAO Sub-category and Daylight"
      // },
      // uimcEventAverageDurationByIcaoCategoryAndDaylight: {
      //   w: 4,
      //   h: 6,
      //   x: 8,
      //   y: 12,
      //   i: "uimcEventAverageDurationByIcaoCategoryAndDaylight",
      //   moved: false,
      //   static: false,
      //   name: "Average Duration of Events by ICAO Sub-category and Daylight"
      // },
      uimcEventsByIcaoCategoryBigNumber: {
        w: 2,
        h: 3,
        x: 0,
        y: 6,
        i: "uimcEventsByIcaoCategoryBigNumber",
        moved: false,
        static: false,
        name: "Count of Events"
      },
      uimcEventDurationByIcaoCategoryBigNumber: {
        w: 2,
        h: 3,
        x: 0,
        y: 9,
        i: "uimcEventDurationByIcaoCategoryBigNumber",
        moved: false,
        static: false,
        name: "Average Event Duration"
      },
      uimcUniqueTrackBigNumber: {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "uimcUniqueTrackBigNumber",
        moved: false,
        static: false,
        name: "Unique Contributing Flight Tracks"
      },
      uimcEventsPerTrackBigNumber: {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "uimcEventsPerTrackBigNumber",
        moved: false,
        static: false,
        name: "Average Events Per Contributing Flight"
      },
      uimcEventsByDaylightPieChart: {
        w: 4,
        h: 6,
        x: 8,
        y: 19,
        i: "uimcEventsByDaylightPieChart",
        moved: false,
        static: false,
        name: "Events by Daylight"
      },
      // uimcEventCountByAircraftTypeDataBrowser: {
      //   w: 3,
      //   h: 9,
      //   x: 0,
      //   y: 21,
      //   i: "uimcEventCountByAircraftTypeDataBrowser",
      //   moved: false,
      //   static: false,
      //   name: "Count of Events by Aircraft Type"
      // },
      uimcEventsByStateAndDaylight: {
        w: 4,
        h: 6,
        x: 4,
        y: 19,
        i: "uimcEventsByStateAndDaylight",
        moved: false,
        static: false,
        name: "Events by State and Daylight"
      },
      uimcEventsByRegionAndDaylight: {
        w: 4,
        h: 6,
        x: 0,
        y: 19,
        i: "uimcEventsByRegionAndDaylight",
        moved: false,
        static: false,
        name: "Events by Region and Daylight"
      },
      uimcEventsBySeasonPieChart: {
        w: 4,
        h: 7,
        x: 8,
        y: 12,
        i: "uimcEventsBySeasonPieChart",
        moved: false,
        static: false,
        name: "Events by Season"
      },
      uimcEventsByFlightRulePieChart: {
        w: 4,
        h: 6,
        x: 8,
        y: 25,
        i: "uimcEventsByFlightRulePieChart",
        moved: false,
        static: false,
        name: "Events by IMC Conditions"
      },
      uimcEventsByAirspaceClassAndFlightRuleChart: {
        w: 4,
        h: 6,
        x: 4,
        y: 25,
        i: "uimcEventsByAirspaceClassAndFlightRuleChart",
        moved: false,
        static: false,
        name: "Events by Airspace Class and Flight Rule"
      },
      uimcEventsByAirspaceNameAndFlightRuleChart: {
        w: 4,
        h: 6,
        x: 0,
        y: 25,
        i: "uimcEventsByAirspaceNameAndFlightRuleChart",
        moved: false,
        static: false,
        name: "Events by Airspace Name and Flight Rule"
      },
      //17
      uimcEventsDataBrowser: {
        w: 12,
        h: 8,
        x: 0,
        y: 31,
        i: "uimcEventsDataBrowser",
        moved: false,
        static: false,
        name: "Events Data"
      },
      wxCamerasDataBrowser: {
        w: 12,
        h: 8,
        x: 0,
        y: 39,
        i: "wxCamerasDataBrowser",
        moved: false,
        static: false,
        name: "Weather Camera Data"
      },
    },
  };

  const geoSearchByHelipadsPageConfig = {
    geoSearchByHelipadsMap: {
      w: 12,
      h: 11,
      x: 0,
      y: 0,
      i: "geoSearchByHelipadsMap",
      moved: false,
      static: true,
      name: "Geospatial Search Map"
    },
    // searchedFlightsTable: {
    //   w: 4,
    //   h: 11,
    //   x: 8,
    //   y: 0,
    //   i: "searchedFlightsTable",
    //   moved: false,
    //   static: false,
    //   name: "Searched Flights"
    // },
  };
  
  const approachFlightsMetricsDashboardConfig = {
    [APPROACH_FLIGTHS_TAB_KEY]: {
      approachFlight3DMap: {
        w: 8,
        h: 11,
        x: 0,
        y: 0,
        i: "approachFlight3DMap",
        moved: false,
        static: false,
        name: "Approach Flight 3D Map"
      },
      approachAngleBarChart: {
        w: 4,
        h: 11,
        x: 8,
        y: 0,
        i: "approachAngleBarChart",
        moved: false,
        static: false,
        name: "Approach Angle"
      },
      approachBankBarChart: {
        w: 4,
        h: 11,
        x: 0,
        y: 12,
        i: "approachBankBarChart",
        moved: false,
        static: false,
        name: "Approach Bank"
      },
      approachPitchBarChart: {
        w: 4,
        h: 11,
        x: 4,
        y: 12,
        i: "approachPitchBarChart",
        moved: false,
        static: false,
        name: "Approach Pitch"
      },
      approachGroundTrackDifferenceBarChart: {
        w: 4,
        h: 11,
        x: 8,
        y: 12,
        i: "approachGroundTrackDifferenceBarChart",
        moved: false,
        static: false,
        name: "Approach Groud Track Difference"
      },
      approachVertspeedBarChart: {
        w: 4,
        h: 11,
        x: 0,
        y: 23,
        i: "approachVertspeedBarChart",
        moved: false,
        static: false,
        name: "Approach Vertical Speed"
      },
      approachAirspeedBarChart: {
        w: 4,
        h: 11,
        x: 4,
        y: 23,
        i: "approachAirspeedBarChart",
        moved: false,
        static: false,
        name: "Approach Air Speed"
      },
      approachTorquetotalBarChart: {
        w: 4,
        h: 11,
        x: 8,
        y: 23,
        i: "approachTorquetotalBarChart",
        moved: false,
        static: false,
        name: "Approach Torque Total"
      }
      // gridCountByAirport: {
      //   w: 3,
      //   h: 6,
      //   x: 9,
      //   y: 0,
      //   i: "gridCountByAirport",
      //   moved: false,
      //   static: false,
      //   name: "Grid Count by Airport"
      // },
      // riskByAtmAssignedCeilingDataBrowser: {
      //   w: 3,
      //   h: 5,
      //   x: 9,
      //   y: 6,
      //   i: "riskByAtmAssignedCeilingDataBrowser",
      //   moved: false,
      //   static: false,
      //   name: "Risk by ATM Assigned Ceiling"
      // },
      // nmacDurationByGridDataBrowser: {
      //   w: 5,
      //   h: 10,
      //   x: 0,
      //   y: 11,
      //   i: "nmacDurationByGridDataBrowser",
      //   moved: false,
      //   static: false,
      //   name: "NMAC Duration (Seconds) by Grid"
      // },
      // nmacLikelihoodByGridDataBrowser: {
      //   w: 5,
      //   h: 10,
      //   x: 5,
      //   y: 11,
      //   i: "nmacLikelihoodByGridDataBrowser",
      //   moved: false,
      //   static: false,
      //   name: "NMAC Likelihood by Grid"
      // },
      // totalFlightsBigNumber: {
      //   w: 2,
      //   h: 5,
      //   x: 10,
      //   y: 12,
      //   i: "totalFlightsBigNumber",
      //   moved: false,
      //   static: false,
      //   name: "Total Flights"
      // },
      // totalFlightHoursBigNumber: {
      //   w: 2,
      //   h: 5,
      //   x: 10,
      //   y: 21,
      //   i: "totalFlightHoursBigNumber",
      //   moved: false,
      //   static: false,
      //   name: "Total Flight Hours"
      // },
      // gridsummDataBrowser: {
      //   w: 12,
      //   h: 10,
      //   x: 0,
      //   y: 26,
      //   i: "gridsummDataBrowser",
      //   moved: false,
      //   static: false,
      //   name: "Gridsumm Data"
      // },
    },
  };

  const baseMaps = {
    "Dark Basemap": {
      url: 'https://basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png',
      attribution: '&copy <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a> contributors',
    },
    "Light Basemap": {
      url: 'https://basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
      attribution: '&copy <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a> contributors',
    },
    "Open Street Map": {
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    },
    "VFR Sectional Basemap": {
      url: 'https://wms.chartbundle.com/tms/1.0.0/sec/{z}/{x}/{y}.png?origin=nw',
      attribution: 'Sectional basemap from <a href="http://www.chartbundle.com/" target="_blank">ChartBundle.com',
    },
    "Helo Chart Basemap": {
      url: 'https://wms.chartbundle.com/tms/1.0.0/hel/{z}/{x}/{y}.png?origin=nw',
      attribution: 'Helicopter Chart basemap from <a href="http://www.chartbundle.com/" target="_blank">ChartBundle.com',
    },
    "Esri World Imagery (Terrain)": {
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    },
  };

  const defaultBasemapStyleJson = {
    'version': 8,
    'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    'sources': {
      'raster-tiles': {
        'type': 'raster',
        'tiles': [
          'https://basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png'
        ],
        'tileSize': 256,
        'attribution': '&copy <a href="http://cartodb.com/attributions" target="_blank">CartoDB</a> contributors'
      }
    },
    'layers': [{
      'id': 'basemap-tiles', // Can be whatever you want to name it
      'type': 'raster', // Must be raster
      'source': 'raster-tiles', // Must be 'raster-tiles'
      'minzoom': 0,
      'maxzoom': 22
    }]
  };

  const PHASE_OF_FLIGHT_COLOR_PALETTE = [
    "#863CFF",
    "#EE94C9",
    "#DE3B00",
    "#B9E52F",
    "#2A5D78",
    "#E436BB",
    "#9FDEF1",
    "#3AB62F",
    "#F6AA54",
    "#8409BD",
    "#DDE009",
    "#413AA7",
    "#00CC99",
    "#0000B3",
    "#990000",
    "#FF99CC",
    "#CCCCFF",
    "#4D4DFF",
    "#FF9980",
    "#86B300",
    "#669999",
    "#999966",
    "#FFCCFF",
  ];

  const EXCEEDANCE_TYPE_COLOR_PALETTE = [
    "#6197E2",
    "#E436BB",
    "#F6AA54",
    "#2A5D78",
    "#B8E52F",
    "#E52F2F",
    "#8409BD",
    "#C1C408",
    "#00CC99",
    "#0000B3",
    "#990000",
    "#FF99CC",
    "#CCCCFF",
    "#4D4DFF",
    "#FF9980",
    "#86B300",
    "#669999",
    "#999966",
    "#FFCCFF",
  ];

  const UIMC_DAYLIGHT_TYPE_COLOR_PALETTE = [
    "#9FDEF1",
    "#2A5D78",
    "#938FC3",
  ];



  const COLOR_PALETTE_SHADE_FACTOR = 20; // Control the percentage that colors are darkened/lightened for each palette cycle
  const DARKEN_COLORS = true; // Darken if true, lighten if false

  const COLOR_MAPS = useRef({
    phaseOfFlightColorMap: {},
    exceedanceSeverityColorMap: {
      "HIGH": "#CF0011",
      "MEDIUM": "#FF8700",
      "LOW": "#F7E65A",
      "NONE": "#AAAAAA"
    },
    exceedanceTypeColorMap: {},
    obstacleProximityColorMap: {
      "HIGH": "#CF0011",
      "MEDIUM": "#FF8700",
      "LOW": "#F7E65A",
      "NONE": "#AAAAAA"
    },
    vrsColorMap: {
      "HIGH RISK": "#FF8700",
      "NO RISK": "#AAAAAA",
      "UNKNOWN RISK": "#737373",
      "BOUNDARY": "#4472C4", // #4472C4, rgba(68, 114, 196, 0.6)
    },
    approachColorMap: {
      "VFR": "#db11fa",
      "IFR": "#21a1fc",
      "VFR-Missed": "#fc7521",
      "IFR-Missed": "#f8fc21"
    },
    angleColorMap: {
      "Shallow": "#F0E68C",
      "Steep": "#4B0082",
      "Normal": "#00008B",
      "Very Steep": "#FFA07A"
    },
    uimcDaylightTypeColorMap: {},
    uimcSeasonsTypeColorMap : {
      "SPRING": "#8FBC8F",
      "SUMMER": "#F5DEB3",
      "FALL" : "#BC8F8F",
      "WINTER": "#708090",
    },
    uimcFlightRuleColorMap : {
      "VFR": "#8FBC8F",
      "MVFR": "#F5DEB3",
      "IFR" : "#BC8F8F",
      "LIFR": "#708090",
    },
  });

  const obstacleTypeSymbols = {
    "AMUSEMENT_PARK": { icon: "icon-amusement-park-fair", color: "#737373" },
    "ANTENNA": { icon: "icon-signal", color: "#737373" },
    "ARCH": { icon: "icon-arch", color: "#737373" },
    "BLDG": { icon: "icon-building1", color: "#737373" },
    "BLDG-TWR": { icon: "icon-build-tower", color: "#737373" },
    "BRIDGE": { icon: "icon-bridge", color: "#737373" },
    "CATENARY": { icon: "icon-signal", color: "Green" },
    "COOL_TWR": { icon: "icon-forrst", color: "Blue" },
    "CRANE": { icon: "icon-crane", color: "Green" },
    "CTRL_TWR": { icon: "icon-airport-tower", color: "#cdcdcd" },
    "DAM": { icon: "icon-dam", color: "#737373" },
    "DOME": { icon: "icon-dom", color: "#737373" },
    "ELEC_SYS": { icon: "icon-flash", color: "Red" },
    "ELEVATOR": { icon: "icon-trello", color: "#737373" },
    "FENCE": { icon: "icon-prison", color: "#737373" },
    "GEN_UTIL": { icon: "icon-prison", color: "Green" },
    "HEAT_COOL_SYSTEM": { icon: "icon-water", color: "#737373" },
    "LANDFILL": { icon: "icon-stop", color: "Brown" },
    "LGTHOUSE": { icon: "icon-lighthouse", color: "#737373" },
    "MET": { icon: "icon-cell-tower", color: "Blue" },
    "MONUMENT": { icon: "icon-monument", color: "#737373" },
    "NAVAID": { icon: "icon-address", color: "#737373" },
    "PLANT": { icon: "icon-industrial-building", color: "#737373" },
    "POLE": { icon: "icon-up-thin", color: "#737373" },
    "POWER_PLANT": { icon: "icon-industrial-building", color: "Red" },
    "REFINERY": { icon: "icon-industrial-building", color: "Blue" },
    "RIG": { icon: "icon-rig-offshoreplatform", color: "Yellow" },
    "SHIP": { icon: "icon-ship", color: "#737373" },
    "SIGN": { icon: "icon-embassy", color: "#737373" },
    "SILO": { icon: "icon-barn-silo", color: "#737373" },
    "SOLAR_PANELS": { icon: "icon-solar-panel1", color: "#737373" },
    "SPIRE": { icon: "icon-spire", color: "#737373" },
    "STACK": { icon: "icon-stack-chimney", color: "Brown" },
    "STADIUM": { icon: "icon-stop", color: "Gray" },
    "TANK": { icon: " icon-airport-tower1", color: "Brown" },
    "T-L_TWR": { icon: "icon-cell-tower", color: "#737373" },
    "TOWER": { icon: "icon-tower", color: "Brown" },
    "TRAMWAY": { icon: "icon-cable-car", color: "Red" },
    "UTILITY_POLE": { icon: "icon-up-thin", color: "Brown" },
    "VERTICAL_STRUCTURE": { icon: "icon-monument", color: "Brown" },
    "WALL": { icon: "icon-wall", color: "Brown" },
    "WINDMILL": { icon: "icon-windmill", color: "#737373" },
    "WINDSOCK": { icon: "icon-windsock", color: "#737373" }
  }

  const OBSTACLE_ICON_SIZE = 20;

  // See: https://www.w3schools.com/jsref/jsref_tolocalestring.asp
  const DATE_TIME_CONFIG = {
    dateStyle: 'medium',
    timeStyle: 'medium',
    // timeZone: 'UTC', // Defaults to Local time
  }

  // Utility Functions
  /**
   * Get the appropriate color mapping for a given ground speed value in knots (kts).
   * 
   * @param {number} groundSpeed Speed in knots (kts) relative to the ground.
   * @returns Object containing the respective color, label, and priority for the ground speed.
   */
  const getGroundSpeedColorMap = (groundSpeed) => {
    let color = "#0320A7" // Default color
    let label = "≥ 150" // Default label
    let priority = 6; // Default priority

    if (groundSpeed >= 125 && groundSpeed < 150) {
      color = "#5874FC";
      label = "125 - 149";
      priority = 5;
    }
    else if (groundSpeed >= 100 && groundSpeed < 125) {
      color = "#6A98FC";
      label = "100 - 124";
      priority = 4;
    }
    else if (groundSpeed >= 75 && groundSpeed < 100) {
      color = "#7CB7FC";
      label = "75 - 99";
      priority = 3;
    }
    else if (groundSpeed >= 50 && groundSpeed < 75) {
      color = "#8ED1FD";
      label = "50 - 74";
      priority = 2;
    }
    else if (groundSpeed >= 25 && groundSpeed < 50) {
      color = "#A0E5FD";
      label = "25 - 49";
      priority = 1;
    }
    else if (groundSpeed >= 0 && groundSpeed < 25) {
      color = "#B2F5FD";
      label = "0 - 24";
      priority = 0;
    }

    return { color: color, label: label, priority: priority };
  }

  /**
   * Get the appropriate color mapping for a given above ground level (agl) value in feet (ft).
   * 
   * @param {number} agl Above ground level altitude in feet (ft).
   * @returns Object containing the respective color, label, and priority for the AGL value.
   */
  const getAglColorMap = (agl) => {
    let color = "#0320A7"; // Default color (anything above 4500 ft agl)
    let label = "4500 and Above"; // >= 4500 (html code)
    let priority = 11; // Default priority

    if (agl >= 4000 && agl < 4500) {
      color = "#5B21FD";
      label = "4000 - 4499";
      priority = 10;
    }
    else if (agl >= 3500 && agl < 4000) {
      color = "#4B33FD";
      label = "3500 - 3999";
      priority = 9;
    }
    else if (agl >= 3000 && agl < 3500) {
      color = "#464AFC";
      label = "3000 - 3499";
      priority = 8;
    }
    else if (agl >= 2500 && agl < 3000) {
      color = "#5874FC";
      label = "2500 - 2999";
      priority = 7;
    }
    else if (agl >= 2000 && agl < 2500) {
      color = "#6A98FC";
      label = "2000 - 2499";
      priority = 6;
    }
    else if (agl >= 1500 && agl < 2000) {
      color = "#7CB7FC";
      label = "1500 - 1999";
      priority = 5;
    }
    else if (agl >= 1000 && agl < 1500) {
      color = "#8ED1FD";
      label = "1000 - 1499";
      priority = 4;
    }
    else if (agl >= 500 && agl < 1000) {
      color = "#A0E5FD";
      label = "500 - 999";
      priority = 3;
    }
    else if (agl >= 0 && agl < 500) {
      color = "#A0E5FD";
      label = "0 - 499";
      priority = 2;
    }
    else if (agl < 0) {
      color = "#D4F5FD";
      label = "0 and Below";
      priority = 1;
    }

    return { color: color, label: label, priority: priority };
  }

  /**
   * Compute and obtain a priority for a given exceedance severity label.
   * 
   * @param {string} severity Exceedance severity label.
   * @returns Numeric priority respective to the provided severity label.
   */
  const getExceedanceSeverityPriority = (severity) => {
    let _severity = typeof(severity) === "string" ? severity.toUpperCase().trim() : severity;
    let priority = -1;
    switch (_severity) {
      case "NONE":
        priority = 1;
        break;
      case "LOW":
        priority = 2;
        break;
      case "MEDIUM":
        priority = 3;
        break;
      case "HIGH":
        priority = 4;
        break;
      default:
        break;
    }

    return priority;
  }

  /**
   * Obtain the color value for a given VRS state.
   * 
   * @param {boolean} vrs Whether vortex ring state (VRS) is in effect or not.
   * @returns Appropriate color mapping based on VRS state.
   */
  const getVrsColor = (vrs) => {
    // NOTE: if vrs is null, color should be grey (or some default color value)
    return vrs === true ? COLOR_MAPS.current.vrsColorMap["HIGH RISK"] :
      vrs === false ? COLOR_MAPS.current.vrsColorMap["NO RISK"] :
      COLOR_MAPS.current.vrsColorMap["UNKNOWN RISK"];
  }

  /**
   * Retrieve JSON data from local storage.
   * 
   * @param {string} key Key for retrieving stored json data.
   * @returns JSON object containing the target data for retrieval
   */
  const getFromLS = (key) => {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem(LS_KEY)) || {};
      }
      catch (e) {}
    }
    return ls[key];
  }

  /**
   * Save JSON data structure to local storage. Local storage does not support
   * storing and retrieving JSON structures with nested functions since the
   * JSON stringify function cannot parse functions properly.
   * 
   * @param {string} key Key for saving json data to local storage.
   * @param {object} value JSON object containing data to save to local storage.
   */
  const saveToLS = (key, value) => {
    if (global.localStorage) {
      let ls = {};
      try {
        ls = JSON.parse(global.localStorage.getItem(LS_KEY)) || {};
      }
      catch (e) {}

      // Save new key/value pair without overwriting existing values
      global.localStorage.setItem(
        LS_KEY,
        JSON.stringify({
          ...ls,
          [key]: value
        })
      );
    }
  }

  /**
   * Clear the app cache by removing the main local storage key
   * from local storage.
   */
  const clearLS = () => {
    if (global.localStorage) {
      global.localStorage.removeItem(LS_KEY);
    }
  }

  /**
   * Generate a string of paragraphs for testing purposes. Generated
   * paragraphs replicate the first two sentences of lorem ipsum.
   * 
   * @param {int} limit Total number of paragraphs to generate.
   * @returns String of paragraphs.
   */
  const generateText = (limit = 20) => {
    let text = "";
    const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    malesuada lacus ex, sit amet blandit leo lobortis eget.`;

    for (let i = 0; i < limit; i++) {
      text = `${text} ${lorem}`;
    }

    return text;
  }

  /**
   * Toggle processing state with delay as a means for preventing UI blocking.
   * This function is intended to be invoked prior to running a process that could
   * potentially block the UI.
   */
  const preventUIBlocking = () => {
    // console.log("Start ui blocking method", baseData)
    // Clear the timeout if already started to renew timeout (addresses potential tab change spamming issue)
    if (uiBlockTimeoutRef.current) {
      clearTimeout(uiBlockTimeoutRef.current);
    }

    // Control UI processing by toggling the processing flag and prevent UI rendering blocks
    setProcessing(true);

    // Providing at least a 1-second delay grants enough time for the UI to change priority for load order, 
    // resolving the race condition issue that causes the UI to be blocked.
    // NOTE: The n-second delay here does not indicate that it will only take n second(s) to completely load a transitioned tab. 
    //       It only serves to allow the UI to render without interruption.
    uiBlockTimeoutRef.current = setTimeout(() => {
      // Handle corner case when loading data flag is stuck on true and data exists
      if (baseData !== null && loadingData) {
        setLoadingData(false);
      }

      // Only turn processing off if data isn't still being loaded
      if (!loadingData) {
        setProcessing(false);
      }
    }, 2000);
  }

  /**
   * Perform a logical grouping given a two-dimensional array and a column index for a target column to group.
   * Provide additional count data along with unique set of occurrences for the target column.
   * 
   * @param {array} array2D Two-dimensional array representing data to perform a groupBy operation on.
   * @param {int} colIdx Column index targeting the respective column to group.
   * @param {object} options Additional options to apply to logical grouping.
   * @param {boolean} caseSensitive Whether to enforce case sensitivity in grouped counts. Default true.
   * @param {number} filterIdx Column index targeting the respective column to include in resulting group.
   * @param {any} filterValues Individual value or list of value(s) to include in resulting group.
   * @returns Object containing the unique set of values for the target column along with the respective count of occurrences.
   */
  const groupByCount = (array2D, colIdx, { caseSensitive = true, filterIdx = null, filterValues = [] } = {}) => {
    let grouping = {};
    let include = true;
    let rawVal, modVal, comparisonVal;
    let modFilterVals = filterValues && filterValues.constructor === Array ? [...filterValues] : filterValues;

    // Iterate 2D array and tally each occurrence of unique values (e.g., groupby + count)
    for (let row of array2D) {
      rawVal = row[colIdx];
      modVal = rawVal;

      if (!caseSensitive) {
        if (typeof(rawVal) === "string") {
          modVal = modVal.toUpperCase();
        }

        // Convert all values in filter values list to uppercase for case-insensitivity
        if (filterValues) {
          if (filterValues.constructor === Array) {
            modFilterVals = filterValues.map(item => typeof(item) === "string" ? item.toUpperCase() : item);
          }
          else if (typeof(filterValues) === "string") {
            modFilterVals = filterValues.toUpperCase();
          }
          else {
            modFilterVals = filterValues;
          }
        }
      }

      // Determine if current value should be included or not based on provided configuration
      if (filterIdx !== null) {
        comparisonVal = row[filterIdx];
        if (!caseSensitive) {
          comparisonVal = typeof(comparisonVal) === "string" ? comparisonVal.toUpperCase() : comparisonVal;
        }

        // Handle comparison according to data type of modified filter value(s)
        if (modFilterVals && modFilterVals.constructor === Array) {
          include = modFilterVals.includes(comparisonVal);
        }
        else {
          include = comparisonVal === modFilterVals;
        }

        // console.log(`Include ${rawVal}: ${include} (comparing ${comparisonVal} to ${modFilterVals})`);
      }

      // Only process if the current value should be included
      if (include) {
        if (!(modVal in grouping)) {
          // NOTE: Storing raw value for display purposes
          grouping[modVal] = { value: rawVal, count: 0 };
        }

        grouping[modVal].count++;
      }
    }

    return grouping;
  }

  /**
   * Perform a logical grouping given a two-dimensional array and a column index for a target column to group.
   * Provide additional count data along with unique set of occurrences for the target column.
   * 
   * @param {array} array2D Two-dimensional array representing data to perform a groupBy operation on.
   * @param {int} primaryIdx Column index targeting the respective column to group on.
   * @param {int} aggColIdx Column to compute average on.
   * @param {object} options Additional options to apply to logical grouping.
   * @param {number} filterIdx Column index targeting the respective column to include in resulting group.
   * @param {any} filterValues Individual value or list of value(s) to include in resulting group.
   * @returns Object containing the unique set of values for the target column along with the respective count of occurrences.
   */
  const groupByAgg = (array2D, { primaryIdx = 0, aggColIdx = 0, filterIdx = null, filterValues = [] } = {}) => {
    let grouping = {};
    let include = true;
    let primaryVal, aggVal, comparisonVal;
    let modFilterVals = filterValues && filterValues.constructor === Array ? [...filterValues] : filterValues;

    // Iterate 2D array and track sum for each value
    for (let row of array2D) {
      primaryVal = row[primaryIdx];
      aggVal = row[aggColIdx];

      // Determine if current value should be included or not based on provided configuration
      if (filterIdx !== null) {
        comparisonVal = row[filterIdx];

        // Handle comparison according to data type of modified filter value(s)
        if (modFilterVals && modFilterVals.constructor === Array) {
          include = modFilterVals.includes(comparisonVal);
        }
        else {
          include = comparisonVal === modFilterVals;
        }

        // console.log(`Include ${primaryVal}: ${include} (comparing ${comparisonVal} to ${modFilterVals})`);
      }

      // Only process if the current value should be included
      if (include) {
        if (!(primaryVal in grouping)) {
          // NOTE: Storing raw value for display purposes
          grouping[primaryVal] = { value: primaryVal, sum: 0, average: 0, count: 0 };
        }

        grouping[primaryVal].count++;

        // Only compute running sum if aggregate value is a number
        if (typeof(aggVal) === "number") {
          grouping[primaryVal].sum += aggVal;
        }
      }
    }

    // Iterate grouping entries and compute aggregations
    for (let key of Object.keys(grouping)) {
      const { sum, count } = grouping[key];

      // Compute average if possible
      if (count > 0) {
        grouping[key].average = sum / count;
      }
    }

    return grouping;
  }

  /**
   * Perform a logical grouping given a two-dimensional array and a column index for a target column to group.
   * 
   * @param {array} array2D Two-dimensional array representing data to perform a groupBy operation on.
   * @param {int} colIdx Column index targeting the respective column to group.
   * @param {object} options Additional options to apply to logical grouping.
   * @param {boolean} caseSensitive Whether to enforce case sensitivity in grouped counts. Default true.
   * @param {number} filterIdx Column index targeting the respective column to include in resulting group.
   * @param {any} filterValues Individual value or list of value(s) to include in resulting group.
   * @returns Array containing the unique set of values for the target column (e.g., logical grouping).
   */
  const groupBy = (array2D, colIdx, { caseSensitive = true, filterIdx = null, filterValues = [] } = {}) => {
    let grouping = groupByCount(array2D, colIdx, { caseSensitive: caseSensitive, filterIdx: filterIdx, filterValues: filterValues });
    let groupedData = Object.keys(grouping).map(key => grouping[key].value);
    // Return the unique list of actual values, preserving the datatype of the target column
    // Using just Object.keys would convert the values to string (undesired)
    return groupedData;
  }

  /**
   * Add padding to beginning of number to ensure the number
   * is always at least 2 digits in length once converted to a string.
   * 
   * @param {number} num Number to pad with with zeros.
   * @returns Stringified form of number with leading zeros.
   */
  const padTo2Digits = (num) => {
    let numStr = num.toString();
    if (numStr.includes(".")) {
      let tokens = numStr.split(".");
      numStr = `${tokens[0].padStart(2, "0")}.${tokens[1]}`;
    }
    else {
      numStr = numStr.padStart(2, "0");
    }

    return numStr;
  }

  /**
   * Convert seconds to hours, minutes, seconds in the format of HH:MM:SS.
   * 
   * @param {number} seconds Seconds to convert to HH:MM:SS string.
   * @param {object} config Optional configuration object with the following parameters:
   *                        {
   *                          roundHalfSeconds: Whether to round seconds to minutes if >= 30.
   *                          rollOverHours: Whether to reset hours to 0 each time they exceed 24.
   *                        }
   * @returns Formatted string representing seconds in HH:MM:SS form.
   */
  const convertSecondsToHMS = (seconds, { roundHalfSeconds = false, rollOverHours = false } = {}) => {
    // let seconds = Math.floor(seconds / 1000); // If milliseconds
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let modSeconds = seconds % 60;

    // If seconds are greater than 30, round minutes up if desired
    if (roundHalfSeconds) {
      minutes = modSeconds >= 30 ? minutes + 1 : minutes;
    }

    minutes = minutes % 60;

    // Roll hours over if desired, e.g. 24 to 00
    // Not rolling over hours results in `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    if (rollOverHours) {
      hours = hours % 24;
    }

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(roundStr(modSeconds, 3))}`;
  }

  /**
   * Sort an array by one or many columns.
   * 
   * Usage:
   *  orderBy(data, false, { id: "col1", direction: "asc" }, { id: "col3", direction: "desc" }, { ... }, ... );
   * 
   * @param {array} data Array of objects or arrays to be sorted.
   * @param {boolean} inplace Whether to overwrite the original array or return a new ordered version.
   * @param  {...object} cols Argument list of objects containing metadata for sorting (see usage format). 
   *                          Each column should assume the following structure:
   *                          {
   *                            id: string | number,
   *                            direction: string,
   *                            customSortingComparator: function | null,
   *                          }
   * @returns Ordered version of the provided data array. Not necessary if inplace flag is true.
   */
  const orderBy = (data, inplace = false, ...cols) => {
    // if (DEBUG_MODE) {
    //   console.log("Sorting data with config:", cols);
    // }

    const compare = (val1, val2, order = "asc") => {
      // let retVal;

      // if (typeof (val1) === "string" && typeof (val2) === "string") {
      //   if (order === "asc") {
      //     retVal = val1.localeCompare(val2);
      //   } else {
      //     retVal = val2.localeCompare(val1);
      //   }
      // } else {
      //   if (order === "asc") {
      //     retVal = val1 - val2;
      //   } else {
      //     retVal = val2 - val1;
      //   }
      // }

      // return retVal;

      let retVal = 0;

      if (val1 > val2) {
        retVal = 1;
      }
      else if (val1 < val2) {
        retVal = -1;
      }

      return order === "asc" ? retVal : -retVal;
    }

    let result = inplace ? data : [...data];
    result.sort((current, next) => {
      let retVal, currentVal, nextVal, newVal, direction, comparator;

      for (let col of cols) {
        currentVal = current[col.id];
        nextVal = next[col.id];

        direction = col.direction.toLowerCase();
        comparator = col.customSortingComparator ? col.customSortingComparator : compare;
        newVal = comparator(currentVal, nextVal, direction);

        if (retVal !== null) {
          retVal = retVal || newVal;
        }
        else {
          retVal = newVal;
        }
      }

      return retVal;
    });

    return result;
  }

  /**
   * Get minimum number value in a list of numbers.
   * 
   * This is a CallStack-Safe implementation.
   * See: https://stackoverflow.com/a/52613386
   * 
   * @param {object} arr Array of numbers.
   * @returns Number with the lowest value in the list of numbers.
   */
  const getMin = (arr) => {
    let len = arr.length;
    let min = Infinity;

    while (len--) {
      min = arr[len] < min ? arr[len] : min;
    }
    return min;
  }

  /**
   * Get maximum number value in a list of numbers.
   * 
   * This is a CallStack-Safe implementation.
   * See: https://stackoverflow.com/a/52613386
   * 
   * @param {object} arr Array of numbers.
   * @returns Number with the highest value in the list of numbers.
   */
  const getMax = (arr) => {
    let len = arr.length;
    let max = -Infinity;

    while (len--) {
      max = arr[len] > max ? arr[len] : max;
    }
    return max;
  }

  /**
   * Get the minimum and maximum values for a specific column of interest given
   * a two-dimensional array.
   * 
   * @param {array} array2D Two-dimensional array representing data to extract min/max values from.
   * @param {int} colIdx Column index targeting the respective column to get min/max for.
   * @returns Object containing min and max values for the target column.
   */
  const getMinMaxValues = (array2D, colIdx) => {
    let min = null;
    let max = null;
    if (array2D && array2D.length > 0) {
      // Support index lookup and key lookup for different types of data in 2D array
      let indexCondition = typeof(colIdx) === "number" && colIdx >= 0 && colIdx < array2D[0].length;
      let keyCondition = typeof(colIdx) === "string" && colIdx in array2D[0];

      if (indexCondition || keyCondition) {
        let values = array2D.map(row => row[colIdx]);
        min = getMin(values);
        max = getMax(values);
      }
    }

    return { min: min, max: max };
  }

  /**
   * Get the magnitude of a number (e.g., magnitude of 5432 is 1000, magnitude of 543 is 100, etc.)
   * 
   * See: https://stackoverflow.com/a/23917134
   * 
   * @param {number} n Number to get magnitude for.
   * @returns Magnitude associated with number.
   */
  const getMagnitude = (n) => {
    var order = Math.floor(Math.log(Math.abs(n)) / Math.LN10 +
      0.000000001); // Needed for floating point math issue
    return Math.pow(10, order);
  }

  /**
   * Add possible list of unique values associated with target column in dataset.
   * Only adds values if they do not exist (e.g., update not supported).
   * 
   * @param {string} columnName Column name used as key for adding grouped data index.
   * @param {list} data List of unique values in dataset associated with column name.
   */
  const addPossibleValuesToIndex = (columnName, data) => {
    // Only update if the grouped data doesn't already exist
    if (!(columnName in groupedDataRef.current)) {
      groupedDataRef.current = { ...groupedDataRef.current, [columnName]: data };
    }
  }

  /**
   * Update possible list of unique values associated with the target column in dataset.
   * Only updates values if they exist.
   * 
   * @param {string} columnName Column name used as key for adding grouped data index.
   * @param {list} data List of unique values in dataset associated with column name.
   */
  const updatePossibleValuesInIndex = (columnName, data) => {
    // Update if exists
    if (columnName in groupedDataRef.current) {
      groupedDataRef.current = { ...groupedDataRef.current, [columnName]: data };
    }
  }

  /**
   * Obtain stored grouped data for a target column by name. Purpose for
   * this function is to limit the amount of groupBy operations that are
   * actually performed during a single session.
   * 
   * @param {string} columnName Column name to obtain grouping for.
   */
  const getPossibleValuesByColumn = (columnName) => {
    return columnName in groupedData ? groupedData[columnName] : [];
  }

  /**
   * Check if two array objects are equal to each other.
   * 
   * @param {object} a First array object for comparison.
   * @param {object} b Second array object for comparison.
   * @returns Whether or not the two arrays equal each other.
   */
  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // Sort arrays
    // NOTE: This sorts the provied array objects inline (by reference)
    a.sort();
    b.sort();

    for (var i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  /**
   * Filter helper function used for comparing a data point to a set of values given a specific method
   * for comparison. Returns whether the provided data points passes or
   * fails the comparison against the set of values.
   * 
   * NOTE: Supported comparison methods may be added over time as needed.
   * 
   * @param {any} data Data to use for comparison.
   * @param {Array} values List of values to compare against.
   * @param {string} how Method for comparison. Can be one of [ "includes", "between" ].
   * @returns Whether the data comparison is successful or not (true or false).
   */
  const _doComparison = (data, values, how) => {
    if (!values || values.constructor !== Array) return false;
    if (!how || typeof(how) !== "string") return false;

    let expression = false;
    switch (how.toLowerCase()) {
      case "includes":
        expression = values.includes(data);
        break;
      case "between":
        if (values.length >= 2) {
          // Do inclusive comparison between first two values
          expression = data >= values[0] && data <= values[1];
        }

        break;
      default:
        break;
    }

    return expression;
  }


  /**
   * Drill down on existing base dataset. Will create a new filtered dataset as a result of filtering the base dataset
   * preserving the base data.
   * 
   * @param {object} controlFilters Set of control filters containing necessary filter values and metadata for applying filters.
   */
  const addDefaultFilter = (controlFilters) => {
    // Add key, this is required by later formats and moved here during redesign
    // controlFilters['key'] = controlFilters.column
      let newFilterList = defaultFilterList && defaultFilterList.length > 0? [...defaultFilterList] : [];
      for(let item in controlFilters){
        // don't push items without columns, as they're likely from useEffects prior to full initialization
        if(controlFilters[item]['column']){
        newFilterList.push(controlFilters[item])
        }
        // console.log(controlFilters)
        // item['key'] = item['column']
      // }
    }
        setDefaultFilterList(newFilterList)
        // Create filters lookup table to efficiently detect duplicate filters
        // let newFiltersLookup = newFilterList.reduce((prev, current) => {
          
        //   return { ...prev, [current.key]: { ...current } }
          
        // }, {});

        // console.log(newFiltersLookup)
        // setDefaultFilterList(newFiltersLookup)
  
  }
  /**
   * Drill down on existing base dataset. Will create a new filtered dataset as a result of filtering the base dataset
   * preserving the base data.
   * 
   * @param {object} controlFilters Set of control filters containing necessary filter values and metadata for applying filters.
   */
  const addControlFilter = (controlFilters) => {
    // Add key, this is required by later formats and moved here during redesign
    // controlFilters['key'] = controlFilters.column
      // if(controlFilters.length > 0){

      let newFilterList = filterList && filterList.length > 0? [...filterList] : [];
      for(let item in controlFilters){
        newFilterList.push(controlFilters[item])
        // console.log(controlFilters)
        // item['key'] = item['column']
      // }
    }

    let filterReduced = newFilterList.reduce((prev, current) => {
      return { ...prev, [current.id]: { ...current } }
    }, {});


      // controlFilters['key'] = controlFilters['column']
        
        
        // console.log(newFilterList)
        setFilterList(Object.values(filterReduced))
  
  }

  

  /**
   * Drill down on existing base dataset. Will create a new filtered dataset as a result of filtering the base dataset
   * preserving the base data.
   * 
   * @param {object} controlFilters Set of control filters containing necessary filter values and metadata for applying filters.
   */
  const handleControlFilter = (controlFilters) => {
    if (baseData) {

      // Turn off flag indicating all filters were reset
      if (allFiltersReset) {
        setAllFiltersReset(false);
      }

      // Obtain reference to lookup tables
      const {
        track_points: { lookup: trackPointsLookup = null } = {},
        unique_track_points: { lookup: uniqueTrackPointsLookup = null } = {},
        exceedance_point: { lookup: exceedancePointsLookup = null } = {},
        exceedance_event: { lookup: eventsLookup = null } = {},
        obstacles: { lookup: obstaclesLookup = null } = {},
        gridsumm: { lookup: gridsummLookup = null } = {},
        uas_sightings: { lookup: sightingsLookup = null } = {},
        uimc_events: { lookup: uimcEventsLookup = null } = {},
      } = baseData;

      // Get unique key set for control filters
      let keys = Object.keys(controlFilters);
      if("confidence_score" in keys.toString){
        // console.log("matched")
      }
      // console.log(filterList)
      if (keys.length > 0) {
        // Build filtered dataset iteratively and update state after
        // let targetData = filteredData ? filteredData : baseData;
        let targetData = baseData;

        // Only re-use filtered dataset if the previous applied filter id doesn't match the current filter id and it's not the same column
        // if (filteredData) {
        //   let prevFilter = filterList.length > 0 ? filterList[filterList.length - 1] : null;
        //   // console.log("Previously applied filter:", prevFilter);
        //   console.log({prevFilter})
        //   console.log({controlFilters})
        //   if (prevFilter && !(prevFilter.id in controlFilters && controlFilters[prevFilter.id].column === prevFilter.column)) {
        //     // console.log("|-- Targeting filtered dataset for applied filter...");
        //     targetData = filteredData;
        //   } else {
        //     // console.log("|-- Previous applied filter id matches the current filter id... ");
        //   }
        // }
        // else {
        //   // console.log("|-- Targeting base dataset for applied filter...");
        // }

        let newFilteredData = { ...targetData }; // Need to make a copy to prevent overwriting the original dataset
        // let newFilterList = [...controlFilters];

        // console.log("Raw,", newFilterList)
        // Create filters lookup table to efficiently detect duplicate filters
        // let newFiltersLookup = newFilterList.reduce((prev, current) => {
        //   // console.log(prev)
        //   // console.log(current)
        //   return { ...prev, [current.key]: { ...current } }
        // }, {});
        let newFiltersLookup = controlFilters

        // console.log("|-- Starting filter list:", [ ...newFilterList ]);
        // console.log("|-- New Filters Lookup:", { ...newFiltersLookup });
        // console.log("|-- Control filter keys:", keys);

        // Determine which exceedance datasets are updated
        let updatedExceedanceEvents = false;
        let updatedExceedancePoints = false;

        // Continue drilling down on control filters if there are more
        for (let i = 0; i < keys.length; i++) {
          let entry = controlFilters[keys[i]];
          const {
            id = "filter-id",
              title = null,
              column: key = "filter-key",
              values = [],
              track = false,
              how = "includes",
          } = entry;
          // console.log("Entry", entry)
          // console.log("id", id)
          // console.log("how", how)
          // console.log("lookup", uimcEventsLookup)
          // console.log("key", key)
          // Extract lookup from aggregations for designated key if exists
          const { aggregations: {
              [key]: { lookup: aggregationsLookup = null } = {} } = {} } = newFilteredData;
          // let aggregationsLookup = key in newFilteredData.aggregations ? newFilteredData.aggregations[key].lookup : null;

          if (trackPointsLookup) {
            if (key in trackPointsLookup) {
              newFilteredData.track_points = {
                ...newFilteredData.track_points,
                data: newFilteredData.track_points.data.filter(
                  record => _doComparison(record[trackPointsLookup[key]], values, how)
                )
              };
            }
          }

          if (uniqueTrackPointsLookup) {
            if (key in uniqueTrackPointsLookup) {
              newFilteredData.unique_track_points = {
                ...newFilteredData.unique_track_points,
                data: newFilteredData.unique_track_points.data.filter(
                  record => _doComparison(record[uniqueTrackPointsLookup[key]], values, how)
                )
              };
            }
          }

          if (exceedancePointsLookup) {
            if (key in exceedancePointsLookup) {
              newFilteredData.exceedance_point = {
                ...newFilteredData.exceedance_point,
                data: newFilteredData.exceedance_point.data.filter(
                  record => _doComparison(record[exceedancePointsLookup[key]], values, how)
                )
              };

              updatedExceedancePoints = true;
            }
          }

          // Update exceedance event data (and any other relevant/related data) with same key
          // NOTE: This requires a common column naming convention across disjoint datasets 
          // Check if key exists in associated lookup table
          if (eventsLookup) {
            if (key in eventsLookup) {
              newFilteredData.exceedance_event = {
                ...newFilteredData.exceedance_event,
                data: newFilteredData.exceedance_event.data.filter(
                  record => _doComparison(record[eventsLookup[key]], values, how)
                )
              };

              updatedExceedanceEvents = true;
            }
            else {
              // Handle corner case for VRS
              if (key === "vrs") {
                // If more than one VRS boolean value then no need to process (since using base data as baseline)
                if (values.length === 1) {
                  // Parse values
                  const vrs = values[0];

                  if (vrs !== null) {
                    // vrs maps to exceedance_subtype where exceedance_subtype is 'Vortex Ring State' if vrs is true. Otherwise, where not equal to 'Vortex Ring State'
                    newFilteredData.exceedance_event = {
                      ...newFilteredData.exceedance_event,
                      data: newFilteredData.exceedance_event.data.filter(
                        record => vrs ? record[eventsLookup.exceedance_subtype] === 'Vortex Ring State' :
                        record[eventsLookup.exceedance_subtype] !== 'Vortex Ring State'
                      )
                    }

                    updatedExceedanceEvents = true;
                  }
                }
              }
            }
          }

          if (obstaclesLookup) {
            if (key in obstaclesLookup) {
              newFilteredData.obstacles = {
                ...newFilteredData.obstacles,
                data: newFilteredData.obstacles.data.filter(
                  record => _doComparison(record[obstaclesLookup[key]], values, how)
                )
              }
            }
          }

          if (gridsummLookup) {
            if (key in gridsummLookup) {
              newFilteredData.gridsumm = {
                ...newFilteredData.gridsumm,
                data: newFilteredData.gridsumm.data.filter(
                  record => _doComparison(record[gridsummLookup[key]], values, how)
                )
              }
            }
          }

          if (sightingsLookup) {
            if (key in sightingsLookup) {
              newFilteredData.uas_sightings = {
                ...newFilteredData.uas_sightings,
                data: newFilteredData.uas_sightings.data.filter(
                  record => _doComparison(record[sightingsLookup[key]], values, how)
                )
              }
            }
          }

          if (uimcEventsLookup) {
            // console.log(key)
            // console.log(values)
            // console.log(how)
            // console.log(uimcEventsLookup[key])
            if (key in uimcEventsLookup) {
              newFilteredData.uimc_events = {
                ...newFilteredData.uimc_events,
                data: newFilteredData.uimc_events.data.filter(
                  record => _doComparison(record[uimcEventsLookup[key]], values, how)
                )
              };
            }
          }
          // console.log(newFilteredData)

          // Only filter aggregations if exists
          if (aggregationsLookup) {
            newFilteredData.aggregations = {
              ...newFilteredData.aggregations,
              [key]: {
                ...newFilteredData.aggregations[key],
                data: newFilteredData.aggregations[key].data.filter(
                  record => _doComparison(record[aggregationsLookup[key]], values, how)
                )
              }
            };
          }

          // filterWidgetNotifierRef.current = { id: id, values: values };
          filterWidgetNotifierRef.current = {
            ...filterWidgetNotifierRef.current,
            [id]: { values: values, propagate: false }
          };

          // Check if the control filter should be tracked in history for undo/redo capabilities
          if (track) {
            // Check that the control filter isn't a duplicate (don't apply duplicate filters)
            let duplicateFilter = key in newFiltersLookup &&
              key === newFiltersLookup[key].column &&
              arraysEqual(newFiltersLookup[key].values, values);

            // // console.log("Duplicate filter =", duplicateFilter);
            // // console.log("Current Filter:", entry);
            // // console.log("Existing Filter:", newFiltersLookup[key]);
            // // console.log("Filter list:", newFilterList);
            if (!duplicateFilter) {
            //   // Add new filter to new filter list (track each filter separately)
            //   let newFilter = { id: id, title: title, key: key, values: values, how: how };
            //   // console.log("Tracking new filter:", newFilter);
            //   filterList.push(newFilter);
            }
          }
        }

        // Ensure exceedance points and exceedance events datasets are synced
        if (exceedancePointsLookup && eventsLookup) {
          const { data: exceedancePointsData = [] } = newFilteredData.exceedance_point;
          const { data: exceedanceTypeData = [] } = newFilteredData.exceedance_event;

          // Determine which exceedance dataset takes precedence based on whether the key exists in respective lookup tables or not
          let prioritizePoints = updatedExceedancePoints && !updatedExceedanceEvents; // NOTE: Should end up prioritizing events in most cases          
          let priorityDataTarget = prioritizePoints ? exceedancePointsData : exceedanceTypeData;
          let priorityLookupTarget = prioritizePoints ? exceedancePointsLookup : eventsLookup;
          let nonPriorityDataTarget = prioritizePoints ? exceedanceTypeData : exceedancePointsData;
          let nonPriorityLookupTarget = prioritizePoints ? eventsLookup : exceedancePointsLookup;
          let nonPriorityKey = prioritizePoints ? "exceedance_event" : "exceedance_point";

          // Obtain unique types and severities based on priority
          let priorityTypes = groupBy(priorityDataTarget, priorityLookupTarget.exceedance_subtype);
          let prioritySeverities = groupBy(priorityDataTarget, priorityLookupTarget.exceedance_severity);
          let nonPriorityTypes = groupBy(nonPriorityDataTarget, nonPriorityLookupTarget.exceedance_subtype);

          if (DEBUG_MODE) {
            console.log("Exceedance Datasets Update Synopsis (from handleControlFilter function):");
            console.log("|-- Exceedance Points Updated =", updatedExceedancePoints);
            console.log("|-- Exceedance Events Updated =", updatedExceedanceEvents);
            console.log("|-- Prioritizing Exceedance Points =", prioritizePoints);
            console.log("|-- Non-priority Data Key =", nonPriorityKey);
          }

          // If the the available exceedance types don't match between datasets then sync them
          if (nonPriorityTypes.length !== priorityTypes.length) {
            newFilteredData[nonPriorityKey] = {
              ...newFilteredData[nonPriorityKey],
              data: baseData[nonPriorityKey].data.filter(
                record => {
                  // Ensure that both the types and severities match
                  let includesType = priorityTypes.includes(record[nonPriorityLookupTarget.exceedance_subtype]);
                  let includesSeverity = prioritySeverities.includes(record[nonPriorityLookupTarget.exceedance_severity]);
                  return includesType && includesSeverity;
                }
              )
            }
          }
        }

        // console.log("Filtered data", newFilteredData);

        // Track sum of points for each dataset to determine if resulting filtered dataset should be nullified
        let filterSum = Object.keys(newFilteredData).reduce((prev, current) => {
          return newFilteredData[current].data ? prev + newFilteredData[current].data.length : prev;
        }, 0);

        // console.log("Filter Sum (nullify filtered data if 0):", filterSum);

        // Initialize filtered data to null if none of the data lists have values (the sum of all data points equals 0)
        if (filterSum === 0) {
          newFilteredData = null;
        }
        // console.log("Base Data check", baseData)
        // console.log("setFilteredData", newFilteredData)
        // Store the new filtered data in state
        setFilteredData(newFilteredData);
        // console.log("Invoking function to build reduced dataset from handleControlFilter function...");
        buildReducedDataset(newFilteredData, true);

        // Update the filter list with the new filter list
        // console.log("Updating filter list with:", newFilterList);
        // console.log("Setting filter", newFilterList)
        // setFilterList(newFilterList);

      }
      else {
        console.error("No values provided to filter by");
      }
    }
    else {
      console.error("Drilldown filter failed. Data does not yet exist.");
    }
  }

  /**
   * Iterate updated list of filters and build new filtered dataset.
   * 
   * @param {list} updatedFilters List of updated filters to apply to dataset.
   */
  const applyUpdatedFilters = (updatedFilters) => {
    // console.log("Applying updated filters:", updatedFilters);
    let newFilteredData = { ...baseData };

    // Obtain reference to lookup tables
    const {
      track_points: { lookup: trackPointsLookup = null } = {},
      unique_track_points: { lookup: uniqueTrackPointsLookup = null } = {},
      exceedance_point: { lookup: exceedancePointsLookup = null } = {},
      exceedance_event: { lookup: eventsLookup = null } = {},
      obstacles: { lookup: obstaclesLookup = null } = {},
      gridsumm: { lookup: gridsummLookup = null } = {},
      uas_sightings: { lookup: sightingsLookup = null } = {},
      uimc_events: { lookup: uimcEventsLookup = null } = {},
    } = baseData;

    // Determine which exceedance datasets are updated
    let updatedExceedanceEvents = false;
    let updatedExceedancePoints = false;

    for (let filter of updatedFilters) {
      const { key = "filter-key", values = [], how = "includes" } = filter;

      // Extract lookup from aggregations for designated key if exists
      const {
        aggregations: {
          [key]: { lookup: aggregationsLookup = null } = {}
        } = {}
      } = newFilteredData;
      // let aggregationsLookup = key in newFilteredData.aggregations ? newFilteredData.aggregations[key].lookup : null;

      if (trackPointsLookup) {
        if (key in trackPointsLookup) {
          newFilteredData.track_points = {
            ...newFilteredData.track_points,
            data: newFilteredData.track_points.data.filter(
              record => _doComparison(record[trackPointsLookup[key]], values, how)
            )
          };
        }
      }

      if (uniqueTrackPointsLookup) {
        if (key in uniqueTrackPointsLookup) {
          newFilteredData.unique_track_points = {
            ...newFilteredData.unique_track_points,
            data: newFilteredData.unique_track_points.data.filter(
              record => _doComparison(record[uniqueTrackPointsLookup[key]], values, how)
            )
          };
        }
      }

      if (exceedancePointsLookup) {
        if (key in exceedancePointsLookup) {
          newFilteredData.exceedance_point = {
            ...newFilteredData.exceedance_point,
            data: newFilteredData.exceedance_point.data.filter(
              record => _doComparison(record[exceedancePointsLookup[key]], values, how)
            )
          };

          updatedExceedancePoints = true;
        }
      }

      if (eventsLookup) {
        if (key in eventsLookup) {
          newFilteredData.exceedance_event = {
            ...newFilteredData.exceedance_event,
            data: newFilteredData.exceedance_event.data.filter(
              record => _doComparison(record[eventsLookup[key]], values, how)
            )
          };

          updatedExceedanceEvents = true;
        }
        else {
          // Handle corner case for VRS
          if (key === "vrs") {
            // If more than one VRS boolean value then no need to process (since using base data as baseline)
            if (values.length === 1) {
              // Parse values
              const vrs = values[0];

              if (vrs !== null) {
                // vrs maps to exceedance_subtype where exceedance_subtype is 'Vortex Ring State' if vrs is true. Otherwise, where not equal to 'Vortex Ring State'
                newFilteredData.exceedance_event = {
                  ...newFilteredData.exceedance_event,
                  data: newFilteredData.exceedance_event.data.filter(
                    record => vrs ? record[eventsLookup.exceedance_subtype] === 'Vortex Ring State' :
                    record[eventsLookup.exceedance_subtype] !== 'Vortex Ring State'
                  )
                };

                updatedExceedanceEvents = true;
              }
            }
          }
        }
      }

      if (obstaclesLookup) {
        if (key in obstaclesLookup) {
          newFilteredData.obstacles = {
            ...newFilteredData.obstacles,
            data: newFilteredData.obstacles.data.filter(
              record => _doComparison(record[obstaclesLookup[key]], values, how)
            )
          }
        }
      }

      if (gridsummLookup) {
        if (key in gridsummLookup) {
          newFilteredData.gridsumm = {
            ...newFilteredData.gridsumm,
            data: newFilteredData.gridsumm.data.filter(
              record => _doComparison(record[gridsummLookup[key]], values, how)
            )
          }
        }
      }

      if (sightingsLookup) {
        if (key in sightingsLookup) {
          newFilteredData.uas_sightings = {
            ...newFilteredData.uas_sightings,
            data: newFilteredData.uas_sightings.data.filter(
              record => _doComparison(record[sightingsLookup[key]], values, how)
            )
          }
        }
      }

      if (uimcEventsLookup) {
        if (key in uimcEventsLookup) {
          newFilteredData.uimc_events = {
            ...newFilteredData.uimc_events,
            data: newFilteredData.uimc_events.data.filter(
              record => _doComparison(record[uimcEventsLookup[key]], values, how)
            )
          };
        }
      }

      // Only filter aggregations if exists
      if (aggregationsLookup) {
        newFilteredData.aggregations = {
          ...newFilteredData.aggregations,
          [key]: {
            ...newFilteredData.aggregations[key],
            data: newFilteredData.aggregations[key].data.filter(
              record => _doComparison(record[aggregationsLookup[key]], values, how)
            )
          }
        };
      }
    }

    // Ensure exceedance points and exceedance events datasets are synced
    // if (exceedancePointsLookup && eventsLookup) {
    //   const { data: exceedancePointsData = [] } = newFilteredData.exceedance_point;
    //   let exceedancePointTypes = groupBy(exceedancePointsData, exceedancePointsLookup.exceedance_subtype);
    //   // let exceedancePointSeverities = groupBy(exceedancePointsData, exceedancePointsLookup.exceedance_severity);

    //   const { data: exceedanceTypeData = [] } = newFilteredData.exceedance_event;
    //   let exceedanceEventTypes = groupBy(exceedanceTypeData, eventsLookup.exceedance_subtype);
    //   let exceedanceEventSeverities = groupBy(exceedanceTypeData, eventsLookup.exceedance_severity);

    //   // If the the available exceedance point types don't match the exceedance event types then sync them
    //   if (exceedancePointTypes.length !== exceedanceEventTypes.length) {
    //     // Exceedance events take precedence so update the exceedance points dataset accordingly
    //     newFilteredData.exceedance_point = {
    //       ...newFilteredData.exceedance_point,
    //       data: baseData.exceedance_point.data.filter(
    //         record => {
    //           // Ensure that both the types and severities match
    //           let includesType = exceedanceEventTypes.includes(record[exceedancePointsLookup.exceedance_subtype]);
    //           let includesSeverity = exceedanceEventSeverities.includes(record[exceedancePointsLookup.exceedance_severity]);
    //           return includesType && includesSeverity;
    //         }
    //       )
    //     };
    //   }
    // }
    if (exceedancePointsLookup && eventsLookup) {
      const { data: exceedancePointsData = [] } = newFilteredData.exceedance_point;
      const { data: exceedanceTypeData = [] } = newFilteredData.exceedance_event;

      // Determine which exceedance dataset takes precedence based on whether the key exists in respective lookup tables or not
      let prioritizePoints = updatedExceedancePoints && !updatedExceedanceEvents; // NOTE: Should end up prioritizing events in most cases          
      let priorityDataTarget = prioritizePoints ? exceedancePointsData : exceedanceTypeData;
      let priorityLookupTarget = prioritizePoints ? exceedancePointsLookup : eventsLookup;
      let nonPriorityDataTarget = prioritizePoints ? exceedanceTypeData : exceedancePointsData;
      let nonPriorityLookupTarget = prioritizePoints ? eventsLookup : exceedancePointsLookup;
      let nonPriorityKey = prioritizePoints ? "exceedance_event" : "exceedance_point";

      // Obtain unique types and severities based on priority
      let priorityTypes = groupBy(priorityDataTarget, priorityLookupTarget.exceedance_subtype);
      let prioritySeverities = groupBy(priorityDataTarget, priorityLookupTarget.exceedance_severity);
      let nonPriorityTypes = groupBy(nonPriorityDataTarget, nonPriorityLookupTarget.exceedance_subtype);

      if (DEBUG_MODE) {
        console.log("Exceedance Datasets Update Synopsis (from applyUpdatedFilters function):");
        console.log("|-- Exceedance Points Updated =", updatedExceedancePoints);
        console.log("|-- Exceedance Events Updated =", updatedExceedanceEvents);
        console.log("|-- Prioritizing Exceedance Points =", prioritizePoints);
        console.log("|-- Non-priority Data Key =", nonPriorityKey);
      }

      // If the the available exceedance types don't match between datasets then sync them
      if (nonPriorityTypes.length !== priorityTypes.length) {
        newFilteredData[nonPriorityKey] = {
          ...newFilteredData[nonPriorityKey],
          data: baseData[nonPriorityKey].data.filter(
            record => {
              // Ensure that both the types and severities match
              let includesType = priorityTypes.includes(record[nonPriorityLookupTarget.exceedance_subtype]);
              let includesSeverity = prioritySeverities.includes(record[nonPriorityLookupTarget.exceedance_severity]);
              return includesType && includesSeverity;
            }
          )
        }
      }
    }

    // Track sum of points for each dataset to determine if resulting filtered dataset should be nullified
    let filterSum = Object.keys(newFilteredData).reduce((prev, current) => {
      return newFilteredData[current].data ? prev + newFilteredData[current].data.length : prev;
    }, 0);

    // Initialize filtered data to null if none of the data lists have values (the sum of all data points equals 0)
    if (filterSum === 0) {
      newFilteredData = null;
    }

    // Store the new filtered data in state
    setFilteredData(newFilteredData);

    // console.log("Invoking function to build reduced dataset from applyUpdatedFilters function...");
    buildReducedDataset(newFilteredData, true);
  }

  /**
   * Take the last applied filter off the filter list stack and place
   * it in the redo stack. Using the metadata of the applied filters,
   * the associated filter select widget instance will be updated by key
   * after the filtered dataset is updated.
   */
  const undoLastAppliedFilter = () => {
    if (filterList.length > 0) {
      let updatedFilters = [...filterList];
      let lastAppliedFilter = updatedFilters.pop(); // LIFO method to retrieve last inserted filter from end of list
      // console.log("Filter list before undoing:", [ ...filterList ]);
      // console.log("Last applied filter:", lastAppliedFilter);

      // Check the last filter applied after poping to see if there's another filter that belongs to the same group
      // TODO Going forward, expand this capability to support filter groups larger than two
      let relatedAppliedFilter = null; // Pointer to related filter previous to the last applied filter, if it exists
      if (lastAppliedFilter.id in FILTER_GROUP_ID_LOOKUP) {
        if (updatedFilters.length > 0) {
          let prevId = updatedFilters[updatedFilters.length - 1].id;
          if (prevId in FILTER_GROUP_ID_LOOKUP) {
            // Pop the last applied filter and keep reference to both filters to reapply them later when the user desires to redo the filters
            if (FILTER_GROUP_ID_LOOKUP[lastAppliedFilter.id] === FILTER_GROUP_ID_LOOKUP[prevId]) {
              relatedAppliedFilter = updatedFilters.pop();
            }
          }
        }
      }

      // Exclude all other filters that have the same id from the updated filters list except for the last occurrence in the list
      let uniqueFiltersLookup = updatedFilters.reduce((prev, curr) => { return { ...prev, [curr.id]: curr } }, {});
      let uniqueFilters = Object.entries(uniqueFiltersLookup).map(item => item[1]);
      // console.log("Unique filters to apply:", uniqueFilters);

      // Apply new set of filters to dataset
      applyUpdatedFilters(uniqueFilters);

      // Reflect changes in filter list
      // console.log("Setting new updated filters...", updatedFilters);
      setFilterList(updatedFilters);

      // Add undone filter(s) to redo stack
      // NOTE: Related applied filter(s) should be applied in a FIFO sequence (e.g., queue).
      //       Therefore, each related applied filter should be prepended.
      let lastAppliedFilters = [lastAppliedFilter];
      if (relatedAppliedFilter) {
        lastAppliedFilters.unshift(relatedAppliedFilter);
      }

      setRedoFilterList([...redoFilterList, ...lastAppliedFilters]);

      // Get history of filters selected for last applied filter widget values
      let filterHistory = null;
      for (let f of lastAppliedFilters) {
        let target = filterHistory === null ? updatedFilters : filterHistory;
        filterHistory = target.filter(item => item.id === f.id);
      }

      // console.log("History for last applied filter: ", history);

      // Depending on history length, notify filter select widgets as necessary
      if (filterHistory.length > 0) {
        let previousSelection = filterHistory.pop();

        filterWidgetNotifierRef.current = {
          ...filterWidgetNotifierRef.current,
          [previousSelection.id]: { values: previousSelection.values, propagate: false }
        };
      }
      else {
        // console.log("Need to notify filter widget notifier ref...");
        let notification = {
          [lastAppliedFilter.id]: { values: getPossibleValuesByColumn(lastAppliedFilter.key), propagate: false }
        };

        if (relatedAppliedFilter) {
          notification[relatedAppliedFilter.id] = { values: getPossibleValuesByColumn(relatedAppliedFilter.key), propagate: false };
        }

        filterWidgetNotifierRef.current = {
          ...filterWidgetNotifierRef.current,
          ...notification,
        };
      }
    }
  }

  /**
   * Take the last undone filter that exists in the redo stack, reapply
   * the filter and add it back to the main filter stack. Using the metadata 
   * of the applied filters, the associated filter select widget instance 
   * will be updated by key after the filtered dataset is updated.
   */
  const redoLastUndoneFilter = () => {
    if (redoFilterList.length > 0) {
      let updatedFilters = [...filterList];
      let updatedRedoFilters = [...redoFilterList];
      let lastUndoneFilter = updatedRedoFilters.pop();

      // Check the last undone filter after poping to see if there's another filter that belongs to the same group
      // TODO Going forward, expand this capability to support filter groups larger than two
      let relatedUndoneFilter = null; // Pointer to related filter previous to the last undone filter, if it exists
      if (lastUndoneFilter.id in FILTER_GROUP_ID_LOOKUP) {
        if (updatedRedoFilters.length > 0) {
          let prevId = updatedRedoFilters[updatedRedoFilters.length - 1].id;
          if (prevId in FILTER_GROUP_ID_LOOKUP) {
            // Pop the last undone filter and keep reference to both filters to undo them later when the user desires to undo the filters
            if (FILTER_GROUP_ID_LOOKUP[lastUndoneFilter.id] === FILTER_GROUP_ID_LOOKUP[prevId]) {
              relatedUndoneFilter = updatedRedoFilters.pop();
            }
          }
        }
      }

      // Add undone filter(s) back to main filter stack
      // NOTE: Related undone filter(s) should be undone in a FIFO sequence (e.g., queue).
      //       Therefore, each related undone filter should be prepended.
      let lastUndoneFilters = [lastUndoneFilter];
      if (relatedUndoneFilter) {
        lastUndoneFilters.unshift(relatedUndoneFilter);
      }

      updatedFilters.push(...lastUndoneFilters);

      // Exclude all other filters that have the same id from the updated filters list except for the last occurrence in the list
      let uniqueFiltersLookup = updatedFilters.reduce((prev, curr) => { return { ...prev, [curr.id]: curr } }, {});
      let uniqueFilters = Object.entries(uniqueFiltersLookup).map(item => item[1]);

      // Apply new set of filters to dataset
      applyUpdatedFilters(uniqueFilters);

      // Reflect changes in filter list
      setFilterList(updatedFilters);

      // Reflect changes to redo stack
      setRedoFilterList(updatedRedoFilters);

      // Create notification to set values of respective filter select widget(s)
      let notification = {
        [lastUndoneFilter.id]: { values: lastUndoneFilter.values, propagate: false }
      };

      if (relatedUndoneFilter) {
        notification[relatedUndoneFilter.id] = { values: relatedUndoneFilter.values, propagate: false };
      }

      filterWidgetNotifierRef.current = {
        ...filterWidgetNotifierRef.current,
        ...notification,
      };
    }
  }

  /**
   * Use the key and id pair to find a filter and undo it.
   * For now undo any filters matching those terms.
   */
  const undoSpecificFilter = (key, id) => {
    console.log("Starting undo filter operation: " + key + " " + id)
    const newFilterList = [].concat(filterList)
    console.log(`Filter Length: ${filterList.length}`)
    if (filterList.length > 0) {
      let updatedFilters = [...filterList];
      updatedFilters.map((filter, index) => {
        console.log("Index: " + index + " Filter: " +filter)
        if(filter.id === id && filter.key === key){
          console.log("Match FOund")
          let lastAppliedFilter = filter;
          // remove filter from list
          newFilterList.splice(index, 1)
          // console.log(newFilterList)

          console.log(`Filter Length: ${newFilterList.length}`)
          // let relatedAppliedFilter = null; // Pointer to related filter previous to the last applied filter, if it exists
          // if (lastAppliedFilter.id in FILTER_GROUP_ID_LOOKUP) {
          //   if (updatedFilters.length > 0) {
          //     let prevId = updatedFilters[updatedFilters.length - 1].id;
          //     if (prevId in FILTER_GROUP_ID_LOOKUP) {
          //       // Pop the last applied filter and keep reference to both filters to reapply them later when the user desires to redo the filters
          //       if (FILTER_GROUP_ID_LOOKUP[lastAppliedFilter.id] === FILTER_GROUP_ID_LOOKUP[prevId]) {
          //         relatedAppliedFilter = updatedFilters.pop();
          //       }
          //     }
          //   }
          // }
        
        // Exclude all other filters that have the same id from the updated filters list except for the last occurrence in the list
      let uniqueFiltersLookup = newFilterList.reduce((prev, curr) => { return { ...prev, [curr.id]: curr } }, {});
      let uniqueFilters = Object.entries(uniqueFiltersLookup).map(item => item[1]);
      // console.log("Unique filters to apply:", uniqueFilters);

      // Apply new set of filters to dataset
      applyUpdatedFilters(uniqueFilters);

      // Reflect changes in filter list
      // console.log("Setting new updated filters...", newFilterList);
      console.log({newFilterList})
      setFilterList(newFilterList);

      // Add undone filter(s) to redo stack
      // NOTE: Related applied filter(s) should be applied in a FIFO sequence (e.g., queue).
      //       Therefore, each related applied filter should be prepended.
      let lastAppliedFilters = [lastAppliedFilter];
      // if (relatedAppliedFilter) {
      //   lastAppliedFilters.unshift(relatedAppliedFilter);
      // }

      setRedoFilterList([...redoFilterList, ...lastAppliedFilters]);

      // Get history of filters selected for last applied filter widget values
      let filterHistory = null;
      for (let f of lastAppliedFilters) {
        let target = filterHistory === null ? updatedFilters : filterHistory;
        filterHistory = target.filter(item => item.id === f.id);
      }

      // console.log("History for last applied filter: ", history);

      // Depending on history length, notify filter select widgets as necessary
      if (filterHistory.length > 0) {
        let previousSelection = filterHistory.pop();

        filterWidgetNotifierRef.current = {
          ...filterWidgetNotifierRef.current,
          [previousSelection.id]: { values: previousSelection.values, propagate: false }
        };
      }
      else {
        // console.log("Need to notify filter widget notifier ref...");
        let notification = {
          [lastAppliedFilter.id]: { values: getPossibleValuesByColumn(lastAppliedFilter.key), propagate: false }
        };

        // if (relatedAppliedFilter) {
        //   notification[relatedAppliedFilter.id] = { values: getPossibleValuesByColumn(relatedAppliedFilter.key), propagate: false };
        // }

        filterWidgetNotifierRef.current = {
          ...filterWidgetNotifierRef.current,
          ...notification,
        };
      }
        
        }
      })
      
      

      
    }
  }

  /**
   * Reset all applied filters by clearing out traced filter data and
   * associated lists for managing filter controls.
   */
  const resetAllFilters = () => {
    selectedFiltersRef.current = {};
    filterWidgetNotifierRef.current = {};

    setFilteredData(null);
    setFilteredReducedData(null);
    setFilterList([]);
    setRedoFilterList([]);

    // Notify sync
    setAllFiltersReset(true); // Notify that all filters have been reset for all interested/subscribed components
  }

  /**
   * Convert decimal degrees to radians.
   * 
   * @param {number} degrees Decimal degrees to convert to radians.
   * @returns Converted radians from decimal degrees.
   */
  const toRadians = (degrees) => {
    return degrees * Math.PI / 180;
  }

  /**
   * Convert radians to decimal degrees.
   * 
   * @param {number} radians Radians to convert to decimal degrees.
   * @returns Converted decimal degrees from radians.
   */
  const toDegrees = (radians) => {
    return radians * 180 / Math.PI;
  }

  /**
   * Convert a string to camel case.
   * 
   * EX: If the provide string is 'hello', the result will be 'Hello'
   * 
   * @param {string} str String to convert to camel case.
   * @returns Camel-Cased string.
   */
  const toCamelCase = (str) => {
    let r = str;
    if (typeof(str) === "string" && str !== "") {
      r = str[0].toUpperCase() + str.slice(1).toLowerCase();
    }

    return r;
  }

  /**
   * Take an input string and transform it into a capitalized, title cased version.
   * 
   * EX: original_string -> Original String
   * 
   * @param {string} str String to parse and capitalize each word.
   * @param {string} separator Separator to split input string on when parsing and capitalizing.
   * @returns New string representing a capitalized title case of the original string.
   */
  const capitalizeWords = (str, separator) => {
    let result = str;

    // Corner case handle for null or undefined
    if (str === null || str === undefined) {
      result = "Null";
    }
    else {
      let newStr = str.toString();

      // Pseudo-Dynamic separator detection support (only detecting underscores or spaces)
      let sep = separator;
      if (sep === null || sep === undefined || sep === "") {
        sep = newStr.includes("_") ? "_" : " ";
      }

      // One final check to see if separator is in string
      if (newStr.includes(sep)) {
        // NOTE: ensure that input str arg is actually string by parsing to string
        result = newStr.trim().split(sep).map(
          (ele) => {
            let r = "";

            // Handle corner case for when string contains hyphens
            if (ele.includes("-")) {
              let tokens = ele.split("-");
              r = tokens.map(token => toCamelCase(token)).join("-");
            }
            else {
              r = toCamelCase(ele);
            }

            return r;
          }
        ).join(" ");
      }
      else {
        // Otherwise treat the input string as a single word or character
        if (newStr.length > 0) {
          result = toCamelCase(newStr);
        }
      }
    }

    return result;
  };

  /**
   * Set selected filters for an individual filter widget instance provided its associated key.
   * This function stores an entry for the respective filter widget selection for future retrieval.
   * 
   * @param {string} key Key of the filter select widget instance the filters are coming from.
   * @param {array} filters List of filters to set as selected for the respective filter widget instance.
   */
  const setSelectedFilters = (key, filters) => {
    // console.log(`Setting selected filters for ${key} in data context`, filters);
    selectedFiltersRef.current[key] = filters;
  }

  /**
   * Get list of selected filters for a respective filter select component instance.
   * 
   * @param {string} id Id corresponding to the filter select component instance to retrieve filters for.
   */
  const getSelectedFiltersById = (id) => {
    return id in selectedFiltersRef.current ? selectedFiltersRef.current[id] : [];
  }

  const showSnack = (vertical, horizontal, message, severityCode, duration = DEFAULT_SNACK_DURATION) => {
    // Available severity levels
    const severities = ["error", "warning", "info", "success"];
    const validSeverityCode = severityCode >= 0 && severityCode < severities.length;
    let severity = validSeverityCode ? severities[severityCode] : "success";
    // let color = validSeverityCode ? SNACK_COLORS[severity.toUpperCase()] : SNACK_COLORS.SUCCESS;

    setSnackVertical(vertical);
    setSnackHorizontal(horizontal);
    setSnackMessage(message);
    setSnackSeverity(severity);
    // setSnackColor(color);
    setSnackDuration(duration);
    setOpenSnack(true);
  }

  /**
   * Sync other maps with parent map when the parent map's viewport changes (e.g., when panning).
   * 
   * @param {leaflet.map} parentMap Parent leaflet map to sync with.
   * @param {array} center Lat/Lon coordinates representing the center of the parent map viewport.
   * @param {number} zoom Zoom level of the parent map.
   * @param {object} options Object containing options related to the parent view.
   * @param  {...leaflet.map} otherMaps Other leaflet maps to sync with the parent map.
   */
  const setSynchronizedMapView = (parentMap, center, zoom, ...otherMaps) => {
    // Setting current map to parent so that other maps' moveend event listeners don't invoke this function
    currentMapRef.current = parentMap;
    for (let map of otherMaps) {
      if (map) {
        map.setView(center, zoom, { animate: false, duration: 0 });
      }
    }
    currentMapRef.current = null;
  }

  /**
   * Helper function to simplify rounding floating point numbers to a specified number
   * of decimal places.
   * 
   * @param {number} num Floating point number to round to nearest n, where n corresponds to the number of decimal places.
   * @param {number} decimalPlaces Total amount of decimal places to round to (e.g., 1 = tenth, 2 = hundredth, etc.)
   * @returns Rounded version of the provided number based on specified decimal places. 
   *          Returns original number if decimal places are invalid.
   */
  const round = (num, decimalPlaces = 2) => {
    let rounded = num;

    // Only parse and round if the number provided is actually a number
    if (!isNaN(parseFloat(num))) {
      if (decimalPlaces >= 0) {
        let precision = Math.pow(10, Math.floor(decimalPlaces));
        if (precision <= Number.MAX_SAFE_INTEGER) {
          rounded = Math.round(num * precision) / precision;
        }
      }
    }

    return rounded;
  }

  /**
   * Helper function to simplify rounding floating point numbers to a specified number
   * of decimal places. Additional zeros are padded to the resulting string version of
   * the rounded number to match the number of specified decimal places when rounding.
   * 
   * @param {number} num Floating point number to round to nearest n, where n corresponds to the number of decimal places.
   * @param {number} decimalPlaces Total amount of decimal places to round to (e.g., 1 = tenth, 2 = hundredth, etc.)
   * @returns Rounded version of the provided number based on specified decimal places. Adds trailing zeros to
   *          match number of decimal places as needed. Returns original number if decimal places are invalid.
   */
  const roundStr = (num, decimalPlaces = 2) => {
    let roundedStr = num;

    // Only parse and round if the number provided is actually a number
    if (!isNaN(parseFloat(num))) {
      let rounded = round(num, decimalPlaces);
      let zeros = Array(decimalPlaces + 1).join("0");

      // True only if the the number has a decimal point
      let floatingPoint = Math.floor(rounded) !== rounded;
      roundedStr = String(rounded);

      // Handle case when number is scientific notation
      if (roundedStr.includes("e") && rounded < 1) {
        roundedStr = `0.${zeros}`;
      }
      else if (floatingPoint) {
        let tokens = roundedStr.split(".");
        roundedStr = `${tokens[0]}.${(tokens[1] + zeros).substring(0, decimalPlaces)}`;
      }
      else if (decimalPlaces > 0) {
        roundedStr = `${rounded}.${zeros}`;
      }
    }

    // Parse and pad with zeros to match desired decimal places (e.g., 2.1 -> 2.10)
    return roundedStr;
  }

   /**
   * Helper function to produce an average from an array of numbers
   * 
   * @param {number} num Floating point number to round to nearest n, where n corresponds to the number of decimal places.
   * @param {number} decimalPlaces Total amount of decimal places to round to (e.g., 1 = tenth, 2 = hundredth, etc.)
   * @returns Rounded version of the provided number based on specified decimal places. Adds trailing zeros to
   *          match number of decimal places as needed. Returns original number if decimal places are invalid.
   */
   const averageNumericArray = (numArray) => {
    
    if( numArray.length > 0){
    let numAverage = numArray.reduce((a, b) => a + b) / numArray.length
    
    return numAverage;
    }
    else {
      return 0
    }
  }

  /**
   * Helper function to produce an average from an array of numbers
   * 
   * @param {number} num Floating point number to round to nearest n, where n corresponds to the number of decimal places.
   * @param {number} decimalPlaces Total amount of decimal places to round to (e.g., 1 = tenth, 2 = hundredth, etc.)
   * @returns Rounded version of the provided number based on specified decimal places. Adds trailing zeros to
   *          match number of decimal places as needed. Returns original number if decimal places are invalid.
   */
  const largestNumericArray = (numArray) => {
    
    if( numArray.length > 0){
    let numLargest = numArray.reduce((a, b) => a > b ? a : b) 
    return numLargest;
    }
    else {
      return 0
    }
  }
  /**
   * Convert a number to big number notation.
   * 
   * EX: 
   * 1234 = 1.23k
   * 12345678 = 12.35m
   * 
   * @param {number} num Number to be formatted in big number notation (e.g., 1234 = 1.2k)
   * @param {number} decimalPlaces The number of decimal places to round the provided number to after converting to big number notation (default 2).
   * @returns Big number formatted version of the provided number. 
   */
  const bigNumberFormatter = (num, decimalPlaces = 2) => {
    if (num >= 1000000000000) {
      return (num / 1000000000000).toFixed(decimalPlaces).replace(/\.0$/, '') + 'T';
    }

    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(decimalPlaces).replace(/\.0$/, '') + 'B';
    }

    if (num >= 1000000) {
      return (num / 1000000).toFixed(decimalPlaces).replace(/\.0$/, '') + 'M';
    }

    if (num >= 1000) {
      return (num / 1000).toFixed(decimalPlaces).replace(/\.0$/, '') + 'K';
    }

    return roundStr(num, decimalPlaces);
  }

  /**
   * Determine whether a given date string is in the correct ISO format (UTC).
   * 
   * See: https://stackoverflow.com/a/52869830
   * 
   * @param {string} dateStr Date string used to determine if in ISO format.
   * @returns Whether or not the provided date string is in ISO format.
   */
  const isIsoDate = (dateStr) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateStr)) return false;
    var d = new Date(dateStr);
    return d.toISOString() === dateStr;
  }

  /**
   * Parse a Date object to a human readable string format.
   * 
   * @param {Date} date Date object to parse to human readable format.
   * @param {boolean} showTimeOnly Whether to show only the time portion of the formatted date string (takes precedence over show date only flag).
   * @param {boolean} showDateOnly Whether to show only the date portion of the formatted date string.
   * @returns Human readable string representation of the provided Date object.
   */
  const toHumanReadableDateStr = (date, showTimeOnly = false, showDateOnly = false) => {
    let newDate = null;
    let config = { ...DATE_TIME_CONFIG };
    if (typeof(date) === "string") {
      if (!isIsoDate(date)) {
        delete config.timeZone;
      }

      newDate = new Date(date);
    }
    else {
      newDate = date;
    }

    // Support only showing the time by removing the date style configuration
    if (showTimeOnly) {
      delete config.dateStyle;
    }
    else if (showDateOnly) {
      delete config.timeStyle;
    }

    let formatted = newDate.toLocaleString(
      'en-US',
      config
    );

    return formatted;
  }

  /**
   * Truncate and add an ellipsis to a provided string when its length exceededs a specified maxiumum number of
   * characters.
   * 
   * @param {string} str String to truncate and add ellipsis if string length exceeds the max character threshold.
   * @param {number} maxChars Maximum number of characters the provided string must be within before truncating and adding an ellipsis (default 20).
   * @returns String representing the truncated and ellipsified version.
   */
  const ellipsify = (str, maxChars = 20) => {
    let ellipsifiedStr = str;
    if (str && typeof(str) === "string") {
      if (str.length > maxChars) {
        ellipsifiedStr = str.substring(0, maxChars) + "...";
      }
    }

    return ellipsifiedStr;
  }

  /**
   * Flight Rule Calculation:
   * VFR: Ceiling greater than 3,000 AND Visibility greater then 5 nautical miles
   * MVFR: Ceiling 1,000 to 3,000 AGL OR Vis 3-5 nautical miles
   * IFR: Ceiling 500 to 999 AGL OR Vis 1 naut mile to 3 naut miles
   * LIFR: below 500 AGL OR Vis less than 1 naut mile
   */
  const calculateFlightRule = (ceiling, visibility) => {
    // visibility is saved as a statue mile
    // 1 nautical mile = 1.15078 statue miles
    let convertVis = visibility / 1.15078
    let cCheck1 = 3000
    let cCheck2 = 1000
    let cCheck3 = 500
    let vCheck1 = 5
    let vCheck2 = 3
    let vCheck3 = 1

    if(ceiling < cCheck3 || convertVis < vCheck3){
      return "LIFR"
    }
    else if(ceiling < cCheck2 || convertVis < vCheck2){
      return "IFR"
    }
    else if(ceiling < cCheck1 || convertVis < vCheck1){
      return "MVFR"
    }
    else {
      return "VFR"
    }


  }
  /**
   * Store a reference to a function (e.g., passed via props) for navigating back to the
   * MyFlights fligth list page/view.
   * 
   * @param {function} fn Pointer to function for navigating back to the MyFlights flight list page/view.
   */
  const setBackToFlightsFn = (fn) => {
    backToFlightsRef.current = fn;
  }

  /**
   * Get function pointer for navigating back to the MyFlights flight list page/view.
   * 
   * @returns Pointer to function for navigating back to the MyFlights flight list page/view.
   */
  const getBackToFlightsFn = () => {
    return backToFlightsRef.current;
  }

  /**
   * Helper function for navigating back to the MyFlights flight list page/view.
   */
  const backToFlights = () => {
    if (backToFlightsRef.current) {
      backToFlightsRef.current();
    }
    else {
      // Programatically refresh page as a fallback
      window.location.reload();
    }
  }

  /**
   * Calculate the angle/heading in degrees between two lat/lng coordinates.
   * 
   * @param {array} coord1 Lat/Lng coordinate pair for first point.
   * @param {array} coord2 Lat/Lng coordinate pair for second point.
   * @returns Angle/Heading in degrees between the two coordinates.
   */
  const _angleFromCoordinate0 = (coord1, coord2) => {
    const [lat1, long1] = coord1;
    const [lat2, long2] = coord2;
    let dLon = (long2 - long1);

    let y = Math.sin(dLon) * Math.cos(lat2);
    let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

    let brng = Math.atan2(y, x);

    brng = brng * (180 / Math.PI); // Convert radians to degrees
    brng = (brng + 360) % 360;
    brng = 360 - brng; // Count degrees counter-clockwise - remove to make clockwise

    return brng;
  }

  const _angleFromCoordinate = (coord1, coord2) => {
    const TWOPI = 6.2831853071795865;
    const RAD2DEG = 57.2957795130823209;

    const [lat1, long1] = coord1;
    const [lat2, long2] = coord2;


    // if (a1 = b1 and a2 = b2) throw an error
    if (long2 == long1 && lat2 == lat1) return 0.0;
    else {
      let theta = Math.atan2(long2 - long1, lat2 - lat1);
      if (theta < 0.0)
        theta += TWOPI;
      return RAD2DEG * theta;
    }
  }

  /**
   * Get the closest cardinal angle in degrees that a specified angle in degrees is closest to.
   * 
   * @param {number} angle Angle in degrees.
   * @param {number} marginOfError Threshold cardinal angle in degrees that the specified angle is closest to.
   * @returns Closest cardinal angle in degrees that the specified angle in degrees is closest to.
   */
  // const _getClosestAngle = (angle, marginOfError = 45) => {
  //   /* 
  //   Core Cardinal Directions in Degrees:
  //     North:        0 
  //     North-East:  45
  //     East:        90 
  //     South-East: 135 
  //     South:      180 
  //     South-West: 225 
  //     West:       270 
  //     North-West: 315
  //   */
  //   // Build cardinal directions in degrees based on margin of error
  //   let cardinals = [];
  //   let degrees = 0;
  //   while (degrees < 360) {
  //     cardinals.push(degrees);
  //     degrees += marginOfError;
  //   }

  //   // console.log("Cardinals:", cardinals);

  //   let smallestDiff = Math.abs(angle - cardinals[0]);
  //   let angleMeta = { diff: smallestDiff, i: 0 };

  //   for (let i = 1; i < cardinals.length; ++i) {
  //     let diff = Math.abs(angle - cardinals[i]);
  //     if (diff < angleMeta.diff) {
  //       angleMeta.diff = diff;
  //       angleMeta.i = i;
  //     }
  //   }

  //   return cardinals[angleMeta.i];
  // }

  /**
   * Add formatted flight metrics fields to a target dataset.
   * 
   * @param {object} dataTarget Target dataset for modifying. Should resemble the base data structure (each key containing an entry for data, lookup, and columns).
   * @param {string} key Key associated with the nested data structure to add formatted fields to.
   * @param {boolean} buildGeoJson Whether to add a GeoJson field to the provided data target as a result of adding formatted fields.
   */
  const addFormattedFlightMetricsFields = (dataTarget, key = "track_points", buildGeoJson = false) => {
    if (dataTarget && dataTarget[key]) {
      const { data, lookup, columns } = dataTarget[key];

      // Only proceed if there's lookup data available (corner case for flights that haven't been reprocessed with exceedance point update)
      if (lookup) {
        let geojson = { // Build geojson for playback capability
          "type": "Feature",
          "properties": {
            "name": "Flight Track",
            "times": [],
          },
          "geometry": {
            "type": "LineString",
            "coordinates": [],
          }
        };

        let processedPoints = 0;
        let heading = 0;
        let cardinals = new Set([0, 90, 180, 270, 360]);

        Array.from(data).forEach((point, idx) => {
          // Calculate heading
          // Use magnetic heading data if possible (use hybrid approach to handle cases when heading data isn't avaiable)
          let newHeading = lookup.heading_final_deg ? point[lookup.heading_final_deg] : point[lookup.flightstate_position_magneticheading];
          if (newHeading !== null) {
            heading = newHeading;
          }
          else {
            // This section OBE to heading_final_deg
            // Need to extrapolate heading based on current position (comparing current point lat/lng to next point)
            newHeading = 0;
            if (idx + 1 < data.length) {
              // Look ahead at next point to determine the heading
              let nextPoint = data[idx + 1];
              newHeading = _angleFromCoordinate(
                [point[lookup.flightstate_location_latitude], point[lookup.flightstate_location_longitude]],
                [nextPoint[lookup.flightstate_location_latitude], nextPoint[lookup.flightstate_location_longitude]],
              );
            }
            else {
              // Use the same calculation from the previous to current point
              if (idx !== 0) {
                let prevPoint = data[idx - 1];
                newHeading = _angleFromCoordinate(
                  [prevPoint[lookup.flightstate_location_latitude], prevPoint[lookup.flightstate_location_longitude]],
                  [point[lookup.flightstate_location_latitude], point[lookup.flightstate_location_longitude]],
                );
              }
            }

            // Smooth heading deltas and fix jerky directional changes
            if (newHeading !== 0 && newHeading !== 360) {
              // Handle corner case for sharp turns in flight and don't allow jumps to cardinal directions
              if (!cardinals.has(Math.round(newHeading))) {
                // console.log("New heading:", newHeading);
                heading = newHeading;
              }
            }
          }

          // Alter data record by reference
          data[idx] = [
            ...point,
            toHumanReadableDateStr(point[lookup.times_timestamp]),
            toHumanReadableDateStr(point[lookup.times_timestamp], true),
            roundStr(point[lookup.flightstate_location_longitude], 6),
            roundStr(point[lookup.flightstate_location_latitude], 6),
            roundStr(point[lookup.final_agl]),
            roundStr(point[lookup.flightcontrols_collectiveposition]),
            roundStr(point[lookup.verticalspeed_final_fpm]),
            roundStr(point[lookup.flightstate_position_pitch]),
            roundStr(point[lookup.flightstate_rates_yawrate]),
            roundStr(point[lookup.flightcontrols_cyclicpositionpitch]),
            roundStr(point[lookup.flightstate_position_yaw]),
            roundStr(point[lookup.flightstate_position_roll]),
            roundStr(point[lookup.groundspeed_final_kt]),
            roundStr(point[lookup.true_airspeed_final_kt]),
            roundStr(point[lookup.flightcontrols_cyclicpositionroll]),
            capitalizeWords(point[lookup.phaseofflight_mavg10]),
            capitalizeWords(point[lookup.vrs]),
            capitalizeWords(point[lookup.exceedance_severity] || "None"),
            capitalizeWords(point[lookup.exceedance_subtype] || "None"),
            heading,
            roundStr(heading),
          ];

          // Add geojson record if desired
          if (buildGeoJson) {
            // Add time and location data to track points geojson object
            geojson.properties.times.push(point[lookup.times_timestamp]);

            geojson.geometry.coordinates.push([
              point[lookup.flightstate_location_longitude],
              point[lookup.flightstate_location_latitude],
              processedPoints,
            ]);
          }

          processedPoints++;
        });

        // Set reference to geojson to persist throughout if desired
        if (buildGeoJson) {
          dataTarget[key].geojson = geojson;
        }

        const addedCols = [
          "human_readable_datetime",
          "human_readable_time",
          "flightstate_location_longitude_str",
          "flightstate_location_latitude_str",
          "final_agl_str",
          "flightcontrols_collectiveposition_str",
          "verticalspeed_final_fpm_str",
          "flightstate_position_pitch_str",
          "flightstate_rates_yawrate_str",
          "flightcontrols_cyclicpositionpitch_str",
          "flightstate_position_yaw_str",
          "flightstate_position_roll_str",
          "groundspeed_final_kt_str",
          "true_airspeed_final_kt_str",
          "flightcontrols_cyclicpositionroll_str",
          "phaseofflight_mavg10_str",
          "vrs_str",
          "exceedance_severity_str",
          "exceedance_subtype_str",
          "heading",
          "heading_str",
        ];

        // Add new values to lookup and columns
        for (let i = 0; i < addedCols.length; i++) {
          let addedCol = addedCols[i];

          if (!lookup.hasOwnProperty(addedCol)) {
            columns.push(addedCol);
            lookup[addedCol] = columns.length - 1;
          }
        }
      }
    }
  }

  /**
   *  Add formatted UAS aggregate metrics fields to a target dataset.
   * 
   * @param {object} dataTarget 
   */
  const addFormattedUasSightingsFields = (dataTarget) => {
    const key = "uas_sightings";
    if (dataTarget && key in dataTarget) {
      const {
        [key]: { data, lookup, columns }
      } = dataTarget;

      if (data && data.length && data.length > 0) {
        let dateOfSighting, dateObj, month, year, parsedDateStr;
        Array.from(data).forEach((point, idx) => {
          // Extract/Parse target date field and create new field to represent month + day
          dateOfSighting = point[lookup.incident_time_zulu];
          dateObj = new Date(dateOfSighting);
          month = dateObj.getMonth() + 1; // NOTE: getMonth returns 0-indexed month so need to add 1
          year = dateObj.getFullYear();
          parsedDateStr = `${month}/${year}`;

          // Add parsed date string to dataset
          data[idx] = [
            ...point,
            parsedDateStr,
          ];
        });

        const addedCols = [
          "month_year",
        ];

        // Add new values to lookup and columns
        for (let i = 0; i < addedCols.length; i++) {
          let addedCol = addedCols[i];

          if (!lookup.hasOwnProperty(addedCol)) {
            columns.push(addedCol);
            lookup[addedCol] = columns.length - 1;
          }
        }
      }
      else {
        console.error(`No uas aggregate data to add formatted fields to for ${key}`);
      }
    }
    else {
      console.error("UAS aggregate data target is null. Can't add formatted fields");
    }
  }

  /**
   * 
   */
  const addFormattedUimcSightingsFields = (dataTarget) => {
    const key = "uimc_events";
    if (dataTarget && key in dataTarget) {
      const { data, lookup, columns } = dataTarget[key];

      // Prepare geojson for mapbox visualization
      let geojson = {
        crs: {
          properties: {
            name: "urn:ogc:def:crs:OGC:1.3:CRS84"
          },
          type: "name"
        },
        features: [],
        name: ["uimc-aggregate-metrics"],
        type: "FeatureCollection"
      };

      const addedCols = [
        "event_date_str",
        "duration_seconds_str",
        "latitude_str",
        "longitude_str",
        "altitude_str",
        "speed_str",
        "flight_rule",
        "time_bucket",
        "year",
        "month",
        "yearMonth"
      ];

      // Add new values to lookup and columns
      for (let i = 0; i < addedCols.length; i++) {
        let addedCol = addedCols[i];

        if (!lookup.hasOwnProperty(addedCol)) {
          columns.push(addedCol);
          lookup[addedCol] = columns.length - 1;
        }
      }
      
      Array.from(data).forEach((point, idx) => {
        let alt = point[lookup["start_altitude"]];
        alt = alt ? alt * 100 : alt; // Convert from hundreds of feet

        // Alter data record by reference
        data[idx] = [
          ...point,
          toHumanReadableDateStr(new Date(point[lookup["start_timestamp"]])),
          roundStr(point[lookup["duration_seconds"]], 2),
          roundStr(point[lookup["start_latitude"]], 6),
          roundStr(point[lookup["start_longitude"]], 6),
          roundStr(alt, 2),
          roundStr(point[lookup["start_speed"]], 2),
          // Convert from MSL to AGL
          calculateFlightRule(point[lookup["start_ceiling_ft"]] - point[lookup["start_sur_ft"]],point[lookup["start_visibility_sm"]]),
          Math.ceil(point[lookup["duration_seconds"]]/10) < 20 ? (Math.ceil(point[lookup["duration_seconds"]]/10)*10).toString() : "200+",
          new Date(point[lookup["start_timestamp"]]).getFullYear(),
          new Date(point[lookup["start_timestamp"]]).getMonth() + 1,
          new Date(point[lookup["start_timestamp"]]).getFullYear() +"/" + (new Date(point[lookup["start_timestamp"]]).getMonth() + 1),

        ];

        
        
        // Build properties
        let properties = {};
        for (let col of columns) {
          properties[col] = data[idx][lookup[col]];
        }

        // Add geojson record
        geojson.features.push({
          "type": "Feature",
          "properties": properties,
          "geometry": {
            "type": "Point",
            "coordinates": [point[lookup["start_longitude"]], point[lookup["start_latitude"]], alt],
          },
        });
      });


      // Set reference to geojson to persist throughout
      dataTarget[key].geojson = geojson;
    }
  }

  /**
   * Convert the weather camera data file into and object, and then merge interesting facts from the UIMC events data
   */
  const addUimcWeatherCameraData = (dataTarget, weatherCameras) => {
    const key = "uimc_events";
    if (dataTarget && key in dataTarget) {
      const { data, lookup, columns } = dataTarget[key];
      // Creat wxCamData structure as an array of object
      let wxCamData = {}
      // Populate list first from master list (weatherCameras) and the match key wx_cam_site
      for(let obj in weatherCameras){
        // console.log(weatherCameras[obj])
        wxCamData[weatherCameras[obj]['sitename']] = {
           
          "Latitude": weatherCameras[obj]['sitelatitude'],
          "Longitude": weatherCameras[obj]['sitelongitude'],
          "State": weatherCameras[obj]['state'],
          "Country": weatherCameras[obj]['country'],
          "DistanceFromEvents": [],
          "Count": 0,
        }
      }

      let wxName = dataTarget.uimc_events.lookup['wx_cam_site']
      let wxDist = dataTarget.uimc_events.lookup['wx_cam_dist_mi']
      
      for(let key in dataTarget.uimc_events.data) {
        // Add Distance from Event to array
        wxCamData[dataTarget.uimc_events.data[key][wxName]]["DistanceFromEvents"].push(
          dataTarget.uimc_events.data[key][wxDist]
        ) 
        wxCamData[dataTarget.uimc_events.data[key][wxName]]["Count"] += 1
      }
      
      // Calc average and longest dist for each entry now that those have been populated
      for(let key in wxCamData){
        wxCamData[key]['AverageDist'] = averageNumericArray(wxCamData[key]['DistanceFromEvents'])
        wxCamData[key]['LargestDist'] = largestNumericArray(wxCamData[key]['DistanceFromEvents'])
      }

      // reformat the whole thing into data, lookup, columns
      let wxCameraData = []
      let wxColList = ["Name"] // First column is always Name
      let colPop = false
      for(let item in wxCamData){
        let dataItem = [item]
        for (let innerItem in wxCamData[item]){
          dataItem.push(wxCamData[item][innerItem])
          if(!colPop){
            wxColList.push(innerItem)
          }
        }
        colPop = true;
        wxCameraData.push(dataItem)
      }
      // console.log(wxCameraData)
      // console.log(wxColList)

      let wxCameraLookup = {}
      for (let element in wxColList){
        // let colVal = wxColList[element]
      wxCameraLookup[wxColList[element]] = element
      }


      //Add geojson formatted data
      let wxGeoJson = {
        "crs":
        {
          "type": "name",
          "properties":
            { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" }
        }, "type": "FeatureCollection",
      }
      let camFeatureList = []
          Object.keys(wxCameraData).map(key => {
            // console.log(key)
          
            
            let camGeo = {'geometry': {
              'coordinates' : [wxCameraData[key][wxCameraLookup['Longitude']],
              wxCameraData[key][wxCameraLookup['Latitude']]],
            'type' : 'Point'},
            'properties': {"Site Name" : wxCameraData[key][wxCameraLookup["Name"]],
            "State" :wxCameraData[key][wxCameraLookup['State']]},
            'type':'Feature'
            }

            camFeatureList.push(camGeo)

            
            
          })
          // console.log(camFeatureList)
          wxGeoJson['features'] = camFeatureList
          wxGeoJson['name'] = ['uimc-wx-locations']
      
          // console.log(wxGeoJson)
      // console.log(wxCameraLookup)
      // console.log(wxCamData)
      dataTarget['wxCameraData'] = {}
      dataTarget['wxCameraData']['data'] = wxCameraData
      dataTarget['wxCameraData']['columns'] = wxColList
      dataTarget['wxCameraData']['lookup'] = wxCameraLookup
      dataTarget['wxCameraData']['geojson'] = wxGeoJson
      

    }
  }

  /**
   * Perform reduction on target nested data object within base/filtered dataset. Invoke
   * callback for processing the data point as a result of passing noise reduction
   * threshold check if the callback function is implemented upon invocation.
   * 
   * @param {object}       dataTarget Target dataset containing the nested data object to reduce.
   * @param {string}              key Key for retrieving the target nested data object to reduce.
   * @param {function} onProcessPoint Optional callback function for processing data points with the following 
   *                                  signature -> ( point, lookup, processedPoints ).
   * @param {function}   comparatorFn Optional callback function for performing an additional validation check 
   *                                  between previous point and current point for culling -> (prevPoint, currentPoint, lookup).
   */
  const _doReduction = (dataTarget, { key = "track_points", onProcessPoint = null, comparatorFn = null } = {}) => {
    let currentPointTime = null;
    let prevPointTime = null;
    let processPoint = true;
    let processedPoints = 0;
    // let totalNoiseReduction = 0;

    if (key in dataTarget) {
      const { data, lookup } = dataTarget[key];
      let prevPoint = null;
      let comparisonCheckStatus = true;

      // Extract only required data key/values for rendering data points and tooltips from track points data
      Array.from(data).forEach((point, idx) => {
        if (idx === 0) {
          // Don't apply noise reduction for first track point (just initialize)
          currentPointTime = new Date(point[lookup.times_timestamp]);
          prevPointTime = currentPointTime;
        }
        else {
          currentPointTime = new Date(point[lookup.times_timestamp]);

          let diff = currentPointTime - prevPointTime;
          processPoint = diff >= TRACK_POINT_NOISE_THRESHOLD || point[lookup.vrs] === true;

          // Only set new previous time once the first point that meets the threshold is found
          if (processPoint) {
            prevPointTime = currentPointTime;
          }
        }

        // Perform additional comparison check between previous point and current point as desired (default true)
        comparisonCheckStatus = comparatorFn !== null ? comparatorFn(prevPoint, point, lookup) : false;

        if (processPoint || comparisonCheckStatus) {
          // Additional processing if desired
          if (onProcessPoint) {
            onProcessPoint(point, lookup, processedPoints);
          }

          processedPoints++;
        }
        // else {
        //   totalNoiseReduction++;
        // }

        // Keep track of previous point for comparison
        prevPoint = point;
      });
    }
  }

  /**
   * Process a data target representing the base data or filtered data and apply noise reduction along with
   * modified/formatted fields suited for scatter chart and map tooltips. Overwrites/Sets the base reduced
   * data or filtered scatter chart data in state depending on whether a base dataset or filtered dataset
   * is being processed respectively.
   * 
   * @param {object} dataTarget Dataset used for building consolidated scatter chart and map data. Intended to be a base dataset or filtered dataset.
   * @param {boolean} trackAsFilteredData Whether to overwrite the base scatter chart and map data or filtered scatter chart abd map data in state.
   */
  const buildReducedDataset = (dataTarget, trackAsFilteredData = false) => {
    if (dataTarget && dataTarget.track_points) {
      let start, end, diff;

      // Build unique dataset
      if (DEBUG_MODE) {
        console.log("Building unique track points data...");
        start = new Date();
      }

      // Extract data and lookup table from track points dataset
      const { columns: trackPointsColumns, data: trackPointsData, lookup: trackPointsLookup } = dataTarget.track_points;

      // Build new raw track points dataset that contains only unique track point records
      // NOTE: 06/29/2022 - Building exceedance and loc list strings to consolidate points with multiple exceedances (for map tooltips, etc.)
      let uniqueIds = {};
      let uniqueTrackPoints = {
        ...dataTarget.track_points,
        columns: [
          ...trackPointsColumns,
          "exceedance_list_str",
          "loc_list_str",
        ],
        lookup: {
          ...trackPointsLookup,
          "exceedance_list_str": -1,
          "loc_list_str": -1,
        },
        data: [],
      };

      // Human readable lookup tables
      let hrExceedances = {
        "EXCEEDANCE": {},
        "LOSS OF CONTROL": {},
        "NONE": {},
      };

      // Extract references for building unique track points dataset
      const { data: uniqueTrackPointsData, lookup: uniqueTrackPointsLookup } = uniqueTrackPoints;

      const { polyLineCoords = [] } = dataTarget;
      let buildPolyLineCoords = polyLineCoords.length === 0;

      Array.from(trackPointsData).forEach(point => {
        let id = point[trackPointsLookup._id];
        let type = point[trackPointsLookup.exceedance_type];
        let exceedance = point[trackPointsLookup.exceedance_subtype];
        let severity = point[trackPointsLookup.exceedance_severity];

        // Normalize type
        type = type === null || type === "" ? "NONE" : type.toUpperCase().trim();

        // Add entry to human readable exceedances lookup if needed
        if (!(exceedance in hrExceedances[type])) {
          hrExceedances[type][exceedance] = type === "NONE" ? "" : capitalizeWords(exceedance);
        }

        // Handle first occurrence of track point id
        if (!(id in uniqueIds)) {
          let newPoint = [...point];
          let newPointLength = newPoint.push(type === "EXCEEDANCE" ? hrExceedances[type][exceedance] : "");

          // Add polyline coord for unique point if needed
          let coords = [
            point[trackPointsLookup.flightstate_location_latitude],
            point[trackPointsLookup.flightstate_location_longitude]
          ];

          if (buildPolyLineCoords) {
            polyLineCoords.push(coords);
          }

          // Update lookup entry for exceedance list string if needed
          if (uniqueTrackPointsLookup.exceedance_list_str === -1) {
            uniqueTrackPointsLookup.exceedance_list_str = newPointLength - 1;
            // console.log(`Set exceedance_list_str idx to ${uniqueTrackPointsLookup.exceedance_list_str}`);
          }

          newPointLength = newPoint.push(type === "LOSS OF CONTROL" ? hrExceedances[type][exceedance] : "");

          // Update lookup entry for loss of control list string if needed
          if (uniqueTrackPointsLookup.loc_list_str === -1) {
            uniqueTrackPointsLookup.loc_list_str = newPointLength - 1;
            // console.log(`Set loc_list_str idx to ${uniqueTrackPointsLookup.loc_list_str}`);
          }

          let uniqueIdx = uniqueTrackPointsData.push(newPoint); // Start exceedance group list

          uniqueIds[id] = {
            idx: uniqueIdx - 1, // Always first point with unique id
            exceedance_set: new Set([exceedance]),
            severity_priority: getExceedanceSeverityPriority(severity),
          }
        }
        else {
          const { idx, exceedance_set } = uniqueIds[id];
          let p1 = uniqueTrackPointsData[idx]; // Obtain reference to first point
          // console.log(`Looking at point (at idx = ${idx}):`, p1);

          // Alter the exceedance severity based on priority as needed
          let newPriority = getExceedanceSeverityPriority(severity);
          if (newPriority > uniqueIds[id].severity_priority) {
            // console.log(`Changing exceedance severity from ${p1[trackPointsLookup.exceedance_severity]} to ${severity}...`);
            p1[trackPointsLookup.exceedance_severity] = severity;
            uniqueIds[id].severity_priority = newPriority;
          }

          // Append exceedance value as needed
          if (!exceedance_set.has(exceedance)) {
            let listStrIdx = uniqueTrackPointsLookup.exceedance_list_str;
            if (type === "LOSS OF CONTROL") {
              listStrIdx = uniqueTrackPointsLookup.loc_list_str;
            }

            // Initialize if currently empty
            if (p1[listStrIdx] === "") {
              p1[listStrIdx] = hrExceedances[type][exceedance];
            }
            else {
              p1[listStrIdx] = `${p1[listStrIdx]}, ${hrExceedances[type][exceedance]}`;
            }

            exceedance_set.add(exceedance);
          }
        }
      });

      // console.log("Human Readable Exceedances Lookup:", hrExceedances);
      // console.log("Unique track points:", uniqueTrackPoints);
      dataTarget.unique_track_points = uniqueTrackPoints;

      // Add reference to polyline coords if needed (only should happen the first time this function is called)
      if (buildPolyLineCoords) {
        // console.log("Adding polyline coordinates field to data target...")
        dataTarget.polyLineCoords = polyLineCoords;
      }

      if (DEBUG_MODE) {
        end = new Date();
        diff = end - start;
        console.log(`Finished building unique track points data in ${diff}ms`);

        console.log("Building base reduced data...");
        start = new Date();
      }

      // Build reduced data template
      const reducedData = {
        ...dataTarget,
        exceedance_point: {
          ...dataTarget.exceedance_point,
          data: [],
        },
        track_points: {
          ...dataTarget.unique_track_points,
          data: [], // Build main datapoints from scratch
          geojson: { // Build geojson for playback capability
            "type": "Feature",
            "properties": {
              "name": "Flight Track",
              "times": [],
            },
            "geometry": {
              "type": "LineString",
              "coordinates": [],
            }
          }
        },
      };

      // Remove unique track points entry from reduced data since it's being written as track_points
      delete reducedData.unique_track_points;

      // Retrieve references to configuration objects to perform reduction
      const { data: reducedTrackPoints, geojson } = reducedData.track_points;
      const { data: reducedExceedancePoints } = reducedData.exceedance_point;

      // Do reduction based on unique track point dataset (not directly modifying the unique_track_points)
      _doReduction(dataTarget, {
        key: "unique_track_points",
        onProcessPoint: (point, lookup, processedPoints) => {
          // Ensure the exceedance and loc list strings are populated
          if (point[lookup.exceedance_list_str] === "") {
            point[lookup.exceedance_list_str] = "None";
          }

          if (point[lookup.loc_list_str] === "") {
            point[lookup.loc_list_str] = "None";
          }

          reducedTrackPoints.push(point);

          // Add time and location data to track points geojson object
          geojson.properties.times.push(point[lookup.times_timestamp]);

          geojson.geometry.coordinates.push([
            point[lookup.flightstate_location_longitude],
            point[lookup.flightstate_location_latitude],
            processedPoints,
          ]);
        }
      });

      // Do reduction on exceedance points data
      // NOTE: Entire exceedance types are getting filtered out because timestamps are the same between the points.
      //       Thus, an additional check on exceedance type variance is required.
      let uniqueExceedanceTypes = new Set();
      _doReduction(dataTarget, {
        key: "exceedance_point",
        onProcessPoint: point => {
          reducedExceedancePoints.push(point);
        },
        comparatorFn: (prevPoint, currentPoint, lookup) => {
          // Only cull points when the time threshold is satisfied AND the exceedance subtypes have occurred at leat once.
          // NOTE: Always keep the first point (e.g., when previous point is null).
          let keep = true;
          let currentType = currentPoint[lookup.exceedance_subtype];
          if (prevPoint) {
            keep = !uniqueExceedanceTypes.has(currentType);
          }

          // Ensure that at least one point from each exceedance type is included in final reduced set
          uniqueExceedanceTypes.add(currentType);
          return keep;
        }
      });

      addFormattedFlightMetricsFields(reducedData, "track_points", false);
      addFormattedFlightMetricsFields(reducedData, "exceedance_point", true);

      // Track as filtered or base scatter chart data
      if (trackAsFilteredData) {
        setFilteredReducedData(reducedData);
      }
      else {
        setBaseReducedData(reducedData);

        // Initialize filtered scatter chart data if it exists
        if (filteredReducedData) {
          setFilteredReducedData(null);
        }
      }

      if (DEBUG_MODE) {
        end = new Date();
        diff = end - start;
        console.log(`Finished building reduced data in ${diff}ms`);
        console.log("Base reduced data:", reducedData);
      }
    }
  }

  /**
   * Calculate the distance between two points with lat, long values.
   * 
   * @param {number} Latitude of the first point.
   * @param {number} Longitude of the first point
   * @param {number} Latitude of the second point.
   * @param {number} Longitude of the second point.
   * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
   */
  const calEarthDistance = (lat1, lon1, lat2, lon2) => {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p) / 2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p)) / 2;

    if (a <= 0.0) a = 0.0
    return 12742000 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km, 6371000 m
  }

  /**
   * Lighten, darken, or blend colors together with hex or rgb value strings.
   * 
   * See: https://stackoverflow.com/a/13542669
   * Usage: _pSBC( -0.2, '#863CFF' ); // 20% Darker -> '#7836E4'
   * 
   * @param {number} p Percentage (decimal) to lighten or darken colors where darker shades applied with n < 0 and lighter shades are applied with n > 0.
   * @param {string} c0 First color as a hex or rgb value string.
   * @param {string} c1 Second color as a hex or rgb value string (optional).
   * @param {boolean} l Whether to apply darker shade.
   * @returns Resulting hex string for applying color modification.
   */
  // const _pSBC = (p, c0, c1, l) => {
  //   let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) == "string";
  //   if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
  //   if (!this.pSBCr) this.pSBCr = (d) => {
  //     let n = d.length, x = {};
  //     if (n > 9) {
  //       [r, g, b, a] = d = d.split(","), n = d.length;
  //       if (n < 3 || n > 4) return null;
  //       x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
  //     } else {
  //       if (n == 8 || n == 6 || n < 4) return null;
  //       if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
  //       d = i(d.slice(1), 16);
  //       if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
  //       else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
  //     } return x
  //   };
  //   h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = this.pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? this.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
  //   if (!f || !t) return null;
  //   if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
  //   else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  //   a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
  //   if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
  //   else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
  // }

  /**
   * Dynamically build color maps for phase of flight, exceedance types, etc. based on
   * available data and maintain the color maps in the COLOR_MAPS config.
   * 
   * @param {object} dataset Dataset containing targets of interest for building color maps dynamically.
   */
  const buildDynamicColorMaps = (dataset) => {
    if (dataset === null) return;

    // Extract relevant datasets if possible
    const {
      track_points: { data: trackPointData = null, lookup: trackPointsLookup = null } = {},
      exceedance_point: { data: exceedanceData = null, lookup: exceedanceLookup = null } = {},
      uimc_events: { data: uimcEventsData = null, lookup: uimcEventsLookup = null } = {},
    } = dataset;

    let pofs = trackPointData ? groupBy(trackPointData, trackPointsLookup.phaseofflight_mavg10) : [];
    let exceedanceTypes = exceedanceData ? groupBy(exceedanceData, exceedanceLookup.exceedance_subtype) : [];
    let uimcDaylightTypes = uimcEventsData ? groupBy(uimcEventsData, uimcEventsLookup.daylight) : [];
 
    const _buildColorMap = (group, palette, startingColors = null) => {
      let cMap = {};
      let factor = 0;
      let color, tcolor;

      // Add starting colors if desired
      if (startingColors !== null) {
        cMap = { ...startingColors };
      }

      if (group && group.length > 0) {
        for (let i = 0; i < group.length; i++) {
          let value = group[i];
          if (value !== null && typeof(value) === "string") {
            value = value.toUpperCase();
          }

          // Increment factor by color pallete darken factor each time the end of the target color palette has been met
          if (i !== 0 && i % palette.length === 0) {
            factor += COLOR_PALETTE_SHADE_FACTOR;
          }

          color = palette[i % palette.length];

          // Darken color if we're on a new cycle
          if (factor > 0) {
            tcolor = tinycolor(color);

            // Darken or lighten as desired
            if (DARKEN_COLORS) {
              tcolor.darken(factor);
            }
            else {
              tcolor.lighten(factor);
            }

            color = tcolor.toString();
          }

          // Add color to the color map if it hasn't already been added
          if (!(value in cMap)) {
            cMap[value] = color;
          }
        }
      }

      return cMap;
    }

    // Set color maps
    COLOR_MAPS.current.phaseOfFlightColorMap = _buildColorMap(pofs, PHASE_OF_FLIGHT_COLOR_PALETTE);
    COLOR_MAPS.current.exceedanceTypeColorMap = _buildColorMap(exceedanceTypes, EXCEEDANCE_TYPE_COLOR_PALETTE, { "NONE": "#9FDEF1" });
    COLOR_MAPS.current.uimcDaylightTypeColorMap = _buildColorMap(uimcDaylightTypes, UIMC_DAYLIGHT_TYPE_COLOR_PALETTE);


    
    if (DEBUG_MODE) {
      console.log("Dynamically created color maps:", COLOR_MAPS.current);
    }
  }

  // State
  const [appScale, setAppScale] = useState(BASE_APP_SCALE);
  const [activeFlight, setActiveFlight] = useState(null); // flightIds[0]
  const [baseData, setBaseData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [baseReducedData, setBaseReducedData] = useState(null);
  const [filteredReducedData, setFilteredReducedData] = useState(null);
  // const [selectedFilters, setSelectedFilters] = useState({});
  const [groupedData, setGroupedData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [darkMode, setDarkMode] = useState(getFromLS(DARK_MODE_KEY) || false);
  const [controlsExpanded, setControlsExpanded] = useState(false);

  const [snackVertical, setSnackVertical] = useState("top");
  const [snackHorizontal, setSnackHorizontal] = useState("center");
  const [snackMessage, setSnackMessage] = useState("DEFAULT SNACK MESSAGE");
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackDuration, setSnackDuration] = useState(DEFAULT_SNACK_DURATION); // Milliseconds
  const [snackColor, setSnackColor] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [approachUnits, setApproachUnits] = useState('agl');

  // NOTES ON FILTER MANAGEMENT LOGIC:
  //   Undo moves filter from main list of filters to redo-list of filters (invokes reapply filters logic)
  //   Redo moves filter from redo-list of filters back to main list of filters (invokes reapply filters logic)
  const [filterList, setFilterList] = useState([]);
  
  const [redoFilterList, setRedoFilterList] = useState([]);
  const [defaultFilterList, setDefaultFilterList] = useState([]);

  const [allFiltersReset, setAllFiltersReset] = useState(false);

  const [helipadInfo, setHelipadInfo] = useState([-95.71, 37.09, '', '', '', '']);
  
  let currentDate = new Date();
  let monthInterval = new Date();
  monthInterval.setMonth(currentDate.getMonth() - 72);
  // const [approachSelectInfo, setApproachSelectInfo] = useState({"helipad": [-74.593125, 39.314567, '87NJ', 'Shore Medical Center'], 
  //                                                               "startDate": monthInterval.toISOString(), 
  //                                                               "endDate": currentDate.toISOString(),
  //                                                               "approachType": "All",
  //                                                               "approachSubtype": []})
  const [approachSelectInfo, setApproachSelectInfo] = useState({"helipad": [-95.71, 37.09, 'AAAAA', '', '',''], 
                                                                "startDate": monthInterval.toISOString(), 
                                                                "endDate": currentDate.toISOString(),
                                                                "approachType": "All",
                                                                "approachSubtype": []})

  const [approachData, setApproachData] = useState(null);
  
  const [searchRadius, setSearchRadius] = useState(0);
  // const [searchObj, setSearchObj] = useState({});
  const [searchStatus, setSearchStatus] = useState(false);
  const [circleGeoCoords, setCircleGeoCoords] = useState([]);
  const [searchedFlights, setSearchedFlights] = useState([]);
  const searchedFlightsMeta = useRef();
  const [foundFlightCount, setFoundFlightCount] = useState(0);
  const [showNoOperatorFlights, setShowNoOperatorFlights] = useState(false);
  const [drawMode, setDrawMode] = useState(DRAW_MODE.INPUT);
  const [hoverFlightId, setHoverFlightId] = useState('');
  const [connectFlightId, setConnectFlightId] = useState('');
  const [dqFlightId, setDqFlightId] = useState('')
  const [renderFlightIds, setRenderFlightIds] = useState([]);
  const [renderFlightStyles, setRenderFlightStyles] = useState({});
  const [restrictedGeoJsonUrlByFId, setRestrictedGeoJsonUrlByFId] = useState({});
  const [backToGeoSearchMode, setBackToGeoSearchMode] = useState(false);
  const [mapLoadSearch, setMapLoadSearch] = useState(false)
  const [approachQueryStatus, setApproachQueryStatus] = useState('')
  const [approachQueryLoading, setApproachQueryLoading] = useState(false)
  const [helipadsOperator, setHelipadsOperator] = useState([])

  const SelectMenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 450,
        marginTop: 10,
        color: darkMode ? "#fff" : "#424242",
        background: darkMode ? "#000" : "#fff",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };

  /**
   * useEffect listens for changes in the defaultFilter and fitlerLists, and condenses them into a single fitler list for use in fitlering the dataset.
   */
  useEffect(() => {
    // create copy of default, append filters, use reducer to squash the earlier (default) entries
    let consolidatedFilter = [...defaultFilterList, ...filterList]

    
    let newFiltersLookup = consolidatedFilter.reduce((prev, current) => {
      return { ...prev, [current.id]: { ...current } }
    }, {});
    
    handleControlFilter(newFiltersLookup)

  }, [filterList, defaultFilterList])

  useEffect(() => {
    if (isMounted.current) {
      // Update state-based grouped data each time grouped data reference is updated to prevent race condition issues with state
      if (groupedDataRef.current) {
        setGroupedData({ ...groupedDataRef.current });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedDataRef.current]);

  useEffect(() =>{
    // console.log("Base Data changed!", baseData)
  }, [baseData])

  // Mount/Unmount used for cleanup
  useEffect(() => {
    isMounted.current = true;

    return () => {
      filterWidgetNotifierRef.current = null;
      groupedDataRef.current = null;
      selectedFiltersRef.current = null;
      backToFlightsRef.current = null;

      // NOTE: clearing individual map references shouldn't be done here due to useEffect race condition in actual map components during cleanup
      currentMapRef.current = null;

      setActiveFlight(null);
      console.log("Base Data init")
      setBaseData(null);
      setFilteredData(null);
      setBaseReducedData(null);
      setFilteredReducedData(null);
      setGroupedData(null);
      setLoadingData(false);
      setProcessing(false);
      setDarkMode(false);
      setControlsExpanded(false);
      setFilterList(null);
      setRedoFilterList(null);
      setDefaultFilterList(null);
      setAllFiltersReset(false);
      setSnackColor({});
    }
  }, []);

  return (
    <DataContext.Provider
      value={{
        classes,
        filterWidgetNotifierRef,
        currentMapRef,
        groundSpeedMapRef,
        aglMapRef,
        exceedanceTypeMapRef,
        exceedanceSeverityMapRef,
        locTypeMapRef,
        locSeverityMapRef,
        controlsExpandedRef,
        SelectMenuProps,
        DEBUG_MODE,
        BASE_APP_SCALE,
        DARK_MODE_KEY,
        VRS_FILTERS_KEY,
        FLIGHT_PROFILE_FILTERS_KEY,
        EXCEEDANCE_FILTERS_KEY,
        LOC_DASHBOARD_TAB_KEY,
        LTE_DASHBOARD_TAB_KEY,
        FLIGHT_PROFILE_TAB_KEY,
        EXCEEDANCE_TAB_KEY,
        PHASE_OF_FLIGHT_TAB_KEY,
        PLAYBACK_TAB_KEY,
        PLAYBACK3D_TAB_KEY,
        UAS_RISK_ANALYSIS_TAB_KEY,
        SUAS_SIGHTINGS_TAB_KEY,
        UIMC_EVENTS_TAB_KEY,
        EXCEEDANCE_TYPE_SELECT_KEY,
        EXCEEDANCE_SEVERITY_SELECT_KEY,
        LOC_TYPE_SELECT_KEY,
        LOC_SEVERITY_SELECT_KEY,
        OBSTACLE_TYPE_SELECT,
        OBSTACLE_THREAT_SELECT,
        LTE_VALUE_SELECT_KEY,
        LTE_DRIFT_ANGLE_SELECT_KEY,
        VRS_SELECT_KEY,
        UAS_RISK_ANALYSIS_SELECT_KEY,
        SUAS_SIGHTINGS_TOP_AIRPORT_SELECT_KEY,
        SUAS_SIGHTINGS_TOP_ICAO_SELECT_KEY,
        UAS_RISK_ANALYSIS_CONTENT_SELECT_KEY,
        UIMC_EVENTS_CONTENT_SELECT_KEY,
        UIMC_EVENTS_ACFT_TYPE_SELECT_KEY,
        UIMC_EVENTS_YEAR_MONTH_SELECT_KEY,
        UIMC_EVENTS_FACILITY_SELECT_KEY,
        UIMC_EVENTS_TIME_SELECT_KEY,
        UIMC_EVENTS_ICAO_CAT_SELECT_KEY,
        UIMC_EVENTS_ICAO_SUB_CAT_SELECT_KEY,
        UIMC_EVENTS_DAYLIGHT_SELECT_KEY,
        UIMC_EVENTS_STATE_SELECT_KEY,
        UIMC_EVENTS_REGION_SELECT_KEY,
        UIMC_EVENTS_SEASON_SELECT_KEY,
        UIMC_EVENTS_AIRSPACE_CLASS_SELECT_KEY,
        UIMC_EVENTS_AIRSPACE_NAME_SELECT_KEY,
        UIMC_EVENTS_SUBCATEGORY_SELECT_KEY,
        UIMC_EVENTS_FLIGHT_RULE_SELECT_KEY,
        UIMC_EVENTS_CONFIDENCE_RANGE_SELECT_KEY,
        SUAS_SIGHTINGS_CONTENT_SELECT_KEY,
        SUAS_SIGHTINGS_STATE_SELECT_KEY,
        SUAS_SIGHTINGS_CITY_SELECT_KEY,
        OBSTACLE_PROXIMITY_TAB_KEY,
        APPROACH_FLIGTHS_TAB_KEY,
        COLOR_MAPS,
        PHASE_OF_FLIGHT_SELECT_KEY,
        MAP_MARKER_RADIUS,
        MAP_MARKER_WEIGHT,
        MAP_MAX_ZOOM,
        TRACK_POINT_NOISE_THRESHOLD,
        SCATTER_PLOT_NOISE_THRESHOLD,
        MAX_BARCHART_DATAPOINT_WIDTH,
        FLIGHT_START_POINT_COLOR,
        FLIGHT_END_POINT_COLOR,
        END_POINT_RADIUS_ADD,
        END_POINT_WEIGHT_ADD,
        START_END_SYMBOL_FLAG,
        DATE_TIME_CONFIG,
        OBSTACLE_ICON_SIZE,
        obstacleTypeSymbols,
        flightMetricsDashboardConfig,
        uasAggregateMetricsDashboardConfig,
        uimcAggregateMetricsDashboardConfig,
        geoSearchByHelipadsPageConfig,
        approachFlightsMetricsDashboardConfig,
        flightIds,
        SNACK_SEVERITY,
        SNACK_COLORS,
        CHART_FONTS,
        FILTER_TIMEOUT_MILLIS,
        DRAW_MODE,
        styles,
        baseMaps,
        defaultBasemapStyleJson,
        getGroundSpeedColorMap,
        getAglColorMap,
        getExceedanceSeverityPriority,
        getVrsColor,
        getFromLS,
        saveToLS,
        clearLS,
        generateText,
        preventUIBlocking,
        groupByCount,
        groupByAgg,
        groupBy,
        orderBy,
        padTo2Digits,
        convertSecondsToHMS,
        getMinMaxValues,
        getMagnitude,
        handleControlFilter,
        addControlFilter,
        addDefaultFilter,
        resetAllFilters,
        toRadians,
        toDegrees,
        toCamelCase,
        capitalizeWords,
        getPossibleValuesByColumn,
        addPossibleValuesToIndex,
        updatePossibleValuesInIndex,
        getSelectedFiltersById,
        undoLastAppliedFilter,
        redoLastUndoneFilter,
        undoSpecificFilter,
        setSelectedFilters,
        showSnack,
        setSynchronizedMapView,
        arraysEqual,
        round,
        roundStr,
        averageNumericArray,
        largestNumericArray,
        toHumanReadableDateStr,
        ellipsify,
        bigNumberFormatter,
        getMin,
        getMax,
        setBackToFlightsFn,
        getBackToFlightsFn,
        backToFlights,
        buildReducedDataset,
        addFormattedFlightMetricsFields,
        addFormattedUasSightingsFields,
        addFormattedUimcSightingsFields,
        addUimcWeatherCameraData,
        calEarthDistance,
        buildDynamicColorMaps,
        snackVertical,
        snackHorizontal,
        snackMessage,
        snackSeverity,
        snackDuration,
        snackColor,
        appScale, setAppScale,
        openSnack, setOpenSnack,
        approachUnits, setApproachUnits,
        groupedData, setGroupedData,
        activeFlight, setActiveFlight,
        baseData, setBaseData,
        filteredData, setFilteredData,
        baseReducedData,
        filteredReducedData,
        loadingData, setLoadingData,
        processing, setProcessing,
        darkMode, setDarkMode,
        controlsExpanded, setControlsExpanded,
        filterList, setFilterList,
        redoFilterList, setRedoFilterList,
        defaultFilterList, setDefaultFilterList,
        allFiltersReset, setAllFiltersReset,
        helipadInfo, setHelipadInfo,
        approachSelectInfo, setApproachSelectInfo,
        approachData, setApproachData,
        searchRadius, setSearchRadius,
        searchStatus, setSearchStatus,
        circleGeoCoords, setCircleGeoCoords,
        searchedFlights, setSearchedFlights,
        searchedFlightsMeta,
        foundFlightCount, setFoundFlightCount,
        showNoOperatorFlights, setShowNoOperatorFlights,
        drawMode, setDrawMode,
        hoverFlightId, setHoverFlightId,
        connectFlightId, setConnectFlightId,
        dqFlightId, setDqFlightId,
        renderFlightIds, setRenderFlightIds,
        restrictedGeoJsonUrlByFId, setRestrictedGeoJsonUrlByFId,
        backToGeoSearchMode, setBackToGeoSearchMode,
        renderFlightStyles, setRenderFlightStyles,
        mapLoadSearch, setMapLoadSearch,
        approachQueryStatus, setApproachQueryStatus,
        approachQueryLoading, setApproachQueryLoading,
        helipadsOperator, setHelipadsOperator
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
