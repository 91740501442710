import React, { useState, useEffect, useRef, useContext } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import { CircleMode, DirectMode, DragCircleMode, SimpleSelectMode, } from "mapbox-gl-draw-circle";
import circle from '@turf/circle';
import { DataContext } from "../DataContext";
import UserContext from '../UserContext';
import getDocument from "../../services/getDocument";

import { useResizeDetector } from 'react-resize-detector';
import BasemapSwitchControl from "../controls/BasemapSwitchControl";
import MapboxGLButtonControl from '../controls/MapboxGLButtonControl';
import getMyFlights from '../../services/getMyFlights';
import PropTypes from 'prop-types';
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import Button from "@mui/material/Button";

// Import Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'font-awesome/css/font-awesome.min.css';
import '../styles/css/fontello.css';
import './Map.css';
import './GeoSearchByHelipadsMap.css';
import GeoSearchHelipadsRadiusBar from '../utils/GeoSearchHelipadsRadiusBar'
import SearchMyFlights from "../../pages/SearchMyFlights";
import helipadPng from '../styles/helipad.png';
import geoPointPng from '../styles/geoPoint.png';
import { makeStyles } from '@mui/styles';

import PageLoadSpinner from '../PageLoadSpinner'


mapboxgl.accessToken = 'pk.eyJ1IjoiemhqbWFwYm94IiwiYSI6ImNpdDY2NjZtczAzYXgyc2xqbGp0MTI0cmUifQ.ZwiVYpuYfxANYXqBBYTzgQ';

const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
};

const defaultProps = {
  startLoc: [-98.5795, 39.8283],
  zoom: 4,
};


const mapResourceNameRunways = 'Runways.geojson';
// const mapLayerNameRunways = 'Runways';
const mapLayerIdRunways = 'Runways-layer';

const mapResourceNameHelipads = 'helipads.geojson';
const mapLayerIdHelipads = 'helipads-layer';

const mapResourceNameCircle = "resource-circle";
const mapLayerIdCircle = "map-layer-circle";
const mapLayerIdCircleOutline = "map-layer-circle-outline";

const mapResourceNameHelipadPoint = 'resource-helipad-point';
const mapLayerIdHelipadPoint = 'map-layer-helipad-point';

const mapImageIdHelipadPoint = 'map-image-helipad-point';


const uasSightingsColor = "#666";



const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem"
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    padding: "0.5rem",
    flexGrow: 1
  }
});

const buildFeaturedTooltip = (row, { color = null }) => {
  // Build tool tip off all properties
  var tooltipText = "";

  // Start table
  tooltipText += "<table>";
  for (const [key, value] of Object.entries(row)) {
    let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
    tooltipText += `
      <tr>
        ${color !== null ? iconData : ""}
        <td style="color: #989898"><b>${key}&nbsp</b></td>
        <td><b>${value}</b></td>
      </tr>
    `;
  }

  // End table
  tooltipText += "</table>";
  return tooltipText;
}


//Build the legend based on the threat levels
const buildLegend = () => {
  let header = "Threat Levels";
  let headerOverlays = "Overlays";
  let categorySightings = "sUAS Sightings";

  // let position = "topright";
  let categories = [{ "label": "Low", "color": "#00ff00" }, { "label": "Medium", "color": "#ffff00" }, { "label": "High", "color": "#ff0000" }];

  //Create the legend container and return
  let legend = <div className="map-overlay " id="legend" style={{ height: 190 }}>
    {/* Title */}
    <strong style={{ color: "black" }}>{header}</strong>
    <br />

    {/* Items/Icons */}
    {categories.map((cate, i) => {
      let category = cate.label;
      const iconColor = { color: cate.color };

      return (
        <div key={`legend-icon-${i}`}>
          <i
            className="fa fa-circle vector-legend-icon-fonts"
            style={iconColor}
          >
            <span className="legend-icon-inner-text">{category}</span>
          </i>
        </div>
      );
    })}
    <br />
    <strong style={{ color: "black" }}>{headerOverlays}</strong>
    <br />
    <div>
      <i
        key={`legend-icon-sightings`}
        className="fa fa-circle vector-legend-icon-fonts"
        style={{ color: uasSightingsColor }}
      >
        <span className="legend-icon-inner-text">{categorySightings}</span>
      </i>
    </div>
  </div>

  return legend;
}

const GeoSearchByHelipadsMap = ({
  mapId,
  startLoc,
  zoom,
}) => {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const userContext = useContext(UserContext);

  const classes = useStyles();

  const dateTimeHandler = (data) => {
    // return dataContext.toHumanReadableDateStr(new Date(data));
    return dataContext.toHumanReadableDateStr(data);
  }

  const exceedanceModifer = (value) => {
    let msg;
    switch (value) {
      case "1":
        msg = 'Low';
        break;
      case "2":
        msg = 'Medium';
        break;
      case "3":
        msg = 'High';
        break;
      default:
        msg = 'None';
        break;

    }
    return msg;
  }

  const exceedanceColorFn = (value) => {
    let color;
    switch (value) {
      case "Low":
        color = '#FAF28B';
        break;
      case "Medium":
        color = 'orange';
        break;
      case "High":
        color = '#FF0000';
        break;
      default:
        color = '#94FA8B';
        break;

    }
    return color;
  }

  const obstacleModifer = (value) => {
    let msg;
    switch (value) {
      case '1':
        msg = 'None';
        break;
      case '2':
        msg = 'Low';
        break;
      case '3':
        msg = 'Medium';
        break;
      case '4':
        msg = 'High';
        break;
      default:
        msg = 'None';
        break;

    }
    return msg;
  }

  const obstacleColorFn = (value) => {
    let color;
    switch (value) {
      case "Low":
        color = '#FAF28B';
        break;
      case "Medium":
        color = 'orange';
        break;
      case "High":
        color = '#FF0000';
        break;
      default:
        color = '#94FA8B';
        break;

    }
    return color;
  }

  const locModifer = (value) => {
    let msg;
    if (!value || value === 'nan') {
      msg = "False";
    }
    else {
      msg = 'True';
    }
    return msg;
  }

  const locColorFn = (value) => {
    let color;
    switch (value) {
      case "Low":
        color = '#FAF28B';
        break;
      case "Medium":
        color = 'orange';
        break;
      case "High":
        color = '#FF0000';
        break;
      case 'Insufficient Data':
        color =  'lightgrey';
        break;
      default:
        color = '#94FA8B';
        break;
    }
    return color;
  }

  const dqColorFn = (value) => {
    let color;
    if (value === '0') {
      color = '#94FA8B';
    }
    else {
      color = 'orange';
    }
    return color;
  }

  const cellTextStyle = { textAlign: "center", left: 0 };

  const handleRowSelected = (key, value) => {
    //value is the selected flight id
    dataContext.setConnectFlightId(value);

    if (dataContext.DEBUG_MODE)
      console.log(value);

  }


  const handleDQSelected = (event, value) => {
    // event.stopPropagation();
    //value is the selected flight id
    dataContext.setDqFlightId(value);

    if (dataContext.DEBUG_MODE)
      console.log(value);

  }

  /**
   * Handle DataBrowser row hovered event for drilling down on data record.
   * 
   * @param {string} key Key associated with target dataset for drilldown.
   * @param {any} value Value associated with key mapping in dataset for drilldown.
   */
  const handleRowHovered = (key, value) => {
    // TODO Handle row hovered event
    // console.log(`Row hovered with key = ${key} and value = ${value}`);

    dataContext.setHoverFlightId(value);

    //Reset the drawing mode, don't draw graphics on the map
    dataContext.setDrawMode(dataContext.DRAW_MODE.NODRAWING);
  }

  // const defaultBasemap = dataContext.darkMode ? "dark-v10" : "light-v10";
  const defaultBasemap = dataContext.baseMaps[dataContext.darkMode ? "Dark Basemap" : "Light Basemap"];
  // const basemap = BASEMAPS[BASEMAPS.findIndex(item => item.id === defaultBasemap)];
  const defaultBasemapStyle = dataContext.defaultBasemapStyleJson;
  defaultBasemapStyle['sources']['raster-tiles']['tiles'][0] = defaultBasemap.url;
  defaultBasemapStyle['sources']['raster-tiles']['attribution'] = defaultBasemap.attribution;

  const {
    width: resizeDetectorWidth,
    height: resizeDetectorHeight,
    ref: resizeDetectorRef
  } = useResizeDetector();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const draw = useRef(null);
  const searchRef = useRef(null);
  const geoRef = useRef(null);

  const [lng, setLng] = useState();
  const [lat, setLat] = useState();
  const [displayPointFlag, setDisplayPointFlag] = useState(false)
  const [zoomState, setZoomState] = useState(zoom);
  const [legend, setLegend] = useState(null);
  const [checkControl, setCheckControl] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [checkCtrlAirspace, setCheckCtrlAirspace] = useState(false);
  const [checkCtrlSightings, setCheckCtrlSightings] = useState(true);
  const [checkCtrlFacility, setCheckCtrlFacility] = useState(true);

  const [helipadMarker, setHelipadMarker] = useState(null);
  const [flightsMapResources, setFlightsMapResources] = useState([]);
  const [flightsMapLayers, setFlightsMapLayers] = useState([]);
  const flightsMapLayersList = useRef([]);
  const highlightMapLayersList = useRef([]);
  const sourceMapLayersList = useRef([]);
  const [searchObj, setSearchObj] = useState(dataContext.searchObj ? dataContext.searchObj : {})
  const [searchedCount, setSearchedCount] = useState(0)

  const [leftBarActive, setLeftBarActive] = useState(false)
  const [bottomBarActive, setBottomBarActive] = useState(false)
  const [runwayJsonUrl, setRunwayJsonUrl] = useState(null);

  let jsonUrl = ""

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const resetSearch = (event) => {
    console.log("Main Reset Method")
      searchRef.current.resetSearch()
      setLat('')
      setLng('')
      geoRef.current.resetSearch()
  }

  // Detect when this component is unmounted
  useEffect(() => {
    
      fetchData();
      // jsonUrl = result.preSignedUrl
      
    
    // isMounted.current = true;
    // return () => {
    //   isMounted.current = false;
    // }
  }, []);

  const fetchData = async () => {
    try {
      await getDocument(userContext.user, "RUNWAYS").then((result) => {
        setRunwayJsonUrl(result.preSignedUrl)
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  function clearDataLayer() {
    if (map.current.getLayer(mapLayerIdRunways)) map.current.removeLayer(mapLayerIdRunways);
    if (map.current.getLayer(mapLayerIdHelipads)) map.current.removeLayer(mapLayerIdHelipads);

    if (draw.current) {
      if (draw.current.getAll().features.length > 0) {
        draw.current.deleteAll();
      }
    }

    if (flightsMapLayers.length > 0)
      flightsMapLayers.forEach(layerId => {
        if (map.current.getLayer(layerId)) map.current.removeLayer(layerId);
      });

    if (flightsMapResources.length > 0)
      flightsMapResources.forEach(mapSource => {
        if (map.current.getSource(mapSource)) map.current.removeSource(mapSource);
      });

    if (map.current.getLayer(mapLayerIdCircle)) map.current.removeLayer(mapLayerIdCircle);
    if (map.current.getLayer(mapLayerIdCircleOutline)) map.current.removeLayer(mapLayerIdCircleOutline);
    if (map.current.getSource(mapResourceNameCircle)) map.current.removeSource(mapResourceNameCircle);


    if (highlightMapLayersList.current.length > 0) {
      highlightMapLayersList.current.forEach((item) => {

        if (map.current.getLayer(item['id'])) map.current.removeLayer(item['id']);
      })
    }
    if (flightsMapLayersList.current.length > 0) {
      flightsMapLayersList.current.forEach((item) => {

        if (map.current.getLayer(item['id'])) map.current.removeLayer(item['id']);
      })
    }
    if (sourceMapLayersList.current.length > 0) {
      sourceMapLayersList.current.forEach((item) => {

        if (map.current.getLayer(item['mapSourceFlightName'])) map.current.removeLayer(item['mapSourceFlightName']);
      })
    }

  }

  const addDataLayerRef = useRef(addDataLayer)
  addDataLayerRef.current = addDataLayer
  function addDataLayer() {
    // console.log("Adding data layer for UAS risk vector tiles map...");
    // let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;

    let sourceStatusRunways = map.current.getSource(mapResourceNameRunways);
    
    if (sourceStatusRunways === undefined) {
      
        // console.log(result.preSignedUrl)
        map.current.addSource(mapResourceNameRunways, {
          'type': 'geojson',
          'data': runwayJsonUrl
        });
      }
        if (map.current.getLayer(mapLayerIdRunways)) map.current.removeLayer(mapLayerIdRunways);
        if (map.current.getLayer(mapLayerIdHelipads)) map.current.removeLayer(mapLayerIdHelipads);
        map.current.addLayer({
          'id': mapLayerIdRunways, // Layer ID
          'type': 'fill',
          'source': mapResourceNameRunways, // ID of the tile source created above
          'minzoom': 11,
          "layout": { 'visibility': 'visible' },
          "paint": {
            "fill-color": '#2493dd',
            "fill-opacity": 1.0,
            "fill-outline-color": '#eee'
          }

      })
    


    // if (map.current.getLayer(mapLayerId)) map.current.removeLayer(mapLayerId);
    
    



    


    if (dataContext.DEBUG_MODE) {
      console.log(dataContext.renderFlightIds);
      console.log(flightsMapLayers);
    }

    if (dataContext.DEBUG_MODE)
      console.log(dataContext.searchedFlights.length);
    // console.log(dataContext.searchedFlightsRef.current.length);

    //Start process searchedFlights
    if (dataContext.searchedFlights.length > 0) {

      //////Remove the draw circle//////////
      if (draw.current) {
        if (draw.current.getAll().features.length > 0) {
          draw.current.deleteAll();
        }
      }
      //////////////////////////////////////

      if (dataContext.DEBUG_MODE)
        console.log(dataContext.searchStatus);

      /////////////////////////////////////////////////////////////
      /////Clean previous flights 
      if (flightsMapLayers.length > 0)
        flightsMapLayers.forEach(layerId => {
          if (map.current.getLayer(layerId)) map.current.removeLayer(layerId);
        });

      if (flightsMapResources.length > 0)
        flightsMapResources.forEach(mapSource => {
          if (map.current.getSource(mapSource)) map.current.removeSource(mapSource);
        });

      ////////////////////////////////////////////////////////////

      // Readd Flights, then add Highlight layer
      // if(flightsMapLayersList.current && flightsMapLayersList.current.length > 0){
      //   flightsMapLayersList.current.forEach(item => {
      //     map.current.addLayer()
      //   }
      //   )
      // }

      // if(highlightMapLayersList.current && highlightMapLayersList.current.length > 0){
      //   highlightMapLayersList.current.forEach(item => {
      //     map.current.addLayer()
      //   }
      //   )
      // }

      if (dataContext.DEBUG_MODE)
        console.log(dataContext.searchedFlights);

      // not used?
      // let flightIds = [];
      // dataContext.searchedFlights.forEach(item => {
      //   let flightId = item["flightid"];
      //   flightIds.push(flightId);
      // });


      ; //For getMyFlights then

      //Remove the circle
      if (map.current.getLayer(mapLayerIdCircle)) map.current.removeLayer(mapLayerIdCircle);
      if (map.current.getLayer(mapLayerIdCircleOutline)) map.current.removeLayer(mapLayerIdCircleOutline);
      if (map.current.getSource(mapResourceNameCircle)) map.current.removeSource(mapResourceNameCircle);


      // }

      // }); //For getFlightsPresignedURL

      // } // For if else backToGeoSearchMode 
    } //For dataContext.searchedFlights
    else { //Remove previous rendered layers

      if (dataContext.renderFlightIds.length > 0) {
        dataContext.renderFlightIds.forEach(flightId => {
          let mapSourceFlightName = "mapSourceName" + "-" + flightId;
          let mapLayerIdFlight = "mapLayerId" + "-" + flightId;
          let mapLayerIdFlightHighlight = "mapLayerId" + "-" + flightId + "-highlight";
          if (map.current.getLayer(mapLayerIdFlightHighlight)) map.current.removeLayer(mapLayerIdFlightHighlight);
          if (map.current.getLayer(mapLayerIdFlight)) map.current.removeLayer(mapLayerIdFlight);
          if (map.current.getSource(mapSourceFlightName)) map.current.removeSource(mapSourceFlightName);
        });

      }

    }

    // Add Sources and Flight Layers:
    if (dataContext.DEBUG_MODE) {
      console.log(sourceMapLayersList.current);
      console.log(flightsMapLayersList.current);
      console.log(highlightMapLayersList.current);

    }

    if (sourceMapLayersList.current && sourceMapLayersList.current.length > 0) {
      sourceMapLayersList.current.forEach(mapSource => {
        map.current.addSource(mapSource['mapSourceFlightName'], mapSource['sourceObj']);
      });


      if (flightsMapLayersList.current && flightsMapLayersList.current.length > 0) {
        flightsMapLayersList.current.forEach((item) => {
          map.current.addLayer(item)
        })
      }
      if (highlightMapLayersList.current && highlightMapLayersList.current.length > 0) {
        highlightMapLayersList.current.forEach((item) => {
          map.current.addLayer(item)
        })
      }
    }



  } // For addLayer()

  const renderFlightLayers = (flightId, geojsonUrl, style) => {

    if (dataContext.DEBUG_MODE)
      console.log(geojsonUrl);

    if (geojsonUrl.features[0].geometry.type === "GeneralizedPathDNE") {
      // stop trying to render if the flight has no flight path
      return;
    }

    //////////////Render Map Layers start////////////////////////////////////////////////
    /////Add map source of the flight
    let mapSourceFlightName = "mapSourceName" + "-" + flightId;
    // let sourceFlight = map.current.getSource(mapSourceFlightName);
    // if (sourceFlight === undefined) {
    sourceMapLayersList.current = [...sourceMapLayersList.current, {
      'mapSourceFlightName': mapSourceFlightName, 'sourceObj': {
        'type': 'geojson',
        'data': geojsonUrl
      }
    }]; // If it's not on the map already then add it to the list of things to add to the map in the render phase
    // }


    setFlightsMapResources(prevArray => [...prevArray, mapSourceFlightName])


    //////Add map layer of the flight
    let mapLayerIdFlight = "mapLayerId" + "-" + flightId;
    if (map.current.getLayer(mapLayerIdFlight)) map.current.removeLayer(mapLayerIdFlight);
    flightsMapLayersList.current = [...flightsMapLayersList.current, {
      'id': mapLayerIdFlight,
      'type': 'line',
      'source': mapSourceFlightName,
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
      },
      'paint': {
        'line-color': style["color"],
        'line-width': 5
      }
    }]
    // map.current.addLayer();

    setFlightsMapLayers(prevArray => [...prevArray, mapLayerIdFlight]);

    //////Add map layer of the flight highlight
    let mapLayerIdFlightHighlight = "mapLayerId" + "-" + flightId + "-highlight";
    if (map.current.getLayer(mapLayerIdFlightHighlight)) map.current.removeLayer(mapLayerIdFlightHighlight);
    highlightMapLayersList.current = [...highlightMapLayersList.current, {
      'id': mapLayerIdFlightHighlight,
      'type': 'line',
      'source': mapSourceFlightName,
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
        'visibility': 'none'
      },
      'paint': {
        'line-color': '#ff0',
        'line-width': 8
      }
    }]
    // map.current.addLayer();

    setFlightsMapLayers(prevArray => [...prevArray, mapLayerIdFlightHighlight]);

    // map.current.on('mousemove', mapLayerIdFlight, (e) => {
    //   // Change the cursor style as a UI indicator.
    //   map.current.getCanvas().style.cursor = 'pointer';

    //   // Extract features
    //   const { properties, geometry } = e.features[0];
    //   console.log(geometry);
    //   // Copy coordinates array.
    //   const coordinates = geometry.coordinates.slice();
    //   console.log(coordinates)

    //   let fieldsOfInterest = {
    //     "flightid": properties["flightid"],
    //     "Maker": properties["rotorcraftMake"],
    //     "Model": properties["rotorcraftModel"],
    //     "Series": properties["rotorcraftSeries"],
    //     "StartTime": properties["startTime"],
    //     "EndTime": properties["endTime"]
    //   };

    //   let color = uasSightingsColor;
    //   let tooltipHTML = buildFeaturedTooltip(fieldsOfInterest, { color: color });
    //   popup.setLngLat(coordinates).setHTML(tooltipHTML).addTo(map.current);
    // });

    // map.current.on('mouseleave', 'airport', () => {
    //   map.current.getCanvas().style.cursor = '';
    //   popup.remove();
    // });

    // map.current.on('click', (e) => {
    //   // Set `bbox` as 5px reactangle area around clicked point.
    //   const bbox = [
    //     [e.point.x - 5, e.point.y - 5],
    //     [e.point.x + 5, e.point.y + 5]
    //   ];
    //   // Find features intersecting the bounding box.
    //   const selectedFeatures = map.current.queryRenderedFeatures(bbox, {
    //     layers: flightsMapLayers
    //   });
    //   const flightids = selectedFeatures.map(
    //     (feature) => feature.properties.flightid
    //   );
    //   console.log(flightids);
    //   // Set a filter matching selected features by FIPS codes
    //   // to activate the 'counties-highlighted' layer.
    //   // map.setFilter('counties-highlighted', ['in', 'FIPS', ...fips]);

    // });

    //////////////Render Map Layers end////////////////////////////////////////////////

  }

  useEffect(() => { // searchedFlights 
    // if (isMounted.current) {
    // if (map.current) return; // Initialize map only once

    //Create layers, save into memory
    // Reset flight layers
    flightsMapLayersList.current = []
    highlightMapLayersList.current = []
    sourceMapLayersList.current = []


    let restrictedGeoJsonUrlByFId = {};


    let resultsMyF = dataContext.searchedFlights
    let foundCount = 0;

    /////////////Create dataTarget for DataBrower////////////////
    let searched_exist_flights = {};

    let columns = [];
    columns.push("alias");
    columns.push("flightmetadata_startisodate");
    columns.push("flightmetadata_rotorcraft_rotorcraftmodel");
    columns.push("flightmetadata_rotorcraft_rotorcraftseries");
    columns.push("flightmetadata_endisodate");
    columns.push("durationConvert");
    columns.push("flightmetadata_missionprofilenameshort");
    columns.push("exceedance_total_max_text");
    columns.push("flightmetadata_risks_maxobstaclelevelencountered_text");
    columns.push("loss_of_control_total_max_text");
    columns.push("dq_errors");
    columns.push("flight_id");
    searched_exist_flights["columns"] = columns;

    let lookUp = {};
    columns.forEach((item, idx) => {
      lookUp[item] = idx;
    });
    searched_exist_flights["lookup"] = lookUp;

    let flightData = []
    let renderFlightIds = [];
    let renderFlightStyles = {};
    ////////////////////////////////////////

    //Parse searchedFlights, scrub NaN, add geo objects.
    {
      // getMyFlights(userContext.user, "", searchObj).then((resultsMyF) => {
      if (dataContext.DEBUG_MODE)
        console.log(resultsMyF)

      if (resultsMyF && resultsMyF.length > 0) {
        let geoJSONArray = []
        // Scrub nan out of dq_errors, build geoJSON object

        resultsMyF.forEach((item) => {
          // console.log(item)
          // Scrub NaN
          if (item['dq_errors'] === 'nan')
            item['dq_errors'] = '0'


          //Parse the wkt into LINESTRING and a string of numbers
          const regEx = /([a-zA-Z\s]+)\(([^)]+)\)/;
          var matches = regEx.exec(item.geometry_flight_generalized_wkt)
          var geoType = "LineString"
          var geoArray = []
          if (matches && matches.length > 0) {
            // convert string to array of arrays

            let array1 = matches[2].split(",")
            array1.forEach(elem => {
              geoArray.push(elem.split(" "))
            })
          }
          else {
            geoType = 'GeneralizedPathDNE'
          }
          // console.log(geoArray)

          //Build geoJSON

          let geoObject = {
            "type": "FeatureCollection",
            "name": [item.flightmetadata__id],
            "crs":
            {
              "type": "name",
              "properties":
                { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" }
            },
            "features":
              [
                {
                  "type": "Feature",
                  "properties":
                  {
                    "flightid": item.flightmetadata__id,
                    "rotorcraftMake": item.flightmetadata_rotorcraft_rotorcraftmake,
                    "rotorcraftModel": item.flightmetadata_rotorcraft_rotorcraftmodel,
                    "rotorcraftSeries": item.lightmetadata_rotorcraft_rotorcraftseries,
                    "startTime": item.flightmetadata_starttime,
                    "endTime": item.flightmetadata_endtime
                  },
                  "geometry":
                  {
                    "type": geoType,
                    "coordinates": geoArray
                  }
                }
              ]
          }


          restrictedGeoJsonUrlByFId[item.flightmetadata__id] = geoObject

          let flightDataItem = [];
          flightDataItem.push(item["alias"]);
          flightDataItem.push(item["flightmetadata_startisodate"]);
          flightDataItem.push(item['flightmetadata_rotorcraft_rotorcraftmodel']);
          flightDataItem.push(item['flightmetadata_rotorcraft_rotorcraftseries']);
          flightDataItem.push(item["flightmetadata_endisodate"]);
          flightDataItem.push(item["durationConvert"]);
          flightDataItem.push(item["flightmetadata_missionprofilenameshort"]);
          flightDataItem.push(item["exceedance_total_max_text"]);
          flightDataItem.push(item["flightmetadata_risks_maxobstaclelevelencountered_text"]);
          flightDataItem.push(item["loss_of_control_total_max_text"]);
          flightDataItem.push(item["dq_errors"]);
          flightDataItem.push(item["flightmetadata__id"]);
          flightData.push(flightDataItem);


          let flightId = item["flightmetadata__id"];
          let geojsonUrl = restrictedGeoJsonUrlByFId[flightId];
          renderFlightIds.push(flightId);
          foundCount++;

          let style = {};
          style["color"] = getRandomColor();
          renderFlightStyles[flightId] = style;


          renderFlightLayers(flightId, geojsonUrl, style);
        })

        dataContext.setRenderFlightIds(renderFlightIds);
        dataContext.setRenderFlightStyles(renderFlightStyles);
        dataContext.setFoundFlightCount(foundCount); //Display the total searched flights number





        // console.log(restrictedGeoJsonUrlByFId)
        dataContext.setRestrictedGeoJsonUrlByFId(restrictedGeoJsonUrlByFId);

      }


    }
    if (resultsMyF && resultsMyF.length > 0) {

      // console.log({resultsMyF})

      // resultsMyF.forEach((resultMyF, idx) => {
      //   console.log("progress B")


      //   let flightId = resultMyF["flightmetadata__id"];
      //   let geojsonUrl = restrictedGeoJsonUrlByFId[flightId];
      //   renderFlightIds.push(flightId);
      //   foundCount++;

      //   let style = {};
      //   style["color"] = getRandomColor();
      //   renderFlightStyles[flightId] = style;

      //   ///////Render flight map Layers start/////
      //   // Rework to invoke and create all regular layers before adding highlight layers to change render


      // }); //For resultsMyF ForEach



      //Create dataTarget for the DataBrowser table view
      searched_exist_flights["data"] = flightData;
      // console.log(flightData)
      let dataTarget = {};
      // let targetItem = {};
      // targetItem["searched_exist_flights"] = searched_exist_flights;

      // dataTarget["searched_results"] = targetItem;
      dataTarget["searched_exist_flights"] = searched_exist_flights;
      // console.log(searched_exist_flights)

      if (dataContext.DEBUG_MODE)
        console.log(dataTarget);

      dataContext.setBaseData(dataTarget);

    } //For if resultsMyF.length 

    dataContext.setSearchStatus(false); //Turn off the circle progress 



    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: true,
      // closeOnClick: false,
      maxWidth: "350px",
      className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light",
    });

    //Define the function to add source and add layer. This function will be called in map.on("load") and map.on("styledata") event


    const changeBasemapStyle = (event, style) => {
      console.log(style);
    }

    //Function for circle button
    const drawCircleSearch = (event) => {
      if (dataContext.DEBUG_MODE)
        console.log(draw.current);

      ///Clean existing drawing circle
      if (draw.current) {

        if (dataContext.DEBUG_MODE) {
          console.log("event draw circle inside draw", event);

          //Circle geojson 
          console.log(draw.current.getAll());
        }

        if (draw.current.getAll().features.length > 0) {
          draw.current.deleteAll();
        }
        draw.current.changeMode('drag_circle');
        // dataContext.setDrawMode(dataContext.DRAW_MODE.CIRCLE);
        // dataContext.setCircleGeoCoords([]);
      }
    }

    const updateRadius = (event) => {
      if (dataContext.DEBUG_MODE)
        console.log(event);

      let feature = event.features[0];
      let circleGeoCoords = feature.geometry.coordinates;
      let circleCenter = feature.properties.center;
      let circleRadius = Math.round(feature.properties.radiusInKm * 0.621371 * 100) / 100;

      let circleGeoCoordsData = circleGeoCoords[0];
      let circleCoords = [];
      for (let i = 0; i < circleGeoCoordsData.length; i += 4) {
        circleCoords.push(circleGeoCoordsData[i])
      }

      if (dataContext.DEBUG_MODE) {
        console.log(circleCoords);

        console.log(circleCenter);
      }

      // console.log(circleRadius);

      // dataContext.setSearchedFlights([]);
      dataContext.setDrawMode(dataContext.DRAW_MODE.CIRCLE);

      dataContext.setSearchRadius(circleRadius);
      dataContext.setCircleGeoCoords(circleCoords);
    }

    if (!map.current) {
      //Basemap with predefined style
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: defaultBasemapStyle,
        center: lat && lng ? [lng, lat] : [startLoc[0], startLoc[1]],
        zoom: zoomState,
        transformRequest: (url, resourceType) => {
          if (resourceType === 'Source' && url.startsWith('https://maptiles.asias.info')) {
          return{

          url: url, 
          headers: {Authorization: userContext.user.signInUserSession.idToken.jwtToken}
          }
        }
        }
      });


      //Add controls
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.FullscreenControl(), 'top-right');
      map.current.addControl(new BasemapSwitchControl("basemap-checkControl-riskmap"));

      // add stuff here

      // const ctrlCircle = new MapboxGLButtonControl({
      //   className: "map-circle-icon",
      //   title: "Draw Circle to Search Flights",
      //   eventHandler: drawCircleSearch
      // });

      // map.current.addControl(ctrlCircle, 'top-right');

      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        defaultMode: "simple_select",
        clickBuffer: 10,
        touchBuffer: 10,
        // modes: {
        //   ...MapboxDraw.modes,
        //   // draw_circle: CircleMode,
        //   // direct_select: DirectMode,
        //   // simple_select: SimpleSelectMode,
        //   // drag_circle: DragCircleMode
        // }
      });

      map.current.addControl(draw.current);
      map.current.on('draw.create', updateRadius);
      map.current.on('draw.delete', updateRadius);
      map.current.on('draw.update', updateRadius);

    }

    if (map.current) {

      // Map event when the basemap style loads or changes.
      map.current.on('styledata', () => {

        // var mapLayer = map.current.getLayer(mapLayerId);
        var mapLayer = map.current.getLayer(mapLayerIdRunways);
        if (typeof mapLayer === 'undefined') {
          addDataLayerRef.current();
        }

        setStylesLoaded(true);

      });

      //Map event when the basemap was loaded
      map.current.on('load', () => {
        addDataLayer();
        dataContext.setMapLoadSearch(true)
        setBottomBarActive(true)
        // setMap(map);
        // map.resize();

        // Create legend
        // const legend = buildLegend();
        // setLegend(legend);

        // const checkControl = buildCheckControl();
        // setCheckControl(checkControl);


        map.current.on('click', (e) => {
          // console.log(e)
          if (e.originalEvent.ctrlKey) {
            let clickCoords = e.lngLat
            setLat(clickCoords['lat'])
            setLng(clickCoords['lng'])
            dataContext.setHelipadInfo([clickCoords['lng'], clickCoords['lat']]);
          }
        })

        // map.current.on('move', () => {
        //   setLng(map.current.getCenter().lng.toFixed(4));
        //   setLat(map.current.getCenter().lat.toFixed(4));
        //   setZoomState(map.current.getZoom().toFixed(2));
        // });
        addDataLayer();

        setMapLoaded(true);
      });

      // Condition is only satisfied when data is loaded and styles are applied
      // This means that layers should only be removed/added here if a filter is applied or removed after the initial map creation
      if (mapLoaded && stylesLoaded) {
        addDataLayer();
      }
    }
    // }//for if mounted

    // Unmount post-processing hook
    return () => {
      // TODO Cleanup objects as needed

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dataContext.baseData, dataContext.filteredData]);
  }, [dataContext.searchedFlights]);

  useEffect(() => {


    if (map.current && map.current.getLayer(mapLayerIdHelipadPoint)) map.current.removeLayer(mapLayerIdHelipadPoint);
    if (map.current && map.current.getSource(mapResourceNameHelipadPoint)) map.current.removeSource(mapResourceNameHelipadPoint);
    if (lat && lng) {
      setDisplayPointFlag(true)
    }
    else {
      setDisplayPointFlag(false)
    }
    dataContext.setHelipadInfo([lng, lat]);
  }, [lat, lng])

  useEffect(() => {
    // Resize map to fix viewport scaling when parent container is resized
    if (map.current) {
      map.current.resize();
    }

  }, [resizeDetectorWidth, resizeDetectorHeight]);

  useEffect(() => {
    // console.log("Bar changed state")
    if (map.current) {
      const paddingSet = { 'left': '0', 'bottom': '0' }

      if (leftBarActive) {
        paddingSet['left'] = '980'
      }
      if (bottomBarActive) {
        // console.log("Bot bar active")
        paddingSet['bottom'] = '400'
      }

      map.current.easeTo({
        padding: paddingSet,
        duration: 1000
      })
    }
  }, [bottomBarActive, leftBarActive])

  /////////For circle layer drawing////////////////////////////
  useEffect(() => {
    if (dataContext.DEBUG_MODE) {
      console.log(dataContext.searchRadius);
      console.log(dataContext.searchRadius);
      console.log(dataContext.helipadInfo);
    }

    if (map.current && map.current.getLayer(mapLayerIdCircle)) map.current.removeLayer(mapLayerIdCircle);
    if (map.current && map.current.getLayer(mapLayerIdCircleOutline)) map.current.removeLayer(mapLayerIdCircleOutline);
    if (map.current && map.current.getSource(mapResourceNameCircle)) map.current.removeSource(mapResourceNameCircle);

    // Don't draw unless a radius has been set
    if (dataContext.searchRadius && dataContext.helipadInfo[0] && dataContext.helipadInfo[1]) {
      //Add circle layer
      if (dataContext.searchRadius > 0 && dataContext.drawMode != dataContext.DRAW_MODE.NODRAWING) {



        if (dataContext.drawMode == dataContext.DRAW_MODE.INPUT) {
          let center = [];
          center[0] = dataContext.helipadInfo[0];
          center[1] = dataContext.helipadInfo[1];
          let radius = dataContext.searchRadius * 1.60934; //miles to kms
          let options = { steps: 60, units: 'kilometers', properties: { "ident": dataContext.helipadInfo[2] ? dataContext.helipadInfo[2] : "Point on Map" } };
          if (dataContext.DEBUG_MODE) console.log(options);

          let circleGeoJson = circle(center, radius, options);
          let circleGeoCoords = circleGeoJson.geometry.coordinates;
          let circleRadius = dataContext.searchRadius;

          // console.log(circleGeoCoords);
          let circleGeoCoordsData = circleGeoCoords[0];
          let circleCoords = [];
          for (let i = 0; i < circleGeoCoordsData.length; i += 4) {
            circleCoords.push(circleGeoCoordsData[i])
          }
          if (dataContext.DEBUG_MODE) console.log(circleCoords);

          dataContext.setSearchRadius(circleRadius);
          dataContext.setCircleGeoCoords(circleCoords);

          let sourceCircle = map.current.getSource(mapResourceNameCircle);
          if (sourceCircle === undefined) {
            map.current.addSource(mapResourceNameCircle, {
              'type': 'geojson',
              'data': circleGeoJson
            });
          }

          map.current.addLayer({
            'id': mapLayerIdCircle, // Layer ID
            'type': 'fill',
            'source': mapResourceNameCircle, // ID of the tile source created above
            'minzoom': 4,
            "layout": { 'visibility': 'visible' },
            "paint": {
              "fill-color": '#0080ff',
              "fill-opacity": 0.5
            }
          });

          map.current.addLayer({
            'id': mapLayerIdCircleOutline,
            'type': 'line',
            'source': mapResourceNameCircle,
            'layout': {},
            'paint': {
              'line-color': '#000',
              'line-width': 2
            }
          });
        }

      } //For if (dataContext.searchRadius > 0)
    }
  }, [dataContext.searchRadius, dataContext.helipadInfo]);

  ////////////////////////////////////////////////////////////////////
  // For hover flight layer 
  useEffect(() => {
    if (dataContext.DEBUG_MODE) {
      console.log(dataContext.hoverFlightId);
      // console.log(dataContext.renderFlightIds);
    }

    if (map.current && dataContext.renderFlightIds.length > 0) {

      dataContext.renderFlightIds.forEach(flightId => {
        let highlightLayerId = "mapLayerId" + "-" + flightId + "-highlight";
        if (map.current.getLayer(highlightLayerId)) {
          if (flightId === dataContext.hoverFlightId)
            map.current.setLayoutProperty(highlightLayerId, 'visibility', 'visible');
          else
            map.current.setLayoutProperty(highlightLayerId, 'visibility', 'none');
        }
      });
    }

  }, [dataContext.hoverFlightId]);

  // function toggleSidebar(id) {
  //   const elem = document.getElementById(id);
  //   // Add or remove the 'collapsed' CSS class from the sidebar element.
  //   // Returns boolean "true" or "false" whether 'collapsed' is in the class list.
  //   const collapsed = elem.classList.toggle('collapsed');
  //   const padding = {};
  //   // 'id' is 'right' or 'left'. When run at start, this object looks like: '{left: 300}';
  //   padding[id] = collapsed ? 0 : 300; // 0 if collapsed, 300 px if not. This matches the width of the sidebars in the .sidebar CSS class.
  //   // Use `map.easeTo()` with a padding option to adjust the map's center accounting for the position of sidebars.
  //   map.easeTo({
  //   padding: padding,
  //   duration: 1000 // In ms. This matches the CSS transition duration property.
  //   });
  //   }

  //For fly to helipad
  useEffect(() => {
    if (dataContext.DEBUG_MODE)
      console.log(dataContext.helipadInfo);


    if (map.current && dataContext.helipadInfo.length > 0) {

      let markerCoord = [dataContext.helipadInfo[0], dataContext.helipadInfo[1]];

      /////////////////////////Load helipad as a marker//////////////////////////////////
      // if (helipadMarker != null)
      //   helipadMarker.remove();

      // let helipadQuickInfo = {
      //   "Ident": dataContext.helipadInfo[2],
      //   "Name": dataContext.helipadInfo[3]
      // };

      // let tooltipHTML = buildFeaturedTooltip(helipadQuickInfo, { color: "#666" });

      // const el = document.createElement('div');
      // el.className = 'helipad-marker';

      // let marker = new mapboxgl.Marker(el)
      //   .setLngLat(markerCoord)
      //   .setPopup(
      //     new mapboxgl.Popup({
      //       offset: 15,
      //       closeButton: true,
      //       maxWidth: "350px",
      //       className: dataContext.darkMode ? "custom-map-tooltip-dark" : "custom-map-tooltip-light"
      //     }) // add popups
      //     .setHTML(tooltipHTML)
      //   )
      //   .addTo(map.current);

      // setHelipadMarker(marker);
      /////////////////////////End of loading helipad as a marker//////////////////////////

      ////////////////////////Load helipad as a image point layer//////////////////////////
      const addHelipadPointLayer = (lon, lat) => {
        if (map.current.getLayer(mapLayerIdHelipadPoint)) map.current.removeLayer(mapLayerIdHelipadPoint);
        if (map.current.getSource(mapResourceNameHelipadPoint)) map.current.removeSource(mapResourceNameHelipadPoint);

        // Add a data source containing one point feature.
        map.current.addSource(mapResourceNameHelipadPoint, {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [{
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [lon, lat]
              }
            }]
          }
        });

        // Add a layer to use the image to represent the data.
        map.current.addLayer({
          'id': mapLayerIdHelipadPoint,
          'type': 'symbol',
          'source': mapResourceNameHelipadPoint, // reference the data source
          'layout': {
            'icon-image': mapImageIdHelipadPoint, // reference the image
            'icon-size': [
              'interpolate', ['linear'],
              ['zoom'],
              5, 0.05,
              8, 0.07,
              12, 0.09,
              13, 0.1,
              14, 0.13
            ]
          }
        });
      }

      if (displayPointFlag) {
        if (map.current.hasImage(mapImageIdHelipadPoint)) {
          addHelipadPointLayer(dataContext.helipadInfo[0], dataContext.helipadInfo[1]);
        }
        else {
          map.current.loadImage(dataContext.helipadInfo[2] ? helipadPng : geoPointPng, (error, image) => {
            if (error) throw error;
            map.current.addImage(mapImageIdHelipadPoint, image);
            addHelipadPointLayer(dataContext.helipadInfo[0], dataContext.helipadInfo[1]);
          });
        }
      }

      ////////////////////////End of loading helipad as image point layer//////////////////
      if (dataContext.helipadInfo[2]) {
        map.current.flyTo({
          center: markerCoord,
          zoom: 8,
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
      }
    }

  }, [dataContext.helipadInfo]);


  const geoSearchFlightsByPolygon = () => {
    clearDataLayer()
    flightsMapLayersList.current = []
    highlightMapLayersList.current = []
    dataContext.setSearchStatus(true);
    dataContext.setFoundFlightCount(0)

    if (dataContext.searchRadius && dataContext.searchRadius > 0) { searchObj["polygon"] = dataContext.circleGeoCoords }
    else { searchObj["polygon"] = '' }

    if (dataContext.DEBUG_MODE)
      console.log(searchObj)
    getMyFlights(userContext.user, "", searchObj).then((resultsMyF) => {
      // if (dataContext.DEBUG_MODE)
        console.log(resultsMyF)

      dataContext.searchedFlightsMeta.current = resultsMyF

      if (resultsMyF) {
        //Create mimic of original geosearch strucutre for useEffect chain
        let tempResultList = []
        // Scrub nan out of dq_errors
        resultsMyF.forEach((item) => {
          if (item['dq_errors'] === 'nan')
            item['dq_errors'] = '0'

          tempResultList.push(item)
        
        })
        console.log("Check 1")
        dataContext.setSearchedFlights(tempResultList);


      }
      else {
        // dataContext.setSearchStatus(false);
        dataContext.setFoundFlightCount(0);
        dataContext.setSearchedFlights([]);
        dataContext.setBaseData({});
      }
      dataContext.setSearchStatus(false);
    })



  }

  const onSearchButtonClick = () => {

    //Remove the red status message
    // let curRadius = radiusRef.current.value;

    // if(dataContext.DEBUG_MODE)
    //   console.log(curRadius);

    dataContext.setShowNoOperatorFlights(false);
    dataContext.searchedFlightsMeta.current = []
    setLeftBarActive(false)
    // setStatusMsg(null);

    // if (curRadius > 0) {

    // dataContext.setSearchRadius(curRadius);
    // dataContext.setSearchStatus(true);
    // dataContext.setSearchedFlights([]);
    dataContext.setBaseData({});
    // dataContext.setBackToGeoSearchMode(false);


    // }
    geoSearchFlightsByPolygon();
  }

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {dataContext.DEBUG_MODE && (
        <div className="mapbox-custom-sidebar">
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoomState}
          </div>
        </div>
      )}

      <div id={mapId} ref={mapContainer} style={mapStyles}>

        <div id="left" className={leftBarActive ? bottomBarActive ? "sidebar with-bottom flex-sidebar-contents left" : "sidebar full-size flex-sidebar-contents left" : bottomBarActive ? "sidebar with-bottom flex-sidebar-contents left shrunk collapsed" : "sidebar full-size flex-sidebar-contents left collapsed"}>
          <div className="sidebar-over rounded-rect flex-center">
            <div className="sidebar-container">
              <div className={dataContext.darkMode ? "sidebar-content darkStyle" : "sidebar-content"}>

                <h5 className="registration-h5">Search Criteria</h5> 
                
                <Button variant="outlined" onClick={(event) => resetSearch({event})}>Reset Search</Button>


                <SearchMyFlights ref={searchRef} setSearchObj={setSearchObj} />
                <GeoSearchHelipadsRadiusBar ref={geoRef} geoSearchFlightsByPolygon={geoSearchFlightsByPolygon} searchObj={searchObj} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />


              </div>
              <div style={{ position: 'relative', alignSelf: 'end', width: '100%', paddingRight: '5px', paddingBottom: '5px' }}>
                <Button variant="contained" style={{ width: '100%' }} onClick={onSearchButtonClick}>Search</Button>
              </div>
            </div>
            <div className="sidebar-toggle rounded-rect left" onClick={() => setLeftBarActive(!leftBarActive)}>
              {leftBarActive ? <>&#9666;</> : <>&#9656;</>}
            </div>
          </div>
        </div>

        <div id="bottom" className={bottomBarActive ? "bottombar flex-bottombar-contents bottom" : "bottombar flex-bottombar-contents bottom collapsed"}>
          <div className="bottombar-over rounded-rect flex-center">
            <div className={dataContext.darkMode ? "bottombar-content rounded-rect flex-bottombar-contents darkStyle" : "bottombar-content rounded-rect flex-bottombar-contents"} >

              {/* <h5 className="registration-h5">Results {dataContext.foundFlightCount ? "- " + dataContext.foundFlightCount + " matching flights." : ""} </h5> */}
              {dataContext.searchStatus ? <div style={{ textAlign: 'center', verticalAlign: 'middle' }}><PageLoadSpinner />Searching Flights</div> : <>
                <div style={{fontSize:'15px'}}>Results capped to 100 flights, if you do not see the flight you expect try refining the search parameters.</div>
                <DataBrowser
                  primaryTargetKey="searched_exist_flights"
                  primaryCol="flight_id"
                  defaultRowsPerPage={100}
                  decorateNulls
                  isDivContainer={true}
                  onRowSelected={handleRowSelected}
                  onRowHovered={handleRowHovered}
                  persistRowSelection={false}
                  columnsOfInterest={
                    [
                    buildDataBrowserColumn({ col: "alias", alias: "Alias", width: 10, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "flightmetadata_startisodate", alias: "Start Time", width: 50, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "flightmetadata_rotorcraft_rotorcraftmodel", alias: "Model", width: 50, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "flightmetadata_rotorcraft_rotorcraftseries", alias: "Series", width: 50, enableHeaderOptions: true }),
                    // buildDataBrowserColumn({ col: "flightmetadata_endisodate", alias: "End Time", width: 50,modifier: dateTimeHandler  }),
                    buildDataBrowserColumn({ col: "durationConvert", alias: "Duration", width: 50, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "flightmetadata_missionprofilenameshort", alias: "Mission", width: 50, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "exceedance_total_max_text", alias: "Exceedances", width: 12, colorFn: exceedanceColorFn, textStyle: cellTextStyle, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "flightmetadata_risks_maxobstaclelevelencountered_text", alias: "Obstacles", width: 12, colorFn: obstacleColorFn, textStyle: cellTextStyle, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "loss_of_control_total_max_text", alias: "Loss of Control", width: 12, colorFn: locColorFn, textStyle: cellTextStyle, enableHeaderOptions: true }),
                    buildDataBrowserColumn({ col: "dq_errors", alias: "Data Quality", width: 6, colorFn: dqColorFn, textStyle: cellTextStyle, enableHeaderOptions: true, hideCol: true, onCellClick: handleDQSelected }),
                    buildDataBrowserColumn({ col: "flight_id", alias: "Flight Id", width: 40, hideCol: true, enableHeaderOptions: true })
                  ]}
                />
              </>}

            </div>
            <div className="bottombar-toggle rounded-rect bottom" onClick={() => setBottomBarActive(!bottomBarActive)}>
              {bottomBarActive ? <>&#9662;</> : <>&#9652;</>}
            </div>
          </div>
        </div>

        {/*checkControl*/}
        {/*gridLabelControl*/}
        {/*legend*/}
      </div>

    </div>

  )
}

GeoSearchByHelipadsMap.propTypes = propTypes;
GeoSearchByHelipadsMap.defaultProps = defaultProps;

export default GeoSearchByHelipadsMap;
