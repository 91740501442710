import React from 'react'
import {
  withRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import UserContext from '../components/UserContext'
import Home from '../pages/Home'
import About from '../pages/About'
import Help from '../pages/Help'
import NoMatch from '../pages/NoMatch'
import AboutAuthenticated from '../pages/AboutAuthenticated'
import Contact from '../pages/Contact'
import SignIn from '../pages/SignIn'
import ForgotPassword from '../pages/ForgotPassword'
import Dashboard from '../pages/Dashboard'
import DashboardV2 from '../pages/DashboardV2'
// import UploadPage from '../pages/UploadPage'
import FleetManagement from '../pages/FleetManagement'
import AddAircraft from '../pages/AddAircraft'
import UserRegistration from '../pages/UserRegistration'
import ManageApproval from '../pages/ManageApproval'
// import AccountSettings from '../pages/AccountSettings'
import UserProfile from '../pages/UserProfile'
import AdminSettings from '../pages/AdminSettings'
import UserManagement from '../pages/UserManagement'
import hasRole from '../services/hasRole'
import MyFlights from '../pages/MyFlights'
import SearchMyFlights from '../pages/SearchMyFlights'
import MyMetrics from '../pages/MyMetrics'
import ArrDepDashboard from '../pages/ArrDepMetrics.js'
import FaqPage from '../pages/FaqPage'
import TestPrototypeDemo from '../pages/TestPrototypeDemo.js'
import Glossary from "../pages/Glossary"
import AggregateUserMetricsWrapper from '../pages/AggregateUserMetricsWrapper'
import UimcAggregateMetricsWrapper from '../pages/UimcAggregateMetricsWrapper'
import FileStatus from '../pages/FileStatus'
import DocumentPage from '../pages/Documents'
import GeoSearchByHelipadsWrapper from '../pages/GeoSearchByHelipadsWrapper'
import ApproachFlightsMetricsWrapper from '../pages/ApproachFlightsMetricsWrapper'

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const isAuthenticated = this.context.user && this.context.user.username ? true : false
    /**
     * logic for admin logins only, this returns true if 
     * process.env.REACT_APP_ADMIN_LOGIN_ONLY is false 
     * or if it is true and user has an admin role.
    */
    // console.log(process.env.REACT_APP_ADMIN_LOGIN_ONLY, !(process.env.REACT_APP_ADMIN_LOGIN_ONLY === 'true'))
    // console.log(process.env.REACT_APP_ADMIN_LOGIN_ONLY, !(process.env.REACT_APP_ADMIN_LOGIN_ONLY.toLowerCase() === 'true'))
    // console.log((process.env.REACT_APP_ADMIN_LOGIN_ONLY === 'true'))
    // console.log(hasRole(this.context, ['administrator']))
    const authorizedLogin = !(process.env.REACT_APP_ADMIN_LOGIN_ONLY.toLowerCase() === 'true') || ((process.env.REACT_APP_ADMIN_LOGIN_ONLY.toLowerCase() === 'true') && hasRole(this.context, ['administrator']))
    const isLoaded = this.context.isLoaded

    if (!isLoaded) return null

    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated && authorizedLogin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }}
      />
    ) 
  }
}

class AdminRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) || hasRole(this.context, ['approver']) ? true : false
  
    // console.log(this.context.user)
    // console.log(this.context.user.username)
    // console.log(isAuthenticated)
    // console.log(hasRole(this.context, ['administrator']))
    // console.log(hasRole(this.context, ['approver']))

    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )
        }}
      />
    )
  }
}

class MyFlightsRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) || hasRole(this.context, ['operator']) ? true : false
  
    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )
        }}
      />
    )
  }
}

class MyMetricsRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) 
                            || hasRole(this.context, ['operator']) 
                            || hasRole(this.context, ['r-iat']) ? true : false
  
    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )
        }}
      />
    )
  }
}

class OperatorDTOMyMetricsRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) 
                            || hasRole(this.context, ['operator']) 
                            || hasRole(this.context, ['dto'])
                            || hasRole(this.context, ['r-iat']) ? true : false
  
    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )
        }}
      />
    )
  }
}

class OperatorIATMyMetricsRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) 
                            || hasRole(this.context, ['operator'])
                            || hasRole(this.context, ['r-iat']) ? true : false
  
    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )
        }}
      />
    )
  }
}

class MyFleetRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const isLoaded = this.context.isLoaded
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) || hasRole(this.context, ['operator']) || hasRole(this.context, ['dto']) ? true : false
  
    return (
      <Route
        {...rest}
        render={props => {
          return isLoaded ? (isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )) :
          ( <></>)
        }}
      />
    )
  }
}


class MyGeoSearchRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) || hasRole(this.context, ['operator']) ? true : false
  
    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )
        }}
      />
    )
  }
}

class MyApproachRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  static contextType = UserContext
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      // this.context.updateCurrentUser()
    })
  }
  componentWillUnmount() {
    this.unlisten()
  }
  render() {
    const { component: Component, ...rest } = this.props
    const isLoaded = this.context.isLoaded
    const groupsLoaded = this.context.groupsLoaded

    if (!groupsLoaded) return null
  
    const isLoggedOn = this.context.user && this.context.user.username ? true : false
    const isAuthenticated = hasRole(this.context, ['administrator']) || hasRole(this.context, ['operator']) ? true : false
  
    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedOn && isAuthenticated ? ( 
              <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          )
        }}
      />
    )
  }
}


PrivateRoute = withRouter(PrivateRoute)
MyFlightsRoute = withRouter(MyFlightsRoute)
MyMetricsRoute = withRouter(MyMetricsRoute)
MyFleetRoute = withRouter(MyFleetRoute)
AdminRoute = withRouter(AdminRoute)
MyGeoSearchRoute = withRouter(MyGeoSearchRoute)
OperatorDTOMyMetricsRoute = withRouter(OperatorDTOMyMetricsRoute)
OperatorIATMyMetricsRoute = withRouter(OperatorIATMyMetricsRoute)
MyApproachRoute = withRouter(MyApproachRoute)
    

const Routes = ({ location }) => (
  <>
  <Switch>
    <Route path='/' exact component={Home} />
    <Route path='/about' component={About} />
    <Route path='/contact' component={Contact} /> 
    <Route path='/help' component={Help} />
    <Route path="/login" component={SignIn} />
    <Route path="/register" component={UserRegistration} />
    <Route path="/forgot_password" component={ForgotPassword} />
    


    {/* BELOW ROUTES ARE ONLY ACCESSIBLE AFTER LOGIN */}
    <PrivateRoute path='/aboutAuth' component={AboutAuthenticated} />
    <PrivateRoute path='/home' component={Dashboard} />  
    <PrivateRoute path='/homeV2' component={DashboardV2} />  
    <PrivateRoute path='/documents' component={DocumentPage} />  
    {/* <PrivateRoute path='/upload' component={UploadPage} /> */}
    <MyFleetRoute path='/fleet' component={FleetManagement} />
    <PrivateRoute path='/new-aircraft' component={AddAircraft} />
    <PrivateRoute path='/glossary' component={Glossary} />
    <PrivateRoute path='/settings/profile' component={UserProfile} />
    <PrivateRoute path='/settings/admin' component={AdminSettings} />
    <MyFlightsRoute path='/my-flights' exact component={MyFlights} />
    <MyMetricsRoute path='/metrics' component={MyMetrics} />
    <OperatorIATMyMetricsRoute path='/user-metrics' component={AggregateUserMetricsWrapper} />
    <MyMetricsRoute path='/uimc-metrics' component={UimcAggregateMetricsWrapper} />
    <MyMetricsRoute path='/arrdep' component={ArrDepDashboard} />
    <OperatorDTOMyMetricsRoute path='/fileStatus' component={FileStatus} />
    <PrivateRoute path='/faq-page' component={FaqPage} />
    <PrivateRoute path="/SearchMyFlights" component={SearchMyFlights} />
    {/* <MyFlightsRoute path='/my-flights/:id' component={FlightMetrics} /> */}
    <MyGeoSearchRoute path='/search_by_helipads' component={GeoSearchByHelipadsWrapper} />
    <MyApproachRoute path='/approach-metrics' component={ApproachFlightsMetricsWrapper} />
    

    {/* BELOW ROUTES ARE ONLY ACCESSIBLE IF USER HAS ADMIN PRIVILEGES */}
    <AdminRoute path='/manage_approval' component={ManageApproval} />
    <AdminRoute path='/user_management' component={UserManagement} />
    <AdminRoute path='/TestPrototypeDemo' component={TestPrototypeDemo} />
    
    <Route component={NoMatch} />
  </Switch>
  </>
)

export default withRouter(Routes)
