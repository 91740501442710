import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {};
const defaultProps = {};

// Control/Configure the size of the markers and lines in the chart
const DATA_POINT_SIZE = 8;
const LINE_THICKNESS = 2;

// NOTE: Data points list with empty object (e.g., [{}]) will cause render in legend but no data points needed on chart
const LEGEND_CONFIG_TEMPLATE = {
  legendText: "",
  showInLegend: true,
  legendMarkerColor: "",
  legendMarkerType: "square",
  dataPoints: [{}]
};

function LocDescentRateScatterChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);

  // Determine if data exists
  const d = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
  const trackPointDataExists = d && d.track_points && d.track_points.data && d.track_points.data.length > 0;
  const exceedancePointDataExists = d && d.exceedance_point && d.exceedance_point.data && d.exceedance_point.data.length > 0;
  const dataExists = trackPointDataExists || exceedancePointDataExists;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);
  const [dataCalculated, setDataCalculated] = useState(true)

  /**
   * Handler for parsing date object to a formatted string version.
   * 
   * @param {object} e CanvasJS label event object containing the current date value.
   * @returns The formatted date string.
   */
  const dateLabelFormatter = (e) => {
    return dataContext.toHumanReadableDateStr(e.value, true);
  }

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
  const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  const [options, setOptions] = useState({
    zoomEnabled: true,
    animationEnabled: true,
    title: {
      text: ""
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    axisX: {
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      labelAutoFit: true,
      labelAngle: 0,
      title: "",
      gridThickness: 0.5,
      // See: https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/formatting-date-time/
      labelFormatter: dateLabelFormatter,
      labelFontSize: 13,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        labelFormatter: dateLabelFormatter,
      },
      // interval: 2,
      // intervalType: "minute",
    },
    axisY: {
      gridThickness: 0.5,
      labelFontFamily: dataContext.CHART_FONTS.fontFamily,
      labelFontSize: dataContext.CHART_FONTS.fontSize,
      labelFontWeight: dataContext.CHART_FONTS.fontWeight,
      title: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    data: [
      { type: "spline", dataPoints: [] }
    ]
  });

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
      console.log(dataTarget)
      if (dataTarget) {
        // Extract lookup table and data from track points or exceedance points if track points aren't available
        let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
        let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point;
        console.log(trackPointsTarget)
        const { data, lookup } = trackPointsTarget; // Extract lookup table from track points data
        const { exceedanceTypeColorMap } = dataContext.COLOR_MAPS.current;

        // Manage new data points and assign colors in legend
        let legendPlaceholdersLookup = {};

        // Extract only required data key/values for rendering data points and tooltips from track points data
        let dataPoints = [];
        let vrsCalculated = true;
        let lteCalculated = true;
        Array.from(data).forEach((point) => {
          let timestampStr = point[lookup.times_timestamp];
          let verticalSpeed = point[lookup.verticalspeed_final_fpm];

          vrsCalculated = vrsCalculated && point[lookup.vrs_calculated]
          lteCalculated = lteCalculated && point[lookup.lte_calculated]


          if (timestampStr !== null && verticalSpeed !== null) {
            let type = point[lookup.exceedance_type] || "NONE";
            type = type.toUpperCase();

            let color = exceedanceTypeColorMap["NONE"]; // Default color

            if (type === "LOSS OF CONTROL") {
              let exceedance = point[lookup.exceedance_subtype] || "NONE";
              exceedance = exceedance.toUpperCase().trim();
              color = exceedanceTypeColorMap[exceedance];
            }

            let loc = type === "LOSS OF CONTROL" ? point[lookup.exceedance_subtype_str] : "None";
            
            // Add new legend placeholder entry if needed
            if (!(loc in legendPlaceholdersLookup)) {
              legendPlaceholdersLookup[loc] = {
                ...LEGEND_CONFIG_TEMPLATE,
                legendText: loc,
                legendMarkerColor: color,
              };
            }

            let fieldsOfInterest = {
              "Time": point[lookup.human_readable_datetime],
              "Loss of Control (LOC)": loc,
              "Phase of Flight": point[lookup.phaseofflight_mavg10_str],
              "AGL (ft)": point[lookup.final_agl_str],
              "Ground Speed (kts)": point[lookup.groundspeed_final_kt_str],
              "Vertical Speed (f/m)": point[lookup.verticalspeed_final_fpm_str],
              "Roll (deg)": point[lookup.flightstate_position_roll_str],
              "Pitch (deg)": point[lookup.flightstate_position_pitch_str],
              "Yaw Rate (deg/s)": point[lookup.flightstate_rates_yawrate_str],
            };

            let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

            dataPoints.push({
              flightid: point[lookup.flightid],
              x: new Date(timestampStr),
              y: dataContext.round(verticalSpeed),
              color: color,
              tooltipText: tooltipText,
            });
          }
        });

        // Iterate through data points and set the line colors to the color of the next point
        Array.from(dataPoints).forEach((currentPoint, idx) => {
          let nextPointExists = (idx + 1) <= dataPoints.length - 1;
          let nextPoint = nextPointExists ? dataPoints[idx + 1] : null;
          currentPoint.lineColor = nextPoint !== null ? nextPoint.color : currentPoint.color;
        });
        

        // Data points of interest exist when at least one data point exists in the list
        setDataOfInterestExists(dataPoints.length > 0);
        setDataCalculated(vrsCalculated && lteCalculated)

        // Get the minimum and maximum possible x and y values to set appropriate scale for x-axis and y-axis (and doesn't change when zooming)
        const { min: minX } = dataContext.getMinMaxValues(dataPoints, "x");
        const { min: minY, max: maxY } = dataContext.getMinMaxValues(data, lookup.verticalspeed_final_fpm);
        let padding = (dataContext.getMagnitude(maxY) / 10) * 5;

        // Adjust padding if it is 0, which indicates all data points have the same y-axis value (e.g., min and max are equal)
        padding = padding === 0 ? 0.1 : padding;
        
        // Build flattended legend entries list to spread in data config
        let legendEntries = Object.entries(legendPlaceholdersLookup).map(entry => entry[1]);

        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        // See performance improvement considerations at https://canvasjs.com/forums/topic/performance-improvement-in-scatter-chart/
        // NOTE: Setting minimum x-axis (domain) value is required when adding legend entry placeholders. Otherwise, default date value is used which skews scale.
        setOptions({
          ...options,
          axisY: {
            ...options.axisY,
            minimum: minY - padding,
            maximum: maxY + padding,
          },
          axisX: {
            ...options.axisX,
            minimum: minX,
          },
          data: [
            ...legendEntries,
            {
              type: "spline",
              markerType: "square",
              lineThickness: LINE_THICKNESS,
              markerSize: DATA_POINT_SIZE,
              dataPoints: dataPoints
            },
          ]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);

  useEffect(() => {
    if (isMounted.current) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted.current) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists) && (
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataCalculated) && (
        <div>
          {/* Customize message as needed */}
          <b>Insufficient Data</b>
        </div>
      )}
      {(dataExists && dataCalculated && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Findings</b>
        </div>
      )}
    </div>
  );
}

LocDescentRateScatterChart.propTypes = propTypes;
LocDescentRateScatterChart.defaultProps = defaultProps;

export default LocDescentRateScatterChart;
