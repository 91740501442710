import React, { Component } from 'react'
import DashboardNav from '../components/DashboardNav'
import Footer from '../components/PageFooter'
import getQuicksightDash from '../services/getFlightDash'
import getArrivalsDepartures from '../services/getArrivalsDepartures'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import UserContext from '../components/UserContext';
import { NavLink as Link, Redirect } from 'react-router-dom';

export default class ArrDepMetrics extends Component {

    state = {
        url: {},
        isLoaded: false,
        error: {},
        loadedAggregate: false,
    }
    static contextType = UserContext


    componentDidMount() {
        getArrivalsDepartures(this.context.user)
        .then((results) => {
            if ('EmbedUrl' in results) {
                this.setState({url: results.EmbedUrl});
                this.setState({isLoaded: true});
            }
        })
        .catch((error) => {
            this.setState({error: error});
        })
    }

    getQuicksightDashboardEmbedURL = (embedUrl) => {
        // console.log("Rendering Dashboard")
        const containerDiv = document.getElementById("dashboardContainer");

         //clear any existing embeds so we can embed a new one and not duplicate
         while (containerDiv.lastChild){
            // console.log("Remove a child comp")
            containerDiv.removeChild(containerDiv.lastChild)
        }

        const options = {
            url: embedUrl,
            container: containerDiv,
            // parameters: {
            //     flightid : "FODR::1538745771.0:1538751766.0"
            // },
            scrolling: "no",
            height: "AutoFit",
            // width: "960px",
            footerPaddingEnabled: true,
        };
    
      // console.log({options})
        const dashboard = QuickSightEmbedding.embedDashboard(options);
    }

    render() {

        if(this.state.isLoaded && !this.state.loadedAggregate) {
            this.getQuicksightDashboardEmbedURL(this.state.url)
            this.setState({loadedAggregate: true})
        }


        return (
            <div>
                <DashboardNav />
                <div className="stretch_grid-container">
                    <h1>Arrivals/Departures</h1>
                    Note: The arrival-departure metrics are calculated using the ADS-B surveillance track data in the FAA’s Enterprise Information Management (EIM) system. ADS-B surveillance data has known limitations in the completeness of data in EIM as well as lack of surveillance coverage in specific areas at lower altitudes. These limitations may result in lower counts and flight hours compared to actual operations.
                <div id="dashboardContainer" ></div>

                    <Footer style={{paddingTop: '200px'}}/> 
                </div>
                
            </div>
        )
    }
}
