import React, { useState, useContext } from "react";
import { DataContext } from "../DataContext";
import PropTypes from 'prop-types';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import LocControls from "../controls/LocControls";
import DashboardWidget from "../DashboardWidget";
import LocDescentRateScatterChart from "../charts/loc/LocDescentRateScatterChart";
import VrsValidationScatterChart from "../charts/loc/VrsValidationScatterChart";
import LocCollectivePositionScatterChart from "../charts/loc/LocCollectivePositionScatterChart";
import LocCyclicPitchScatterChart from "../charts/loc/LocCyclicPitchScatterChart";
import LocCyclicRollScatterChart from "../charts/loc/LocCyclicRollScatterChart";
import LocAglScatterChart from "../charts/loc/LocAglScatterChart";
import LocGroundSpeedScatterChart from "../charts/loc/LocGroundSpeedScatterChart";
import LocVerticalSpeedScatterChart from "../charts/loc/LocVerticalSpeedScatterChart";
import ApproachSubchart from "../charts/loc/ApproachSubchart";
import LoadingSkeleton from "../utils/LoadingSkeleton";
import FlightIdBanner from "./FlightIdBanner";
// import Toolbar from "../utils/Toolbar";
import { IconButton, Tooltip } from "@mui/material";
import AddList from "../AddList";
import SaveIcon from '@mui/icons-material/Save';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BigNumber from "../misc/BigNumber";
import LteCompassRoseChart from "../charts/loc/LteCompassRoseChart";
import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
import LocRiskFrequencyBarChart from "../charts/loc/LocRiskFrequencyBarChart";
import LocRiskDurationBarChart from "../charts/loc/LocRiskDurationBarChart";
import LocTypeMap from "../maps/LocTypeMap";
import LocSeverityMap from "../maps/LocSeverityMap";
import { withParentSize } from "@visx/responsive";

// Need to import styles for core functionality
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const styles = {
  toolbarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolbarBanner: {
    marginRight: "auto",
  },
};

// Original props: { size: { width } }, darkMode
function LocContent(props) {
  const SAVED_LAYOUTS_KEY = "LocLayout";

  const dataContext = useContext(DataContext);
  const flightMetricsDashboardConfig = dataContext.flightMetricsDashboardConfig[dataContext.LOC_DASHBOARD_TAB_KEY];

  // NOTE: See above for sample data structure of original items and initial layouts
  const originalItems = Object.keys(flightMetricsDashboardConfig);

  const initialLayouts = {
    lg: originalItems.map(item => flightMetricsDashboardConfig[item])
  };

  const loadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
  const [layouts, setLayouts] = useState(
    loadedLayouts
  );

  // Initial set of widgets will be based on saved layouts data (defaults to initial layouts if no saved data found)
  const [items, setItems] = useState(loadedLayouts.lg.map(item => item.i));

  const onLayoutChange = (_, allLayouts) => {
    // NOTE: Initial bug for re-rendering components on layout change was resolved from within map components
    setLayouts(allLayouts);
  };

  // Approach details popover controls
  const [menuOpen, setMenuOpen] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  // const aglRef = useRef(null)
  const open = Boolean(menuOpen)
  const subcharts = props => <><ApproachSubchart key={props.id} id={props.id} open={open} anchorEl={anchorEl} menuOpen={menuOpen} setMenuOpen={setMenuOpen} /></>

  const openMenuItemWithAnchor = (item, anchor) => {
    // console.log("Debug")
    // console.log(item)
    // console.log(anchor)
    setMenuOpen(item)
    setAnchorEl(anchor)
  }

  const onLayoutSave = (e) => {
    e.stopPropagation();
    dataContext.saveToLS(SAVED_LAYOUTS_KEY, layouts);

    // Show snackbar notification on save
    dataContext.showSnack("top", "center", "Dashboard Layout Saved for Loss of Control (LOC)!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  };



  const onLayoutReset = (e) => {
    e.stopPropagation();
    const newLoadedLayouts = dataContext.getFromLS(SAVED_LAYOUTS_KEY) || initialLayouts;
    setLayouts(newLoadedLayouts);
    setItems(newLoadedLayouts.lg.map(item => item.i));
    dataContext.showSnack("top", "center", "Dashboard Layout Reset to Last Saved State for Loss of Control (LOC)!", dataContext.SNACK_SEVERITY.SUCCESS, 2000);
  }

  const onRemoveItem = (itemId) => {
    // console.log(`Removing ${itemId} from the dashboard...`);
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  // Handler Functions for Data Viewers
  const dateTimeHandler = (data) => {
    // return dataContext.toHumanReadableDateStr(new Date(data));
    return dataContext.toHumanReadableDateStr(data);
  }

  const roundingHandlerDefault = (data) => {
    return dataContext.roundStr(data);
  }

  const roundingHandlerLong = (data) => {
    return dataContext.roundStr(data, 6);
  }

  const humanReadableHandler = (data) => {
    return dataContext.capitalizeWords(data);
  }

  // Customize components
  const EnhancedLteCompassRoseChart = withParentSize(LteCompassRoseChart);

  // Core widgets list
  const componentList = {
    locRiskFrequencyBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <LocRiskFrequencyBarChart />,
    locRiskDurationBarChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <LocRiskDurationBarChart />,
    lteCompassRoseChart:
      dataContext.processing ? <LoadingSkeleton /> :
        <EnhancedLteCompassRoseChart />,
    locTypeMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <LocTypeMap
          mapId="loc-type-map"
          zoomSensitivity={0.2}
        />,
    locSeverityMap:
      dataContext.processing ? <LoadingSkeleton /> :
        <LocSeverityMap
          mapId="loc-severity-map"
          zoomSensitivity={0.2}
        />,
    // lteRiskDurationBigNumber:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <BigNumber
    //       source="exceedance_event"
    //       column="exceedance_subtype"
    //       sumColumn="duration"
    //       metric="sum"
    //       subheader="Second(s)"
    //       value="Loss of Tail-Rotor Effectiveness"
    //     />,
    // lteRiskFrequencyBigNumber:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <BigNumber
    //       source="exceedance_event"
    //       column="exceedance_subtype"
    //       metric="count"
    //       subheader="Event(s)"
    //       value="Loss of Tail-Rotor Effectiveness"
    //       decimalPlaces={0}
    //     />,
    locTrackPointsDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="track_points"
          decorateNulls
          filter={(row, lookup) => {
            let val = row[lookup.exceedance_type] || "NONE";
            val = val.toUpperCase();
            return val === "LOSS OF CONTROL";
          }}
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "times_timestamp", alias: "Time", modifier: dateTimeHandler }),
            buildDataBrowserColumn({ col: "flightstate_location_latitude", alias: "Latitude", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "flightstate_location_longitude", alias: "Longitude", modifier: roundingHandlerLong }),
            buildDataBrowserColumn({ col: "exceedance_subtype", alias: "Loss of Control (LOC)", modifier: data => humanReadableHandler(data || "None") }),
            buildDataBrowserColumn({ col: "exceedance_severity", alias: "Severity", modifier: humanReadableHandler }),
            buildDataBrowserColumn({ col: "phaseofflight_mavg10", alias: "Phase of Flight", modifier: humanReadableHandler }),
            buildDataBrowserColumn({ col: "final_agl", alias: "AGL (ft)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "groundspeed_final_kt", alias: "Ground Speed (kts)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "verticalspeed_final_fpm", alias: "Vertical Speed (f/m)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "flightstate_position_roll", alias: "Roll (deg)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "flightstate_position_pitch", alias: "Pitch (deg)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "flightstate_rates_yawrate", alias: "Yaw Rate (deg/s)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "rotors_mainrotortorque", alias: "Main Rotor Torque (%)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "engines_computations_e1torque", alias: "Engine 1 Torque", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "engines_computations_e2torque", alias: "Engine 2 Torque", modifier: roundingHandlerDefault }),
          ]}
        />,
        ...(process.env.REACT_APP_USER_BRANCH !== 'prod' && {locAglScatterChart :
          dataContext.processing ? <LoadingSkeleton /> : 
                                  <LocAglScatterChart subcharts={subcharts} openMenuItemWithAnchor={openMenuItemWithAnchor}/>}),
        ...(process.env.REACT_APP_USER_BRANCH !== 'prod' && {locGroundSpeedScatterChart :
          dataContext.processing ? <LoadingSkeleton /> : 
                                  <LocGroundSpeedScatterChart subcharts={subcharts} openMenuItemWithAnchor={openMenuItemWithAnchor}/>}),
        // locVerticalSpeedScatterChart : dataContext.processing ? <LoadingSkeleton /> : 
        //                         <LocVerticalSpeedScatterChart subcharts={subcharts} openMenuItemWithAnchor={openMenuItemWithAnchor}/>,
    // vrsRiskDurationBigNumber:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <BigNumber
    //       source="exceedance_event"
    //       column="exceedance_subtype"
    //       sumColumn="duration"
    //       metric="sum"
    //       subheader="Second(s)"
    //       value="Vortex Ring State"
    //     // modifier={result => result / 60} // Convert from seconds to minutes
    //     />,
    // vrsRiskFrequencyBigNumber:
    //   dataContext.processing ? <LoadingSkeleton /> :
    //     <BigNumber
    //       source="exceedance_event"
    //       column="exceedance_subtype"
    //       metric="count"
    //       subheader="Event(s)"
    //       value="Vortex Ring State"
    //       decimalPlaces={0}
    //     />,
    locDescentRateScatterChart:
      dataContext.processing ? <LoadingSkeleton /> : <LocDescentRateScatterChart />,
    vrsValidationScatterChart:
      dataContext.processing ? <LoadingSkeleton /> : <VrsValidationScatterChart />,
    locCollectivePositionScatterChart:
      dataContext.processing ? <LoadingSkeleton /> : <LocCollectivePositionScatterChart />,
    locCyclicPitchScatterChart:
      dataContext.processing ? <LoadingSkeleton /> : <LocCyclicPitchScatterChart />,
    locCyclicRollScatterChart:
      dataContext.processing ? <LoadingSkeleton /> : <LocCyclicRollScatterChart />,
    locEventsDataBrowser:
      dataContext.processing ? <LoadingSkeleton /> :
        <DataBrowser
          primaryTargetKey="exceedance_event"
          decorateNulls
          filter={(row, lookup) => {
            let val = row[lookup.exceedance_type] || "NONE";
            val = val.toUpperCase();
            return val === "LOSS OF CONTROL";
          }}
          columnsOfInterest={[
            buildDataBrowserColumn({ col: "start", alias: "Start", modifier: dateTimeHandler }),
            buildDataBrowserColumn({ col: "end", alias: "End", modifier: dateTimeHandler }),
            buildDataBrowserColumn({ col: "duration", alias: "Duration (seconds)", modifier: roundingHandlerDefault }),
            buildDataBrowserColumn({ col: "exceedance_subtype", alias: "Loss of Control (LOC)", modifier: data => humanReadableHandler(data || "None") }),
            buildDataBrowserColumn({ col: "exceedance_severity", alias: "Severity", modifier: humanReadableHandler }),
            buildDataBrowserColumn({ col: "exceedance_desc", alias: "Description" }),
          ]}
        />,
  };

  return (
    <>
      {/* Dashboard tab management functions - right aligned if display is flex and content is justified as flex-end */}
      <div style={styles.toolbarContainer}>
        <FlightIdBanner style={styles.toolbarBanner} />
        <div>
          <IconButton aria-label="save" onClick={onLayoutSave}>
            <Tooltip title="Save Dashboard Tab Layout" placement="top" arrow>
              <SaveIcon />
            </Tooltip>
          </IconButton>

          <IconButton aria-label="reset" onClick={onLayoutReset}>
            <Tooltip title="Reset Dashboard Tab Layout to Last Saved State" placement="top" arrow>
              <DashboardIcon />
            </Tooltip>
          </IconButton>

          <AddList
            items={items}
            onRemoveItem={onRemoveItem}
            onAddItem={onAddItem}
            originalItems={originalItems}
            dashboardKey={dataContext.LOC_DASHBOARD_TAB_KEY}
            dashboardConfig={dataContext.flightMetricsDashboardConfig}
          />
        </div>
      </div>
      {/* <Toolbar
        onLayoutSave={onLayoutSave}
        AddListProps={{
          items: items,
          onRemoveItem: onRemoveItem,
          onAddItem: onAddItem,
          originalItems: originalItems,
          dashboardKey: dataContext.LOC_DASHBOARD_TAB_KEY
        }}
      /> */}

      <LocControls
        onLayoutSave={onLayoutSave}
        items={items}
        onRemoveItem={onRemoveItem}
        onAddItem={onAddItem}
        originalItems={originalItems}
      />

      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={props.size.width * (1 / dataContext.appScale)}
        draggableHandle=".drag-handle"
        onLayoutChange={onLayoutChange}
        transformScale={dataContext.appScale} // Required to adapt transform translation offset when dragging
      >
        {items.map((key) => (

          <div
            key={key}
            data-grid={{ w: 6, h: 8, x: 0, y: Infinity }}
          >
            <DashboardWidget
              id={key}
              name={flightMetricsDashboardConfig[key].name}
              className="drag-handle"
              onRemoveItem={onRemoveItem}
              fullscreenEnabled={false}
            >
              {componentList[key]}
            </DashboardWidget>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

LocContent.propTypes = {
  size: PropTypes.object,
  onAddFilter: PropTypes.func,
  onReapplyFilters: PropTypes.func,
  onUpdateFilters: PropTypes.func,
};

LocContent.defaultProps = {
  onAddFilter: (key, value, filterExpression, filterLabel) => console.log("Unimplemented add filter callback.", { key: key, value: value, expression: filterExpression, label: filterLabel }),
  onReapplyFilters: () => console.log("Unimplemented reapply filters function."),
  onUpdateFilters: (filters) => console.log("Unimplemented update filters callback. Updated filters:", filters)
};

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(LocContent);
