import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataContext } from "../../DataContext";
// import PropTypes from 'prop-types';
import CanvasJSReact from '../../../canvasjs-commercial-3.4.12/canvasjs.react';
import { useResizeDetector } from 'react-resize-detector';
import '../Chart.css';

// const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const propTypes = {

};

const defaultProps = {

};

function PhaseOfFlightPieChart() {
  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);

  const { width, height, ref: resizeDetectorRef } = useResizeDetector();

  // Keep reference of chart for event listeners/actions
  const chartRef = useRef(null);
  const isMounted = useRef(false);
  const pofDurationsRef = useRef(null);

  // Determine if data exists
  const d = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
  const dataExists = d && d.unique_track_points && d.unique_track_points.data && d.unique_track_points.data.length > 0;

  const [dataOfInterestExists, setDataOfInterestExists] = useState(true);

  /**
   * Build and retrieve formatted HTML string representing a featured
   * tooltip given a set of values for a particular row of interest.
   * 
   * row = {
   *   "key_1": "value_1",
   *   "key_2": "value_2",
   *   ...
   *   "key_n": "value_n"
   * }
   * 
   * @param {object} row Fields of interest for current row in dataset.
   * @param {string} color Hexidecimal or RGB(A) color string.
   * @returns Formatted HTML string representing a featured tooltip.
   */
   const getFeaturedTooltip = (row, color = null) => {
    // Build tool tip off all properties
    var tooltipText = "";

    // Start table
    tooltipText += "<table>";
    for (const [key, value] of Object.entries(row)) {
      let iconData = `<td><i class="fa fa-square tooltip-icon-fonts" style="color: ${color}; margin-right: 5px;"></i></td>`;
      tooltipText += `
        <tr>
          ${color !== null && iconData}
          <td style="color: #989898"><b>${key}&nbsp</b></td>
          <td><b>${value}</b></td>
        </tr>
      `;
    }

    // End table
    tooltipText += "</table>";
    return tooltipText;
  }

  // See CanvasJS configuration options at https://canvasjs.com/docs/charts/basics-of-creating-html5-chart/
  // Value format string: https://canvasjs.com/docs/charts/chart-options/axisx/valueformatstring/
  const [options, setOptions] = useState({
    animationEnabled: true,
    title: {
      text: ""
    },
    legend: {
      fontFamily: dataContext.CHART_FONTS.fontFamily,
      fontSize: dataContext.CHART_FONTS.fontSize,
      fontWeight: dataContext.CHART_FONTS.fontWeight,
    },
    theme: dataContext.darkMode ? "dark1" : "light1",
    toolTip: {
      enabled: true,          //disable here
      animationEnabled: true, //disable here
      contentFormatter: e => {
        var content = "";
        for (var i = 0; i < e.entries.length; i++) {
          let dataPoint = e.entries[i].dataPoint;
          content = `${content}${dataPoint.tooltipText}`;
        }

        return content;
      }
    },
    data: [{
      indexLabelFontFamily: dataContext.CHART_FONTS.fontFamily,
      indexLabelFontSize: dataContext.CHART_FONTS.fontSize,
      indexLabelFontWeight: dataContext.CHART_FONTS.fontWeight,
      type: "pie", 
      dataPoints: []
    }]
  });

  /**
   * Drilldown on data on click event. Parse selection and pass forward to
   * data context to handle drilldown filter.
   * 
   * @param {object} e Event object passed from click listener on chart.
   */
  const handleDrilldown = (e) => {
    // Build data structure that imitates control filter
    // EXAMPLE:
    // {
    //   "phaseOfFlightSelect": {
    //     "title": "Phase of Flight",
    //     "label": "Standing, Hover Ground Effect",
    //     "column": "phaseofflight_mavg10",
    //     "values": [
    //       "standing",
    //       "hover ground effect"
    //      ]
    //    }
    // }
    const { indexLabel, value } = e.dataPoint;
    let values = [value];
    let filter = {
      [dataContext.PHASE_OF_FLIGHT_SELECT_KEY]: {
        id: dataContext.PHASE_OF_FLIGHT_SELECT_KEY,
        label: indexLabel,
        column: "phaseofflight_mavg10",
        values: values,
        track: true,
      }
    }

    chartRef.current.toolTip.hide(); // Hide tooltip to prevent errors
    dataContext.addControlFilter(filter);
  }

  /**
   * Get the corresponding color of a phase of flight to color code on the pie chart.
   * 
   * @param {string} phaseOfFlight The phase of flight to provide a color mapping for.
   */
  const getPhaseOfFlightColor = (phaseOfFlight) => {
    // Get color mapped hex value for given phase of flight
    let normalized = phaseOfFlight.toUpperCase().trim();
    const { phaseOfFlightColorMap } = dataContext.COLOR_MAPS.current;
    return normalized in phaseOfFlightColorMap ? phaseOfFlightColorMap[normalized] : "";
  }

  // Component mount/unmount
  useEffect(() => {
    isMounted.current = true;

    // NOTE: identify document element by id on component mount to ensure canvas js chart wraps to parent container
    if (resizeDetectorRef.current) {
      let chartContainer = resizeDetectorRef.current.children[0]; // e.g., canvasjs-react-chart-container-2
      if (chartContainer) {
        chartContainer.style.height = "100%";
        chartContainer.style.width = "100%";
      }
    }

    return () => {
      isMounted.current = false;

      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted.current && !pofDurationsRef.current) {
      // console.log("Computing static POF durations...");
      let dataTarget = dataContext.baseData; // Ensure consistent durations by looking at base data

      if (dataTarget && dataTarget.unique_track_points) {
        const { data, lookup } = dataTarget.unique_track_points; // Extract lookup table from track points data

        // Get unique count of each phase of flight
        let counts = dataContext.groupByCount(data, lookup.phaseofflight_mavg10);
        let total_pof_count = Object.keys(counts).reduce((prev, current) => prev + counts[current].count, 0);
        let total_flight_duration = data[0][lookup.flightmetadata_durationsec];

        // Iterate through phase of flight entries and calculate duration
        let total_pof_duration = 0;
        for (let key of Object.keys(counts)) {
          const { count } = counts[key];
          counts[key].duration = ( count / total_pof_count ) * total_flight_duration;
          total_pof_duration += counts[key].duration;
        }

        if (dataContext.DEBUG_MODE) {
          console.log("Total POF Count:", total_pof_count);
          console.log("Total POF duration (seconds):", Math.round(total_pof_duration * 100) / 100);
          console.log("Total flight duration (seconds):", total_flight_duration);
          console.log(counts);
        }

        pofDurationsRef.current = counts;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, pofDurationsRef.current]);

  // Detect changes to incomming/ingested data
  useEffect(() => {
    if (isMounted.current) {
      // Obtain reference to active data from context
      let dataTarget = dataContext.filteredData ? dataContext.filteredData : dataContext.baseData;
      // console.log("Building pof dataset...");
      // console.log("Data Target:", dataTarget);

      if (dataTarget && dataTarget.unique_track_points) {
        const { data, lookup } = dataTarget.unique_track_points; // Extract lookup table from track points data

        // Get unique count of each phase of flight
        let counts = dataContext.groupByCount(data, lookup.phaseofflight_mavg10);
        let total_pof_count = Object.keys(counts).reduce((prev, current) => prev + counts[current].count, 0);

        // Iterate through phase of flight entires and set static durations (e.g., only counts change, not durations)
        for (let key of Object.keys(counts)) {
          counts[key].duration = 0;

          if (pofDurationsRef.current && key in pofDurationsRef.current) {
            counts[key].duration = pofDurationsRef.current[key].duration;
          }
        }

        // Extract only required data key/values for rendering data points and tooltips from track points data
        let dataPoints = Object.keys(counts).map(item => {
          const capitalized = dataContext.capitalizeWords(item);
          let color = getPhaseOfFlightColor(capitalized);
          let count = counts[item].count;
          let durationHms = dataContext.convertSecondsToHMS(counts[item].duration);
          let percent = (count / total_pof_count) * 100;
          let fieldsOfInterest = {
            "Phase of Flight": capitalized,
            // "Count": `${count.toLocaleString('en-US')} (${dataContext.round(percent, 0)}%)`,
            "Duration (HMS)": `${durationHms} (${dataContext.round(percent, 0)}%)`
          };

          let tooltipText = getFeaturedTooltip(fieldsOfInterest, color);

          return {
            indexLabel: capitalized,
            value: item,
            y: count,
            click: handleDrilldown,
            color: color,
            tooltipText: tooltipText,
          }
        });

        // Data points of interest exist when at least one data point exists in the list
        setDataOfInterestExists(dataPoints.length > 0);

        // Create formatted data structure for canvasjs chart
        // See data series attributes at https://canvasjs.com/docs/charts/chart-options/data/
        setOptions({
          ...options,
          data: [
            {
              indexLabelFontFamily: dataContext.CHART_FONTS.fontFamily,
              indexLabelFontSize: dataContext.CHART_FONTS.fontSize,
              indexLabelFontWeight: dataContext.CHART_FONTS.fontWeight,
              type: "pie",
              showInLegend: true,
              explodeOnClick: false,
              // toolTipContent: `Phase of Flight <b>{indexLabel}</b><br> 
              //            Count${"              ".replaceAll(" ", "&nbsp")}<b>{y} (#percent%)</b>`,
              // yValueFormatString: "#,##0,,.## Million",
              legendText: "{indexLabel}",
              dataPoints: dataPoints
            }
          ]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.baseData, dataContext.filteredData]);

  useEffect(() => {
    if (isMounted.current) {
      // Re-render chart if there's a change in theme
      if (chartRef.current) {
        // NOTE: must do it this way since options are copied to chart reference (e.g., not bound by state/props)
        chartRef.current.options.theme = dataContext.darkMode ? "dark1" : "light1";
        chartRef.current.render();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.darkMode]);

  // Dynamically update width and height of chart to fill parent container on dimension change
  useEffect(() => {
    if (isMounted.current) {
      if (chartRef.current) {
        let yOffset = 20;
        let dh = height - yOffset;
        if (width > 0 && dh > 0) {
          chartRef.current.options.width = width;
          chartRef.current.options.height = dh;
          chartRef.current.render();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      {(dataExists && dataOfInterestExists) && (
        <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
      )}
      {(!dataExists) && (
        <div>
          <b>No Data</b>
        </div>
      )}
      {(dataExists && !dataOfInterestExists) && (
        <div>
          {/* Customize message as needed */}
          <b>No Data</b>
        </div>
      )}
    </div>
  );
}

PhaseOfFlightPieChart.propTypes = propTypes;
PhaseOfFlightPieChart.defaultProps = defaultProps;

export default PhaseOfFlightPieChart;
