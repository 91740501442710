import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOMServer from 'react-dom/server';
import { DataContext } from "../DataContext";
import UserContext from '../UserContext';
import getFlightsPresignedURL from '../../services/getFlightsPresignedURL';
import axios from 'axios'

import * as Cesium from 'cesium'

import { useResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';
// import DataBrowser, { buildDataBrowserColumn } from "../misc/DataBrowser";
// import Container from '@mui/material/Container';

// Import Styles
import 'font-awesome/css/font-awesome.min.css';
import '../styles/css/fontello.css';
import './Map.css';

import DOMPurify from "dompurify";
// import { makeStyles } from '@mui/styles';

//This is just for demo
// import flightCZML from './tmp3Ddata/S76D1_20220930T133924_20220930T135613.flight.czml.json';
import XMSN_Body from './gauges/XMSN_Oil/XMSN_Body.glb';
import Temp_Needle from './gauges/XMSN_Oil/Temp_Needle.glb';
import Pressure_Needle from './gauges/XMSN_Oil/Pressure_Needle.glb';
import Eng_Oil_Body from './gauges/Eng_Oil/Eng_Oil_Body.glb';
import Eng_Oil_Pressure from './gauges/Eng_Oil/Eng_Oil_Pressure.glb';
import Eng_Oil_Temperature from './gauges/Eng_Oil/Eng_Oil_Temperature.glb';
import KnotsGaugeBody from './gauges/KnotsGauge/KnotsGaugeBody.glb';
import KnotsGaugeArrow from './gauges/KnotsGauge/KnotsGaugeArrow.glb';
import Main_Fuel_Body from './gauges/Main_Fuel/Main_Fuel_Body.glb';
import Main_Fuel_Needle from './gauges/Main_Fuel/Main_Fuel_Needle.glb';
import VerticalSpeedGaugeBody from './gauges/VerticalSpeed/VerticalSpeedGaugeBody.glb';
import VerticalSpeedArrow from './gauges/VerticalSpeed/VerticalSpeedArrow.glb';
import TachometerGaugeBody from './gauges/Tachometer/TachometerGaugeBody.glb';
import TachometerGaugeArrowSmall from './gauges/Tachometer/TachometerGaugeArrowSmall.glb';
import TachometerGaugeArrow1 from './gauges/Tachometer/TachometerGaugeArrow1.glb';
import TachometerGaugeArrow2 from './gauges/Tachometer/TachometerGaugeArrow2.glb';
import TorqueGaugeBody from './gauges/TorquePercentGauge/TorqueGaugeBody.glb';
import TorqueGaugeArrow1 from './gauges/TorquePercentGauge/TorqueGaugeArrow1.glb';
import TorqueGaugeArrow2 from './gauges/TorquePercentGauge/TorqueGaugeArrow2.glb';
import Fuel_Flow_Body from './gauges/Fuel_Flow/Fuel_Flow_Body.glb';
import Fuel_Flow_Needle from './gauges/Fuel_Flow/Fuel_Flow_Needle.glb';
import T5_Body from './gauges/T5/T5_Body.glb';
import T5_Needle from './gauges/T5/T5_Needle.glb';
import N1_Body from './gauges/N1/N1_Body.glb';
import N1_Needle from './gauges/N1/N1_Needle.glb';


const propTypes = {
  mapId: PropTypes.string.isRequired,
  startLoc: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
};

const defaultProps = {
  startLoc: [-76.72425, 39.1057],
  zoom: 10,
};


const Playback3DMap = ({
  mapId,
  startLoc,
  zoom,
}) => {

  const containerStyle = {
    width: "100%",
    height: "120%",
    top: 50,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  }

  const {
    width: resizeDetectorWidth,
    height: resizeDetectorHeight,
    ref: resizeDetectorRef
  } = useResizeDetector();

  // Obtain reference to global data context consumer
  const dataContext = useContext(DataContext);
  const userContext = useContext(UserContext);

  Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_KEY

  const ref = useRef(null);

  function startup(Cesium, lookup, vizData) {
    'use strict';

    var withOrWithoutGauge = true;

    //<![CDATA[
    var viewer = new Cesium.Viewer('cesiumContainer', {
      geocoder: false,
      projectionPicker: false,
      sceneModePicker: false,
      timeline: true,
      vrButton: true,
      animation: true,
      terrainProvider: Cesium.createWorldTerrain(),
      baseLayerPicker: false
    });
    // var terrainProvider = Cesium.createWorldTerrain();
    // Click the VR button in the bottom right of the screen to switch to VR mode.

    const toolbar = document.querySelector("div.cesium-viewer-toolbar");
    const modeButton = document.querySelector("span.cesium-navigationHelpButton-wrapper");

    // const guageButton = document.createElement("button");
    // guageButton.classList.add("cesium-button", "cesium-toolbar-button");
    // guageButton.innerHTML = '<i class="fa fa-paper-plane" aria-hidden="true"></i>';
    // guageButton.onclick = function() {
    //   withOrWithoutGauge = !withOrWithoutGauge;
    //   console.log(withOrWithoutGauge);
    // };
    // toolbar.insertBefore(guageButton, modeButton);

    //////////////Customize the basemap widget///////////////////////
    const providerViewModels = [];
    providerViewModels.push(
      new Cesium.ProviderViewModel({
        name: "Bing Maps Aerial",
        iconUrl: Cesium.buildModuleUrl("Widgets/Images/ImageryProviders/bingAerial.png"),
        tooltip: "Bing Maps aerial imagery, provided by Cesium ion",
        category: "Basemaps",
        creationFunction: function() {
          return Cesium.createWorldImagery({
            style: Cesium.IonWorldImageryStyle.AERIAL,
          });
        },
      })
    );

    providerViewModels.push(
      new Cesium.ProviderViewModel({
        name: "Bing Maps Aerial with Labels",
        iconUrl: Cesium.buildModuleUrl(
          "Widgets/Images/ImageryProviders/bingAerialLabels.png"
        ),
        tooltip: "Bing Maps aerial imagery with labels, provided by Cesium ion",
        category: "Basemaps",
        creationFunction: function() {
          return Cesium.createWorldImagery({
            style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
          });
        },
      })
    );

    providerViewModels.push(
      new Cesium.ProviderViewModel({
        name: "Bing Maps Roads",
        iconUrl: Cesium.buildModuleUrl("Widgets/Images/ImageryProviders/bingRoads.png"),
        tooltip: "Bing Maps standard road maps, provided by Cesium ion",
        category: "Basemaps",
        creationFunction: function() {
          return Cesium.createWorldImagery({
            style: Cesium.IonWorldImageryStyle.ROAD,
          });
        },
      })
    );

    providerViewModels.push(
      new Cesium.ProviderViewModel({
        name: "ESRI World Imagery",
        iconUrl: Cesium.buildModuleUrl(
          "Widgets/Images/ImageryProviders/esriWorldImagery.png"
        ),
        tooltip: "\
World Imagery provides one meter or better satellite and aerial imagery in many parts of the world and lower resolution \
satellite imagery worldwide.  The map includes NASA Blue Marble: Next Generation 500m resolution imagery at small scales \
(above 1:1,000,000), i-cubed 15m eSAT imagery at medium-to-large scales (down to 1:70,000) for the world, and USGS 15m Landsat \
imagery for Antarctica. The map features 0.3m resolution imagery in the continental United States and 0.6m resolution imagery in \
parts of Western Europe from DigitalGlobe. In other parts of the world, 1 meter resolution imagery is available from GeoEye IKONOS, \
i-cubed Nationwide Prime, Getmapping, AeroGRID, IGN Spain, and IGP Portugal.  Additionally, imagery at different resolutions has been \
contributed by the GIS User Community.\nhttp://www.esri.com",
        category: "Basemaps",
        creationFunction: function() {
          return new Cesium.ArcGisMapServerImageryProvider({
            url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
            enablePickFeatures: false,
          });
        },
      })
    );


    const pickBase = document.createElement('span');
    pickBase.setAttribute("id", "baseLayerPickerContainer");
    toolbar.insertBefore(pickBase, modeButton);

    const baseLayerPicker = new Cesium.BaseLayerPicker('baseLayerPickerContainer', {
      globe: viewer.scene, //Important!!!
      imageryProviderViewModels: providerViewModels
    });


    /////////////////////View change button functions////////////////////////////////////////////

    var viewOrder = 0;
    var totalViewNum = 8;

    const changeViewBtn = document.createElement("button");
    changeViewBtn.classList.add("cesium-button", "cesium-toolbar-button");
    changeViewBtn.innerHTML = DOMPurify.sanitize('<i class="fa fa-paper-plane" aria-hidden="true"></i>');
    changeViewBtn.title = "Click this button to change different view";
    changeViewBtn.onclick = function() {
      // console.log(viewOrder);
      switch (viewOrder) {
        case 0:
          if (listener !== undefined) {
            viewer.clock.onTick.removeEventListener(listener);
          }
          viewer.trackedEntity = aircraft;
          changeViewBtn.title = "Chase view: Tracking view of the rotor craft from above.";
          break;
        case 1:
          trackingViewHandler(leftSideListener);
          changeViewBtn.title = "Front view: Tracking view of the rotor craft from the front.";
          break;
        case 2:
          trackingViewHandler(rightSideListener);
          changeViewBtn.title = "Rear View: Tracking view of the rotor craft from the rear.";
          break;
        case 3:
          trackingViewHandler(rideAlongListener);
          changeViewBtn.title = "Left View: Tracking view of the rotor craft from the left side.";
          break;
        case 4:
          trackingViewHandler(rideAlongOthersideListener);
          changeViewBtn.title = "Right View: Tracking viewview of the rotor craft from the right side";
          break;
        case 5:
          trackingViewHandler(cockpitListener);
          changeViewBtn.title = "Windshield View: the view of the rotor craft from inside.";
          break;
        case 6:
          staticViewHandler(0, -90);
          changeViewBtn.title = "Top down view: A fixed-position, top down view of the rotor craft.";
          break;
        case 7:
          staticViewHandler(-90, 0);
          changeViewBtn.title = "Side view: A fixed-position side view of the rotor craft at the current time.";
          break;
        default:
          if (listener !== undefined) {
            viewer.clock.onTick.removeEventListener(listener);
          }
          viewer.trackedEntity = aircraft;
          changeViewBtn.title = "Tracking view of the rotor craft from above.";
          break;
      }
      viewOrder++;
      if (viewOrder > totalViewNum - 1)
        viewOrder = 0;

    };
    toolbar.insertBefore(changeViewBtn, modeButton);


    /****** Multiple buttons in the toolbar ****
    // const buttonTopDown = createToolButton('Top Down', "A fixed-position, top down view of the rotor craft.");
    // buttonTopDown.onclick = function() {
    //   staticViewHandler(0, -90);
    // };
    // toolbar.insertBefore(buttonTopDown, modeButton);

    // const buttonSide = createToolButton('Side View', "A fixed-position side view of the rotor craft at the current time.");
    // buttonSide.onclick = function() {

    // };
    // toolbar.insertBefore(buttonSide, modeButton);

    // const buttonAircraft = createToolButton('Air- craft', "Tracking view of the rotor craft from above");
    // buttonAircraft.onclick = function() {
    //   if (listener !== undefined) {
    //     viewer.clock.onTick.removeEventListener(listener);
    //   }
    //   viewer.trackedEntity = aircraft;
    // };
    // toolbar.insertBefore(buttonAircraft, modeButton);

    // const buttonLeftSide = createToolButton('Front Side', "Tracking view of the rotor craft from the front.");
    // buttonLeftSide.onclick = function() {
    //   trackingViewHandler(leftSideListener);
    // };
    // toolbar.insertBefore(buttonLeftSide, modeButton);

    // const buttonRightSide = createToolButton('Rear Side', "Tracking view of the rotor craft from the rear.");
    // buttonRightSide.onclick = function() {
    //   trackingViewHandler(rightSideListener);
    // };
    // toolbar.insertBefore(buttonRightSide, modeButton);

    // const buttonRightAlong = createToolButton('Ride Along', "Tracking view of the rotor craft along the flight.");
    // buttonRightAlong.onclick = function() {
    //   trackingViewHandler(rideAlongOthersideListener);
    // };
    // toolbar.insertBefore(buttonRightAlong, modeButton);

    // const buttonCockpit = createToolButton('Wind- shield ', "Windshield view of the rotor craft from inside.");
    // buttonCockpit.onclick = function() {
    //   trackingViewHandler(cockpitListener);
    // };
    // toolbar.insertBefore(buttonCockpit, modeButton);
    */

    // viewer.scene.globe.enableLighting = true;
    // viewer.scene.globe.depthTestAgainstTerrain = true;
    /////////////////////////////////////////////////////////////////////
    // Postprocessing setting, use fxaa stage after set windows device resolution to viewer resolution!
    viewer.resolutionScale = window.devicePixelRatio;
    viewer.scene.postProcessStages.fxaa.enabled = true;

    //   viewer.scene.globe.enableLighting = false;
    ///////////////////////////////////////////////////////////////////

    // Follow the path of a plane. See the interpolation Sandcastle example.
    Cesium.Math.setRandomNumberSeed(3);
    viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
    viewer.clock.multiplier = 1.0;
    viewer.clock.shouldAnimate = true;

    var dataStream; //entities that will be taken from CZML

    /*Arrays that will be populated with Strings from CZML*/
    var engine0Torque;
    var engine1Torque;
    var airSpeedGauge;
    var engineOilPressure;
    var engineOilTemp;
    var fuelFlow;
    var xMSNPressure;
    var xMSNTemp;
    var mainFuelWeight;
    var n1NGGauge;
    var turbineTemp;
    var altitudeRateGauge;
    var tachometerN2R1;
    var tachometerN2R2;
    var tachometerRotorRPM;
    var heliHPR;
    var newPos;
    var mainClock;
    var positionData = null; //long, lat, evaluation (x,y,z) 
    var heliHPRdata = null; //Heli data that was taken from the CZML determines the orientation of the helicopter.
    var gaugeStatusData; //Track status data
    var gaugeStatus;
    var availabilityStart;

    var tiger, aircraft; //helicopter entity

    var gaugeCollection; //Object that holds sampledProperties which holds the values of the different gauges.

    var gaugeSelect = 0; //Selects which gauge to look at.
    var gaugeScale = 50 // original 70;
    var gaugeTopScalar = 0.2;
    var gaugeRightScalar = 0.43;
    var gaugeGapScale = 0.12; // original 0.15;

    /*heading pitch and roll of the camera*/
    var cHeading;
    var cPitch;
    var cRoll;
    var camera = viewer.camera; //Camera object

    var offSet = new Cesium.Cartesian3(0, 0, 0); //Makes camera slightly behind the gauge.
    var neuteredUp = new Cesium.Cartesian3(0, 0, 0); //Result of the Camera.Up vector when scaled.
    var neuteredLeft = new Cesium.Cartesian3(0, 0, 0); //Result of the Camera.Right vector when scaled.

    var ready = false; //Indicates if all initilizations have been completed.

    var CurrentGaugeCount = 0;

    function createGaugeModels() {
      createModel(XMSN_Body, gaugeScale, 'XMSNBody');
      createModel(Temp_Needle, gaugeScale, 'XMSNTNeedle');
      createModel(Pressure_Needle, gaugeScale, 'XMSNPNeedle');
      createModel(Eng_Oil_Body, gaugeScale, 'EngOilBody');
      createModel(Eng_Oil_Pressure, gaugeScale, 'EngOilPNeedle');
      createModel(Eng_Oil_Temperature, gaugeScale, 'EngOilTNeedle');
      createModel(KnotsGaugeBody, gaugeScale, 'KnotBody');
      createModel(KnotsGaugeArrow, gaugeScale, 'KnotNeedle');
      createModel(Main_Fuel_Body, gaugeScale, 'FuelBody');
      createModel(Main_Fuel_Needle, gaugeScale, 'FuelNeedle');
      createModel(VerticalSpeedGaugeBody, gaugeScale, 'VertSpeedBody');
      createModel(VerticalSpeedArrow, gaugeScale, 'VertSpeedNeedle');
      createModel(TachometerGaugeBody, gaugeScale, 'TachoBody');
      createModel(TachometerGaugeArrowSmall, gaugeScale, 'TachoSNeedle');
      createModel(TachometerGaugeArrow1, gaugeScale, 'Tacho1Needle');
      createModel(TachometerGaugeArrow2, gaugeScale, 'Tacho2Needle');
      createModel(TorqueGaugeBody, gaugeScale, 'TorqueBody');
      createModel(TorqueGaugeArrow1, gaugeScale, 'Torque1Needle');
      createModel(TorqueGaugeArrow2, gaugeScale, 'Torque2Needle');
      createModel(Fuel_Flow_Body, gaugeScale, 'FlowBody');
      createModel(Fuel_Flow_Needle, gaugeScale, 'FlowNeedle');
      createModel(T5_Body, gaugeScale, 'T5Body');
      createModel(T5_Needle, gaugeScale, 'T5Needle');
      createModel(N1_Body, gaugeScale, 'N1Body');
      createModel(N1_Needle, gaugeScale, 'N1Needle');

      viewer.entities.getById('XMSNBody').show = true;
      viewer.entities.getById('XMSNTNeedle').show = true;
      viewer.entities.getById('XMSNPNeedle').show = true;

      viewer.entities.getById('EngOilBody').show = true;
      viewer.entities.getById('EngOilPNeedle').show = true;
      viewer.entities.getById('EngOilTNeedle').show = true;

      viewer.entities.getById('KnotBody').show = true;
      viewer.entities.getById('KnotNeedle').show = true;

      viewer.entities.getById('FuelBody').show = false;
      viewer.entities.getById('FuelNeedle').show = false;

      viewer.entities.getById('VertSpeedBody').show = false;
      viewer.entities.getById('VertSpeedNeedle').show = false;

      viewer.entities.getById('TachoBody').show = false;
      viewer.entities.getById('TachoSNeedle').show = false;
      viewer.entities.getById('Tacho1Needle').show = false;
      viewer.entities.getById('Tacho2Needle').show = false;

      viewer.entities.getById('TorqueBody').show = false;
      viewer.entities.getById('Torque1Needle').show = false;
      viewer.entities.getById('Torque2Needle').show = false;

      viewer.entities.getById('FlowBody').show = false;
      viewer.entities.getById('FlowNeedle').show = false;

      viewer.entities.getById('T5Body').show = false;
      viewer.entities.getById('T5Needle').show = false;

      viewer.entities.getById('N1Body').show = false;
      viewer.entities.getById('N1Needle').show = false;
    }

    // createGaugeModels();

    /**
     * Sets the position and orientation of a specified gauge.
     */
    function gaugeAnimate(gaugePart, newPos, orientationQuaternion) {
      if (newPos !== undefined && orientationQuaternion !== undefined) {
        gaugePart.position = newPos; //Sets position

        gaugePart.orientation = orientationQuaternion; //Sets orientation

      }
    }

    /**
    ////////////////View angle button functions//////////////////////////// 
     */
    var listener;
    var modelMatrixLocation;

    //For soem reason Purifying the HTML here breaks cesium.  Since this function was unused I've commented it out
    // --Nick
    // function createToolButton(btnName, title) {
    //   const buttonViewAngle = document.createElement("button");
    //   buttonViewAngle.classList.add("cesium-button", "cesium-toolbar-button");
    //   buttonViewAngle.innerHTML = '<div class="cesium-viewer-button-text" >' + btnName + '</div>';
    //   buttonViewAngle.title = title;

    //   return buttonViewAngle;
    // }


    //  Updates the left-side camera view based on the clock
    var leftSideListener = function(clock) {
      var transform = getTransform();
      var offset = new Cesium.HeadingPitchRange(Cesium.Math.toRadians(180),
        Cesium.Math.toRadians(0),
        300);
      viewer.scene.camera.lookAtTransform(transform, offset);
    };

    //  Updates the right-side camera view based on the clock
    var rightSideListener = function(clock) {
      var transform = getTransform();
      var offset = new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0),
        Cesium.Math.toRadians(0),
        400);
      viewer.scene.camera.lookAtTransform(transform, offset);
    };

    //  Updates the ride-along camera view based on the clock
    var rideAlongListener = function(clock) {
      var transform = getTransform();
      var offset = new Cesium.HeadingPitchRange(Cesium.Math.toRadians(270),
        Cesium.Math.toRadians(0),
        550);
      viewer.scene.camera.lookAtTransform(transform, offset);
    };

    //  Updates the ride-along camera view based on the clock
    var rideAlongOthersideListener = function(clock) {
      var transform = getTransform();
      var offset = new Cesium.HeadingPitchRange(Cesium.Math.toRadians(90),
        Cesium.Math.toRadians(0),
        450);
      viewer.scene.camera.lookAtTransform(transform, offset);
    };

    //  Updates the ride-along camera view based on the clock
    var cockpitListener = function(clock) {
      var transform = getTransform();
      var offset = new Cesium.HeadingPitchRange(Cesium.Math.toRadians(90),
        Cesium.Math.toRadians(0),
        0.00001);
      viewer.scene.camera.lookAtTransform(transform, offset);

      // viewer.camera.lookAtTransform(transform, new Cesium.Cartesian3(0.0, 0.0, 0.2));

      viewer.scene.camera.lookLeft(Cesium.Math.toRadians(90));
    };

    //  Returns the current transform from the primitives if available.
    function getTransform() {
      var transform;

      if (modelMatrixLocation === undefined) {
        for (var i = 0; i < viewer.scene.primitives.length; i++) {
          if (viewer.scene.primitives._primitives[i].id !== undefined &&
            viewer.scene.primitives._primitives[i].id.id === aircraft.id) {
            modelMatrixLocation = i;
            break;
          }
        }
      }
      if (modelMatrixLocation !== undefined) {
        transform = viewer.scene.primitives._primitives[modelMatrixLocation].modelMatrix.clone();
      }
      return transform;
    }

    //  handles updates for static views (top down, side view)
    function staticViewHandler(headingInDegrees, pitchInDegrees) {

      if (listener !== undefined) {
        viewer.clock.onTick.removeEventListener(listener);
      }
      viewer.trackedEntity = undefined;

      if (aircraft.properties.bounds !== undefined) {
        var rectangle = viewer.entities.getById('trackRegion').rectangle.coordinates._value;
        var boundingSphere = Cesium.BoundingSphere.fromRectangle3D(rectangle);
        var offset = new Cesium.HeadingPitchRange(headingInDegrees, pitchInDegrees, 0);

        viewer.camera.viewBoundingSphere(boundingSphere, offset);
      }
    }

    //  handles updates for tracking views (right, left, ride along, cockpit)
    function trackingViewHandler(thisListener) {
      if (listener !== undefined) {
        viewer.clock.onTick.removeEventListener(listener);
      }
      viewer.trackedEntity = undefined;
      listener = thisListener;
      viewer.clock.onTick.addEventListener(listener);
    }

    /////////////////////////////////////////////////////////////////////////////////////

    /**
     * Loads CZML data and stores them within the fields of an object. 
     */
    async function load_cesium(czml) {

      //Update the czml position based on terrain data
      let srcPositions = czml[1]["position"]["cartographicDegrees"];

      let positions = [];
      let times = [];
      for (let i = 0; i < srcPositions.length - 3; i += 4) {
        times.push(srcPositions[i]);
        const cart = Cesium.Cartographic.fromDegrees(srcPositions[i + 1], srcPositions[i + 2], srcPositions[i + 3]);
        const p = new Cesium.Cartographic(cart.longitude, cart.latitude, cart.altitude);
        positions.push(p);
      }

      // Update scale and height 
      // 30, 10 are good
      const updateScale = 10;
      const updateHelicopterHeight = 24;
      const leadTime = 0;
      const trailTime = 500;
      
      const updatePositions = await Cesium.sampleTerrain(viewer.terrainProvider, 11, positions);
      positions = [];
      times.forEach((t, i) => {
        positions.push(t, srcPositions[i * 4 + 1], srcPositions[i * 4 + 2], updatePositions[i].height + srcPositions[i * 4 + 3]-updateHelicopterHeight);
      });
      czml[1].position.cartographicDegrees = positions;
      
      //Change the scale to be smaller
      czml[1].model.scale = updateScale;
      czml[1].path.leadTime = leadTime;
      czml[1].path.trailTime = trailTime;

      // Load the updated flight czml into 3D viewer
      viewer.dataSources
        .add(Cesium.CzmlDataSource.load(czml))
        .then(function(dataSource) {

          // viewer.trackedEntity = dataSource.entities.getById("path");

          /*Fills a arrays with the data from the CZML*/
          tiger = dataSource.entities.getById('path');
          aircraft = tiger;

          ///////////////Flyto the start point////////////////////////////////////
          var pathData = Object.values(czml).find(item => item.id === 'path');
          positionData = pathData['position']['cartographicDegrees'];
          // console.log(positionData);
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(positionData[1], positionData[2], 5000.0),
          });

          ///////Implement function of the "Home" button to set the beginning point///////
          var availability = pathData['availability'];
          var timeStartEnd = availability.split("/");
          availabilityStart = timeStartEnd[0];

          viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
            function(e) {
              e.cancel = true;
              viewer.scene.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(positionData[1], positionData[2], 5000.0),
              });
              viewer.clock.currentTime = Cesium.JulianDate.fromIso8601(availabilityStart);
              viewer.timeline.updateFromClock();
              viewer.trackedEntity = aircraft;

            });
          //////////////////////////////////////////////////////////////////////////

          viewer.trackedEntity = aircraft;


          // var availabilityEnd = timeStartEnd[1];
          // console.log(availabilityStart);
          // console.log(availabilityEnd);

          // viewer.clock.clockRange.startTime = Cesium.JulianDate.fromIso8601(availabilityStart);
          // viewer.clock.clockRange.stopTime = Cesium.JulianDate.fromIso8601(availabilityEnd);

          //////////////Make the timeline just run once without loop/////////////////
          viewer.clock.clockRange = Cesium.ClockRange.CLAMPED;

          //////////////Add buildings and landmarks//////////////////////////////////
          const osmBuildings = viewer.scene.primitives.add(Cesium.createOsmBuildings());

          ////////////////////////////////////////////////////////////////
          //The light will follow the camera's direction
          viewer.scene.light = new Cesium.DirectionalLight({
            direction: viewer.scene.camera.directionWC,
          });
          viewer.scene.preRender.addEventListener(function(scene, time) {
            viewer.scene.light.direction = Cesium.Cartesian3.clone(
              viewer.scene.camera.directionWC,
              viewer.scene.light.direction
            );
          });
          //////////////////////////////////////////////////////////////

          heliHPRdata = tiger.properties.headingPitchRoll.valueOf().values;
          // console.log(heliHPRdata);
          // console.log(lookup);
          // console.log(vizData);
          // gaugeStatusData = tiger.properties.gauges.valueOf().values;
          // console.log(gaugeStatusData);

          let totalInfoItems = 24;
          gaugeStatus = {
            // time: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 1, totalInfoItems),
            // lat: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 2, totalInfoItems),
            // long: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 3, totalInfoItems),
            // agl: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 4, totalInfoItems),
            // pitch: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 5, totalInfoItems),
            // roll: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 6, totalInfoItems),
            // yaw: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 7, totalInfoItems),
            // groundspeed: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 9, totalInfoItems),
            // heading: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 8, totalInfoItems),
            // verticalspeed: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 10, totalInfoItems),
            // gpsAltitude: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 11, totalInfoItems),
            // airspeed: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 12, totalInfoItems),
            // e1Torque: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 13, totalInfoItems),
            // // e2Torque: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 14, totalInfoItems),
            // mainRotorRpm: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 14, totalInfoItems),
            // // tailRotorRpm: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 16, totalInfoItems),
            // gearDown: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 15, totalInfoItems),
            // pilotRadioAltitude: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 16, totalInfoItems),
            // hasWeightOnWheels: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 17, totalInfoItems),
            // cyclicPositionPitch: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 18, totalInfoItems),
            // cyclicPositionRoll: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 19, totalInfoItems),
            // antiTorquePedalPosition: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 20, totalInfoItems),
            // collectivePosition: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 21, totalInfoItems),
            // exceedanceType: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 22, totalInfoItems),
            // exceedanceSeverity: extractObjectSamplesStep(gaugeStatusData, viewer.clock.startTime, 23, totalInfoItems),
            time: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.human_readable_datetime, lookup.times_timestamp),
            lat: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_location_latitude, lookup.times_timestamp),
            long: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_location_longitude, lookup.times_timestamp),
            agl: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.final_agl, lookup.times_timestamp),
            pitch: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_position_pitch, lookup.times_timestamp),
            roll: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_position_roll, lookup.times_timestamp),
            // yaw: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_position_yaw, lookup.times_timestamp),
            yaw: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_rates_yawrate, lookup.times_timestamp),
            groundspeed: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.groundspeed_final_kt, lookup.times_timestamp),
            heading: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_position_magneticheading, lookup.times_timestamp),
            verticalspeed: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.verticalspeed_final_fpm, lookup.times_timestamp),
            gpsAltitude: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_altitudes_gpsaltitude, lookup.times_timestamp),
            airspeed: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.true_airspeed_final_kt, lookup.times_timestamp),
            e1Torque: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.engines_computations_e1torque, lookup.times_timestamp),
            mainRotorRpm: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.rotors_mainrotorrpm, lookup.times_timestamp),
            gearDown: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.airframe_haslandinggeardown, lookup.times_timestamp),
            pilotRadioAltitude: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightstate_altitudes_pilotradioaltitude, lookup.times_timestamp),
            hasWeightOnWheels: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.airframe_hasweightonwheels, lookup.times_timestamp),
            cyclicPositionPitch: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightcontrols_cyclicpositionpitch, lookup.times_timestamp),
            cyclicPositionRoll: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightcontrols_cyclicpositionroll, lookup.times_timestamp),
            antiTorquePedalPosition: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightcontrols_antitorquepedalposition, lookup.times_timestamp),
            collectivePosition: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.flightcontrols_collectiveposition, lookup.times_timestamp),
            exceedanceType: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.exceedance_list_str, lookup.times_timestamp),
            exceedanceSeverity: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.exceedance_severity_str, lookup.times_timestamp),
            locType: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.loc_list_str, lookup.times_timestamp),
            locSeverity: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, "loc_severity", lookup.times_timestamp),
            pof: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.phaseofflight_mavg10_str, lookup.times_timestamp),
            vrs: extractVizDataTargetSamplesStep(vizData, viewer.clock.startTime, lookup.vrs_str, lookup.times_timestamp)
          };

          // console.log(gaugeStatus);

          //  create new entity around the aircraft
          var bounds = tiger.properties.bounds;
          var trackedRegion = createTrackedRegion(bounds);
          viewer.entities.add(trackedRegion);

          heliHPR = extractQuaternion(heliHPRdata, positionData, viewer.clock.startTime); //Takes HPR data from CZML.

          //// Add exceedance event balls///
          // for (let i = 0; i < flightData.length; i++) {
          //   const dataPoint = flightData[i];

          //   viewer.entities.add({
          //     description: `Location: (${dataPoint.longitude}, ${dataPoint.latitude}, ${dataPoint.height})`,
          //     position: Cesium.Cartesian3.fromDegrees(dataPoint.longitude, dataPoint.latitude, dataPoint.height),
          //     point: { pixelSize: 10, color: Cesium.Color.RED }
          //   });
          // }

          ready = true; //initializations have been completed.


          // dataStream = dataSource.entities.getById('needle0');
          // engine0Torque = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle1');
          // engine1Torque = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle2');
          // airSpeedGauge = dataStream.properties.valueArray.valueOf().values;
          // // console.log(airSpeedGauge);

          // dataStream = dataSource.entities.getById('needle3');
          // engineOilPressure = dataStream.properties.valueArray.valueOf().values;
          // // console.log(engineOilPressure);

          // dataStream = dataSource.entities.getById('needle4');
          // engineOilTemp = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle5');
          // fuelFlow = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle6');
          // xMSNPressure = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle7');
          // xMSNTemp = dataStream.properties.valueArray.valueOf().values;

          // // console.log(xMSNTemp);

          // dataStream = dataSource.entities.getById('needle8');
          // mainFuelWeight = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle9');
          // n1NGGauge = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle10');
          // turbineTemp = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle11');
          // altitudeRateGauge = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle12');
          // tachometerN2R1 = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle13');
          // tachometerN2R2 = dataStream.properties.valueArray.valueOf().values;

          // dataStream = dataSource.entities.getById('needle14');
          // tachometerRotorRPM = dataStream.properties.valueArray.valueOf().values;

          // /*Fields of the gauge collection being set to the dataSamples*/
          // gaugeCollection = {
          //   engine0Torque: extractSamples(engine0Torque, viewer.clock.startTime),
          //   engine1Torque: extractSamples(engine1Torque, viewer.clock.startTime),
          //   airSpeedGauge: extractSamples(airSpeedGauge, viewer.clock.startTime),
          //   engineOilPressure: extractSamples(engineOilPressure, viewer.clock.startTime),
          //   engineOilTemp: extractSamples(engineOilTemp, viewer.clock.startTime),
          //   fuelFlow: extractSamples(fuelFlow, viewer.clock.startTime),
          //   xMSNPressure: extractSamples(xMSNPressure, viewer.clock.startTime),
          //   xMSNTemp: extractSamples(xMSNTemp, viewer.clock.startTime),
          //   mainFuelWeight: extractSamples(mainFuelWeight, viewer.clock.startTime),
          //   n1NGGauge: extractSamples(n1NGGauge, viewer.clock.startTime),
          //   turbineTemp: extractSamples(turbineTemp, viewer.clock.startTime),
          //   altitudeRateGauge: extractSamples(altitudeRateGauge, viewer.clock.startTime),
          //   tachometerN2R1: extractSamples(tachometerN2R1, viewer.clock.startTime),
          //   tachometerN2R2: extractSamples(tachometerN2R2, viewer.clock.startTime),
          //   tachometerRotorRPM: extractSamples(tachometerRotorRPM, viewer.clock.startTime)
          // };

        });

    }

    function createTrackedRegion(bounds) {

      var maxLat = parseFloat(bounds._value.maxLatitude);
      var minLat = parseFloat(bounds._value.minLatitude);
      var maxLon = parseFloat(bounds._value.maxLongitude);
      var minLon = parseFloat(bounds._value.minLongitude);
      var maxAlt = parseFloat(bounds._value.maxAltitude);
      var minAlt = parseFloat(bounds._value.minAltitude);

      var rectangle = Cesium.Rectangle.fromDegrees(minLon, minLat, maxLon, maxLat, new Cesium.Rectangle());
      var cartographicCenter = Cesium.Rectangle.center(rectangle, new Cesium.Cartographic());
      var center = Cesium.Cartesian3.fromRadians(cartographicCenter.longitude, cartographicCenter.latitude, cartographicCenter.height);

      //  create new entity
      return {
        name: 'Track Region',
        id: 'trackRegion',
        position: center,
        rectangle: {
          coordinates: rectangle,
          extrudedHeight: maxAlt,
          height: minAlt,
          fill: false,
          outline: false
        }
      };
    }

    /**
     ////////////Takes the values from an array and adds it to a SampledProperty and returns it.///////////
     */
    function extractSamples(properties, startTime) {
      var time = startTime.clone(); //Clones start time
      var data = new Cesium.SampledProperty(Number); ////Initializes SampledProperty

      /* Sets interpolations */
      data.setInterpolationOptions({
        interpolationDegree: 5,
        interpolationAlgorithm: Cesium.LagrangePolynomialApproximation
      });

      var counter = 0; //Added to start time ensuring each data will be matched with the proper time.

      /*Loops through properties adding each value data with its respective time*/
      for (var i = 0; i < properties.length; i++) {
        Cesium.JulianDate.addSeconds(startTime, counter, time); //"Incements" time
        data.addSample(time.clone(), parseFloat(properties[i])); //Adds the data as a float.
        counter++;
      }
      return data;
    }

    function extractSamplesStep0(properties, startTime, index, step) {
      var time = startTime.clone();
      var data = new Cesium.SampledProperty(Number);

      data.setInterpolationOptions({
        interpolationDegree: 5,
        interpolationAlgorithm: Cesium.LagrangePolynomialApproximation
      });

      var counter = 0;

      //  load the data from the properties
      for (var i = 0; i < properties.length; i += step) {
        Cesium.JulianDate.addSeconds(startTime, counter, time);
        let propValue = parseFloat(properties[i + index]);

        data.addSample(time.clone(), propValue);
        counter++;
      }

      return data;
    }

    function extractSamplesStep(properties, startTime, index, step) {
      var time = startTime.clone();
      var data = new Cesium.SampledProperty(Number);

      data.setInterpolationOptions({
        interpolationDegree: 5,
        interpolationAlgorithm: Cesium.LagrangePolynomialApproximation
      });

      //  load the data from the properties
      let sectionNum = Math.round(properties.length / step);

      for (var j = 0; j < sectionNum; j++) {

        Cesium.JulianDate.addSeconds(startTime, j, time);

        let itemValueOrder = j * step + index;
        let itemValue = parseFloat(properties[itemValueOrder]);

        data.addSample(time.clone(), itemValue);
      }

      return data;
    }

    /********************************************************************
     * Create TimeIntervalCollectionProperty for Object/String properties
     *********************************************************************/
    function cloneObject(value, result) {
      return {
        value: value.value
      };
    }

    function extractObjectSamplesStep(properties, startTime, index, step) {

      var composite = new Cesium.TimeIntervalCollectionProperty(cloneObject);

      //  load the data from the properties
      let sectionNum = Math.round(properties.length / step);

      for (var j = 0; j < sectionNum; j++) {
        let timeValueOrder = j * step + 1;
        let timeValue = properties[timeValueOrder];

        let itemValueOrder = j * step + index;
        let itemValue = properties[itemValueOrder];

        var timeStr = ""
        if (j == 0)
          timeStr = availabilityStart + "/" + timeValue;
        else {
          let preOrder = (j - 1) * step + 1;
          timeStr = properties[preOrder] + "/" + timeValue;
        }

        var itemObject = {
          value: itemValue
        };

        var interval = Cesium.TimeInterval.fromIso8601({
          iso8601: timeStr,
          isStartIncluded: true,
          isStopIncluded: false,
          data: itemObject
        });

        composite.intervals.addInterval(interval);

      }

      return composite;
    }

    function timestampToSecondZ(time_str) {

      let lastdot = time_str.lastIndexOf(".");
      var time_z;
      if (lastdot > 0)
        time_z = time_str.substring(0, lastdot).replace(" ", "T") + "Z";
      else
        time_z = time_str.replace(" ", "T") + "Z";

      return time_z;
    }

    function extractVizDataTargetSamplesStep(data, startTime, dataIndexStr, timeIndexStr) {

      var composite = new Cesium.TimeIntervalCollectionProperty(cloneObject);


      let heading = 0;
      let cardinals = new Set([0, 90, 180, 270, 360]);

      //Since the data comes from DataContext memory (same as the json file in leaf-viz), it is json format, it uses 'null' instead of 'None'

      for (var j = 0; j < data.length; j++) {
        let featureData = data[j];

        // let timeValue = featureData[timeIndexStr].replace(" ", "T") + "Z";
        let timeValue = timestampToSecondZ(featureData[timeIndexStr]);

        var itemValue = "";
        if (dataIndexStr === lookup.exceedance_severity_str) {
          let exceedanceSeverity = featureData[lookup.exceedance_list_str] === null ? "None" : featureData[lookup.exceedance_severity_str];
          itemValue = exceedanceSeverity;
        }
        else if (dataIndexStr === "loc_severity") {
          let locServerity = featureData[lookup.loc_list_str] === null ? "None" : featureData[lookup.exceedance_severity_str];
          itemValue = locServerity;
        }
        else {
          itemValue = featureData[dataIndexStr];
        }

        if (dataIndexStr === lookup.flightstate_position_magneticheading) {
          let point = data[j];
          let newHeading = point[lookup.flightstate_position_magneticheading];

          if (newHeading !== null) {
            heading = newHeading;
          }
          else {
            // Need to extrapolate heading based on current position (comparing current point lat/lng to next point)
            newHeading = 0;
            if (j + 1 < data.length) {
              // Look ahead at next point to determine the heading
              let nextPoint = data[j + 1];
              newHeading = _angleFromCoordinate(
                [point[lookup.flightstate_location_latitude], point[lookup.flightstate_location_longitude]],
                [nextPoint[lookup.flightstate_location_latitude], nextPoint[lookup.flightstate_location_longitude]],
              );
            }
            else {
              // Use the same calculation from the previous to current point
              if (j !== 0) {
                let prevPoint = data[j - 1];
                newHeading = _angleFromCoordinate(
                  [prevPoint[lookup.flightstate_location_latitude], prevPoint[lookup.flightstate_location_longitude]],
                  [point[lookup.flightstate_location_latitude], point[lookup.flightstate_location_longitude]],
                );
              }
            }

            // Smooth heading deltas and fix jerky directional changes
            if (newHeading !== 0 && newHeading !== 360) {
              // Handle corner case for sharp turns in flight and don't allow jumps to cardinal directions
              if (!cardinals.has(Math.round(newHeading))) {
                // console.log("New heading:", newHeading);
                heading = newHeading;
              }
            }
          }
          itemValue = heading;

        }

        var timeStr = ""
        if (j == 0)
          timeStr = availabilityStart + "/" + timeValue;
        else {
          let preFeatureData = data[j - 1];
          // timeStr = preFeatureData[timeIndexStr].replace(" ", "T") + "Z" + "/" + timeValue;
          timeStr = timestampToSecondZ(preFeatureData[timeIndexStr]) + "/" + timeValue;
        }

        var itemObject = {
          value: itemValue
        };

        var interval = Cesium.TimeInterval.fromIso8601({
          iso8601: timeStr,
          isStartIncluded: true,
          isStopIncluded: false,
          data: itemObject
        });

        composite.intervals.addInterval(interval);

      }

      return composite;
    }

    /**
     * Takes the values from an array and adds it to a SampledProperty and returns it.
     * The data is specifically used for the orientations of an entity(helicopter).
     */
    function extractQuaternion(rotations, positions, startTime) {

      var time = startTime.clone(); //Clones start time
      var data = new Cesium.SampledProperty(Cesium.Cartesian3); //Initializes SampledProperty
      var result = new Cesium.Cartesian3(0, 0, 0); //Resulting Cartesian3 holding a heading pitch and roll in radians.

      /* Sets interpolations */
      data.setInterpolationOptions({
        interpolationDegree: 5,
        interpolationAlgorithm: Cesium.LagrangePolynomialApproximation
      });

      var counter = 0; //Added to start time ensuring each data will be matched with the proper time.

      /*Loops through properties adding each value data with its respective time*/
      for (var i = 1; i < rotations.length; i = i + 4) {
        // console.log(rotations[i] + " ," + rotations[i+1] + " ," + rotations[i+2]);

        Cesium.JulianDate.addSeconds(startTime, counter, time); //"Incements" time
        // Cesium.JulianDate.addSeconds(startTime, rotations[i-1], time); //"Incements" time

        let heading = parseFloat(rotations[i]);
        if (heading == 0.0 && i < rotations.length - 4) {
          let idx1 = i;
          let coord1 = [positions[idx1 + 1], positions[idx1]];
          let idx2 = i + 4;
          let coord2 = [positions[idx2 + 1], positions[idx2]];
          heading = _angleFromCoordinate(coord1, coord2);
        }
        else if (heading < 0) {
          heading = heading + 360;
        }

        // console.log(rotations[i] + ", " + heading);
        result = new Cesium.Cartesian3(Cesium.Math.toRadians(heading), Cesium.Math.toRadians(parseFloat(rotations[i + 1])), Cesium.Math.toRadians(parseFloat(rotations[i + 2]))); //Takes 3 values from an array and converts them into a Cartesian3 of radians(origionally degrees).
        //result = new Cesium.Cartesian3(Cesium.Math.toRadians(parseFloat(rotations[i])), Cesium.Math.toRadians(parseFloat(rotations[i + 1])), Cesium.Math.toRadians(parseFloat(rotations[i + 2]))); //Takes 3 values from an array and converts them into a Cartesian3 of radians(origionally degrees).
        data.addSample(time.clone(), result); //Adds the data.
        counter++;
      }

      return data;
    }

    /**
     * Calculate the angle/heading in degrees between two lat/lng coordinates.
     * 
     * @param {array} coord1 Lat/Lng coordinate pair for first point.
     * @param {array} coord2 Lat/Lng coordinate pair for second point.
     * @returns Angle/Heading in degrees between the two coordinates.
     */
    function _angleFromCoordinate0(coord1, coord2) {
      const [lat1, long1] = coord1;
      const [lat2, long2] = coord2;
      let dLon = (long2 - long1);

      let y = Math.sin(dLon) * Math.cos(lat2);
      let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

      let brng = Math.atan2(y, x);

      brng = brng * (180 / Math.PI); // Convert radians to degrees
      brng = (brng + 360) % 360;
      brng = 360 - brng; // Count degrees counter-clockwise - remove to make clockwise

      return brng;
    }

    function _angleFromCoordinate(coord1, coord2) {
      const TWOPI = 6.2831853071795865;
      const RAD2DEG = 57.2957795130823209;

      const [lat1, long1] = coord1;
      const [lat2, long2] = coord2;


      // if (a1 = b1 and a2 = b2) throw an error
      if (long2 == long1 && lat2 == lat1) return 0.0;
      else {
        let theta = Math.atan2(long2 - long1, lat2 - lat1);
        if (theta < 0.0)
          theta += TWOPI;
        return RAD2DEG * theta;
      }
    }

    /**
     * Takes a specified data and returns the orientation the gauges needle has to be at the current time.
     * Returns -1 if the data is invalid.
     */
    function pullData(data, posSet, clock) {
      if (data !== undefined && !isNaN(data.getValue(clock.currentTime))) //makes sure that the value in data is defined and is a number.
      {
        var gaugeNum = data.getValue(clock.currentTime); //Takes the value for the current time.
        var hpr = new Cesium.HeadingPitchRoll(cHeading, cPitch, cRoll + gaugeNum); //Sets hpr to face camera and creates an offset to show the gauge needle rotate.
        var orientation = Cesium.Transforms.headingPitchRollQuaternion(posSet, hpr); //creates quaternion with the hpr and given position.
        return orientation;
      }

      return -1;
    }

    // var activeGaugeFunctions = [
    //     function(newPos, clock) {
    //         var orientation2;
    //         var orientation3;
    //         var orientation4;

    //         /*Sets the orientation of the gauge body */
    //         var hpr = new Cesium.HeadingPitchRoll(cHeading, cPitch, cRoll); //Sets hpr to face the camera.
    //         var orientation1 = Cesium.Transforms.headingPitchRollQuaternion(newPos, hpr); //Creates quaternion from hpr.
    //         gaugeAnimate(viewer.entities.getById('1'), newPos, orientation1); //Animetes gauge body.

    //         if (pullData(gaugeCollection.xMSNTemp, newPos, clock) !== -1) {
    //             orientation2 = pullData(gaugeCollection.xMSNTemp, newPos, clock);
    //             gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
    //         }
    //         if (pullData(gaugeCollection.xMSNPressure, newPos, clock) !== -1) {
    //             orientation3 = pullData(gaugeCollection.xMSNPressure, newPos, clock);
    //             gaugeAnimate(viewer.entities.getById('3'), newPos, orientation3);
    //         }
    //     }
    // ];

    var runGauge = function(element) {
      // console.log("CurrentGaugeCount: " + CurrentGaugeCount);
      //0.15 is for scale gaugeScale 70
      if (CurrentGaugeCount < 3) {
        Cesium.Cartesian3.multiplyByScalar(camera.upWC, gaugeTopScalar - (CurrentGaugeCount * gaugeGapScale), neuteredUp); //Takes the up vector from camera and scales it.
      }
      else {
        Cesium.Cartesian3.multiplyByScalar(camera.upWC, gaugeTopScalar - ((CurrentGaugeCount - 3) * gaugeGapScale), neuteredUp); //Takes the up vector from camera and scales it.
      }
      if (CurrentGaugeCount < 3) {
        Cesium.Cartesian3.multiplyByScalar(camera.rightWC, -gaugeRightScalar, neuteredLeft); //Takes the right vector from camera and scales it. (It will point left)
      }
      else {
        Cesium.Cartesian3.multiplyByScalar(camera.rightWC, gaugeRightScalar, neuteredLeft); //Takes the right vector from camera and scales it. (It will point left)
      }
      var ForwardAndUp = new Cesium.Cartesian3(0, 0, 0); //Will be vector in between the scaled forward and up vectors.
      var BisectAndLeft = new Cesium.Cartesian3(0, 0, 0); //Will be vector in between the scaled forward and up vectors.
      Cesium.Cartesian3.add(camera.directionWC, neuteredUp, ForwardAndUp); //Calculates vector in between.
      Cesium.Cartesian3.add(ForwardAndUp, neuteredLeft, BisectAndLeft); //Calculates vector in between.
      Cesium.Cartesian3.normalize(BisectAndLeft, BisectAndLeft); //Normalizes the vectors.

      var scaledDirection = new Cesium.Cartesian3(0, 0, 0); //Increased magnitude of the normalized vector.
      Cesium.Cartesian3.multiplyByScalar(BisectAndLeft, 50, scaledDirection); //Increase magnitude.
      Cesium.Cartesian3.add(camera.positionWC, scaledDirection, scaledDirection); //Uses scaled direction as an offset to the cameras current position.
      newPos = new Cesium.Cartesian3(scaledDirection.x, scaledDirection.y, scaledDirection.z); //Creates a new Cartesian3 with the new vector.
      cHeading = camera.heading + Cesium.Math.PI_OVER_TWO; //Set to the camera's current headin + an offset due to the gauge's initial orientation.
      cPitch = -1 * camera.pitch; //Set to the negative pitch of the camera.
      cRoll = camera.roll; //Set to the camera's roll.

      var hpr = new Cesium.HeadingPitchRoll(cHeading, cPitch, cRoll);
      // console.log(element.id + "," + element.show);

      if (!element.id.includes('Body')) {
        switch (element.id) {
          case 'XMSNTNeedle':
            // console.log(gaugeCollection.xMSNTemp);
            if (pullData(gaugeCollection.xMSNTemp, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.xMSNTemp, newPos, mainClock));
            }
            break;
          case 'XMSNPNeedle':
            if (pullData(gaugeCollection.xMSNPressure, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.xMSNPressure, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }

            break;
          case "EngOilPNeedle":
            if (pullData(gaugeCollection.engineOilPressure, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.engineOilPressure, newPos, mainClock));
            }
            break;
          case "EngOilTNeedle":
            if (pullData(gaugeCollection.engineOilTemp, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.engineOilTemp, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "KnotNeedle":
            if (pullData(gaugeCollection.airSpeedGauge, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.airSpeedGauge, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "FuelNeedle":
            if (pullData(gaugeCollection.mainFuelWeight, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.mainFuelWeight, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "VertSpeedNeedle":
            if (pullData(gaugeCollection.altitudeRateGauge, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.altitudeRateGauge, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "TachoSNeedle":
            if (pullData(gaugeCollection.tachometerRotorRPM, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.tachometerRotorRPM, newPos, mainClock));
            }
            break;
          case "Tacho1Needle":
            if (pullData(gaugeCollection.tachometerN2R1, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.tachometerN2R1, newPos, mainClock));
            }
            break;
          case "Tacho2Needle":
            if (pullData(gaugeCollection.tachometerN2R2, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.tachometerN2R2, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "Torque1Needle":
            if (pullData(gaugeCollection.engine0Torque, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.engine0Torque, newPos, mainClock));
            }
            break;
          case "Torque2Needle":
            if (pullData(gaugeCollection.engine1Torque, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.engine1Torque, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "FlowNeedle":
            if (pullData(gaugeCollection.fuelFlow, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.fuelFlow, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "T5Needle":
            if (pullData(gaugeCollection.turbineTemp, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.turbineTemp, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;
              }
            }
            break;
          case "N1Needle":
            if (pullData(gaugeCollection.n1NGGauge, newPos, mainClock) !== -1) {
              gaugeAnimate(element, newPos, pullData(gaugeCollection.n1NGGauge, newPos, mainClock));
              if (element.show) {
                CurrentGaugeCount++;

              }
            }
            break;
        }
      }
      else {
        gaugeAnimate(element, newPos, Cesium.Transforms.headingPitchRollQuaternion(newPos, hpr));
      }

    };

    /**
     * Changes the orientation of the chosen gauge.
     */
    function chooseData(Id, newPos, clock) {
      /*Orientation place holders */
      var orientation2;
      var orientation3;
      var orientation4;

      /*Sets the orientation of the gauge body */
      var hpr = new Cesium.HeadingPitchRoll(cHeading, cPitch, cRoll); //Sets hpr to face the camera.
      var orientation1 = Cesium.Transforms.headingPitchRollQuaternion(newPos, hpr); //Creates quaternion from hpr.
      gaugeAnimate(viewer.entities.getById('1'), newPos, orientation1); //Animetes gauge body.

      /*Takes specified gauge needles and will rotate them according to colculated orientations */
      switch (Id) {
        case 0:
          if (pullData(gaugeCollection.xMSNTemp, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.xMSNTemp, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          if (pullData(gaugeCollection.xMSNPressure, newPos, clock) !== -1) {
            orientation3 = pullData(gaugeCollection.xMSNPressure, newPos, clock);
            gaugeAnimate(viewer.entities.getById('3'), newPos, orientation3);
          }

          break;
        case 1:
          if (pullData(gaugeCollection.engineOilPressure, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.engineOilPressure, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          if (pullData(gaugeCollection.engineOilTemp, newPos, clock) !== -1) {
            orientation3 = pullData(gaugeCollection.engineOilTemp, newPos, clock);
            gaugeAnimate(viewer.entities.getById('3'), newPos, orientation3);
          }
          break;
        case 2:
          if (pullData(gaugeCollection.airSpeedGauge, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.airSpeedGauge, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          break;
        case 3:
          if (pullData(gaugeCollection.mainFuelWeight, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.mainFuelWeight, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          break;
        case 4:
          if (pullData(gaugeCollection.altitudeRateGauge, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.altitudeRateGauge, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          break;
        case 5:
          if (pullData(gaugeCollection.tachometerRotorRPM, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.tachometerRotorRPM, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          if (pullData(gaugeCollection.tachometerN2R1, newPos, clock) !== -1) {
            orientation3 = pullData(gaugeCollection.tachometerN2R1, newPos, clock);
            gaugeAnimate(viewer.entities.getById('3'), newPos, orientation3);
          }
          if (pullData(gaugeCollection.tachometerN2R2, newPos, clock) !== -1) {
            orientation3 = pullData(gaugeCollection.tachometerN2R2, newPos, clock);
            gaugeAnimate(viewer.entities.getById('3'), newPos, orientation3);
          }
          break;
        case 6:
          if (pullData(gaugeCollection.engine0Torque, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.engine0Torque, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          if (pullData(gaugeCollection.engine1Torque, newPos, clock) !== -1) {
            orientation3 = pullData(gaugeCollection.engine1Torque, newPos, clock);
            gaugeAnimate(viewer.entities.getById('3'), newPos, orientation3);
          }
          break;
        case 7:
          if (pullData(gaugeCollection.fuelFlow, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.fuelFlow, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          break;
        case 8:
          if (pullData(gaugeCollection.turbineTemp, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.turbineTemp, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          break;
        case 9:
          if (pullData(gaugeCollection.n1NGGauge, newPos, clock) !== -1) {
            orientation2 = pullData(gaugeCollection.n1NGGauge, newPos, clock);
            gaugeAnimate(viewer.entities.getById('2'), newPos, orientation2);
          }
          break;
      }
    }

    /**
     * Creates an entity and adds it to the viewer. The model is created with the given URL.
     */
    function createModel(url, scale1, id) {

      var position1 = new Cesium.Cartesian3(0.0, 0, 0);
      var modelURI = url;
      var hpr = new Cesium.HeadingPitchRoll(0, 0, 0);
      var orientation1 = Cesium.Transforms.headingPitchRollQuaternion(position1, hpr);
      var tempEntity = viewer.entities.add({
        orientation: orientation1,
        position: position1,
        id: id,
        model: {
          uri: modelURI,
          minimumPixelSize: 1024,
          maximumScale: scale1,
          scale: scale1
        },
        show: false
      });
      return tempEntity;
    }

    function generateCZML() {

      var czml = [];

      let starttime_str = vizData[0][lookup.flightmetadata_starttime];
      // let lastdot = starttime_str.lastIndexOf(".");
      // var meta_starttime_z;
      // if(lastdot > 0)
      //   meta_starttime_z = starttime_str.substring(0, lastdot).replace(" ", "T") + "Z";
      // else
      //   meta_starttime_z = starttime_str.replace(" ", "T") + "Z";
      var meta_starttime_z = timestampToSecondZ(starttime_str);

      let endtime_str = vizData[0][lookup.flightmetadata_endtime];
      // lastdot = endtime_str.lastIndexOf(".");
      // var meta_endtime_z;
      // if(lastdot > 0)
      //   meta_endtime_z = endtime_str.substring(0, lastdot).replace(" ", "T") + "Z";
      // else
      //   meta_endtime_z = endtime_str.replace(" ", "T") + "Z";
      var meta_endtime_z = timestampToSecondZ(endtime_str);


      //var meta_starttime_z = vizData[0][lookup.times_timestamp].replace(" ", "T") + "Z";
      //var meta_endtime_z = vizData[vizData.length-1][lookup.flightmetadata_endtime].replace(" ", "T") + "Z";

      var firstPacket = {}
      firstPacket["id"] = "document"
      firstPacket["name"] = "CZML flight"
      firstPacket["version"] = "1.0"

      var clockObj = {}
      clockObj["interval"] = meta_starttime_z + "/" + meta_endtime_z
      clockObj["currentTime"] = meta_starttime_z
      clockObj["multiplier"] = 10
      firstPacket["clock"] = clockObj

      czml.push(firstPacket)

      var starttime_epoch = Date.parse(meta_starttime_z) / 1000;

      console.log(starttime_epoch);

      var pathPackage = {};
      pathPackage["id"] = "path";
      pathPackage["name"] = "path with GPS flight data";
      pathPackage["description"] = "Government";
      pathPackage["availability"] = meta_starttime_z + "/" + meta_endtime_z;

      var path = {};
      var color = {};
      color["rgba"] = [255, 255, 0, 255];

      var outlineColor = {};
      outlineColor["rgba"] = [0, 0, 0, 255];

      var polylineOutline = {};
      polylineOutline["color"] = color;
      polylineOutline["outlineColor"] = outlineColor;
      polylineOutline["outlineWidth"] = 2;

      var material = {};
      material["polylineOutline"] = polylineOutline;

      path["material"] = material;
      path["width"] = 5;
      path["leadTime"] = -2;
      path["trailTime"] = 1000;
      path["resolution"] = 60;

      pathPackage["path"] = path;

      var model = {};
      model["gltf"] = "./models/chopper_red.glb";
      model["scale"] = 60;
      model["minimumPixelSize"] = 128;
      model["maximumScale"] = 100.0;
      pathPackage["model"] = model;

      var position = {};
      position["epoch"] = meta_starttime_z;

      var positionList = [];
      var orientationList = [];

      var minAltitude = 100000.0;
      var maxAltitude = -100000.0;
      var minLat = 100.0;
      var minLon = 1000.0;
      var maxLat = -1000.0;
      var maxLon = -1000.0;

      // console.log(vizData.length);

      vizData.forEach(p => {
        for (var i = 0; i < p.length; i++) {
          if (p[i] == null || String(p[i]) === 'nan' || String(p[i]) === 'None') {
            if (i === lookup.flightstate_position_trueheading || i === lookup.flightstate_position_pitch || i === lookup.flightstate_position_roll || i === lookup.flightstate_position_yaw)
              p[i] = 0.0;
            else
              p[i] = null;
          }
        }

        let current_time = p[lookup.times_timestamp];
        // lastdot = current_time.lastIndexOf(".");
        // var current_time_z;
        // if(lastdot > 0)
        //   current_time_z = current_time.substring(0, lastdot).replace(" ", "T") + "Z";
        // else
        //   current_time_z = current_time.replace(" ", "T") + "Z";
        var current_time_z = timestampToSecondZ(current_time);

        let curtime_epoch = Date.parse(current_time_z) / 1000;

        let index = parseInt(Math.floor(curtime_epoch - starttime_epoch));
        // console.log(p[lookup.times_timestamp] + ", " + curtime_epoch + ", " + starttime_epoch + ", " + index);

        positionList.push(index);
        positionList.push(p[lookup.flightstate_location_longitude]); // Longitude is the first one 
        positionList.push(p[lookup.flightstate_location_latitude]);
        positionList.push(p[lookup.final_agl] + 30.0);

        var agl = parseFloat(p[lookup.final_agl]);
        if (agl < minAltitude) minAltitude = agl;
        if (agl > maxAltitude) maxAltitude = agl;

        var lat = parseFloat(p[lookup.flightstate_location_latitude]);
        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;

        var lon = parseFloat(p[lookup.flightstate_location_longitude]);
        if (lon < minLon) minLon = lon;
        if (lon > maxLon) maxLon = lon;

        orientationList.push(String(index));
        orientationList.push(String(p[lookup.flightstate_position_trueheading]));
        orientationList.push(String(p[lookup.flightstate_position_pitch]));
        orientationList.push(String(p[lookup.flightstate_position_roll]));

      });

      position["cartographicDegrees"] = positionList;
      pathPackage["position"] = position;

      var properties = {};
      var headingPitchRoll = {};
      headingPitchRoll["values"] = orientationList;
      properties["headingPitchRoll"] = headingPitchRoll;

      var bounds = {};
      bounds["minAltitude"] = minAltitude;
      bounds["maxLongitude"] = maxLon;
      bounds["minLatitude"] = minLat;
      bounds["maxAltitude"] = maxAltitude;
      bounds["minLongitude"] = minLon;
      bounds["maxLatitude"] = maxLat;
      properties["bounds"] = bounds;

      pathPackage["properties"] = properties;

      var orientation = {}
      orientation["interpolationAlgorithm"] = "LINEAR";
      orientation["interpolationDegree"] = 1;
      orientation["epoch"] = meta_starttime_z;
      orientation["unitQuaternion"] = [0, 0.45652188368372576, -0.049580035995243577, -0.8819344359461565, 0.10640131785324795];

      pathPackage["orientation"] = orientation;

      czml.push(pathPackage);

      return czml;
    }

    /**
    * Place initializations in here:
    */
    function initialize() {
      // load_cesium('data/CZMLdataNumbers.json'); //Loads CZML data.
      // load_cesium(CZMLdataNumbers); //Loads CZML data.

      //////////////Load flight dynamically//////////////////
      let flightId = dataContext.activeFlight;
      let flightIds = [flightId];

      /////////////Get flight presigned url then get the CZML//////////////////////////
      getFlightsPresignedURL(userContext.user, flightIds, "flight_czml").then((results) => {

        if (results.length > 0) {
          var czmlJsonUrl = results[0]['url'];

          axios.get(czmlJsonUrl).then(resp => {
            //console.log(resp.data);
            load_cesium(resp.data);
          });

        }
      });

      ///////////For development//////////////////////////////
      // load_cesium(flightCZML);

      ///////////For dynamical generation//////////////////////////////
      // Since datatarget simplified the points, it may cause wrong flight status. So the data can not be used to 
      // the CZML for the flight.
      // let curCZML = generateCZML();
      // console.log(curCZML);
      // load_cesium(curCZML);

    }


    function checkTerrainAndInitialize() {

      let flg = true;
      viewer.scene.globe.tileLoadProgressEvent.addEventListener(function(queuedTileCount) {
        if (viewer.scene.globe.tilesLoaded && viewer.terrainProvider && flg) {
          flg = false;
          initialize();
        }
      });
    }

    //////////////Check Terrain loaded, then load CZML, update position, run!!////////////
    checkTerrainAndInitialize();


    /**
     * Subscribes to listener, will be called everytime the clock ticks.
     */

    viewer.clock.onTick.addEventListener(function(clock) {

      if (ready) {
        //Makes sure tiger entity is defined before using it
        if (tiger !== undefined) {
          // console.log(clock.currentTime);
          //Sets the orientation of tiger entity
          var tempCartesian = heliHPR.getValue(clock.currentTime);
          // if(tempCartesian != undefined){
          //      console.log(heliHPR);
          //      console.log(tempCartesian);

          //     var hpr = new Cesium.HeadingPitchRoll(tempCartesian.x, tempCartesian.y, tempCartesian.z);
          //     tiger.orientation = Cesium.Transforms.headingPitchRollQuaternion(tiger.position.getValue(clock.currentTime), hpr);
          //     //camera.position = tiger.position.getValue(clock.currentTime); //Follows the helicopter. Comment out for free movement.
          // }

          if (tempCartesian !== undefined) {
            var tempTigerPosition = tiger.position.getValue(clock.currentTime);
            if (tempTigerPosition != undefined) {

              var hpr = new Cesium.HeadingPitchRoll(tempCartesian.x, tempCartesian.y, tempCartesian.z);
              // console.log(tempCartesian.x +", " + tempCartesian.y + "," + tempCartesian.z)
              if (hpr != undefined) {

                // tiger.orientation = Cesium.Transforms.headingPitchRollQuaternion(tiger.position.getValue(clock.currentTime), hpr);
                tiger.orientation = Cesium.Transforms.headingPitchRollQuaternion(tempTigerPosition, hpr);
                // camera.position = tempTigerPosition; //Follows the helicopter. Comment out for free movement.
              }
            }
          }

        }

        ///////////////////////////////////////////////////////////////////////////////
        //Animates a certain gauge every clock tick. 
        //Switch the camera viewer mode by clicking the switch button in the toolbar
        mainClock = clock;
        CurrentGaugeCount = 0;
        // if (withOrWithoutGauge) {
        //   viewer.entities.values.map(runGauge);
        //   viewer.trackedEntity = null;
        // }
        // else
        //   viewer.trackedEntity = tiger;

        var paras = [
          { name: "time", display: "Time: ", value: "--", type: 2, fixed: 0 },
          { name: "lat", display: "Latitude: ", value: "--", type: 1, fixed: 6 },
          { name: "long", display: "Longitude: ", value: "--", type: 1, fixed: 6 },
          { name: "agl", display: "Altitudes AGL (ft): ", value: "--", type: 1, fixed: 0 },
          { name: "groundspeed", display: "Ground Speed (kts): ", value: "--", type: 1, fixed: 0 },
          { name: "heading", display: "Heading (deg): ", value: "--", type: 1, fixed: 2 },
          { name: "pitch", display: "Pitch (deg): ", value: "--", type: 1, fixed: 2 },
          { name: "roll", display: "Roll (deg): ", value: "--", type: 1, fixed: 2 },
          { name: "yaw", display: "Yaw Rate (deg/s): ", value: "--", type: 1, fixed: 2 },
          { name: "verticalspeed", display: "Vertical Speed (fpm): ", value: "--", type: 1, fixed: 0 },
          { name: "gpsAltitude", display: "Altitude GPS (ft): ", value: "--", type: 1, fixed: 0 },
          { name: "airspeed", display: "True Airspeed (kts ): ", value: "--", type: 1, fixed: 0 },
          { name: "e1Torque", display: "Torque (%): ", value: "--", type: 1, fixed: 0 },
          // { name: "e2Torque", display: "Engine 2 Torque (%): ", value: "--", type: 1, fixed: 0 },
          { name: "mainRotorRpm", display: "Rotor RPM (%): ", value: "--", type: 1, fixed: 0 },
          // { name: "tailRotorRpm", display: "Tail Rotor RPM (%): ", value: "--", type: 1, fixed: 0 },
          { name: "pilotRadioAltitude", display: "Radar Altitude (ft): ", value: "--", type: 1, fixed: 0 },
          { name: "gearDown", display: "Gear (Up/Down): ", value: "--", type: 2, fixed: 0 },
          { name: "hasWeightOnWheels", display: "Weight on Wheels: ", value: "--", type: 2, fixed: 0 },
          { name: "cyclicPositionPitch", display: "Cyclic Pitch (%): ", value: "--", type: 1, fixed: 0 },
          { name: "cyclicPositionRoll", display: "Cyclic Roll (%): ", value: "--", type: 1, fixed: 0 },
          { name: "antiTorquePedalPosition", display: "Antitorque Pedal (%): ", value: "--", type: 1, fixed: 0 },
          { name: "collectivePosition", display: "Collective (%): ", value: "--", type: 1, fixed: 0 },
          { name: "exceedanceType", display: "Exceedance Type(s): ", value: "--", type: 2, fixed: 0 },
          { name: "exceedanceSeverity", display: "Exceedance Severity: ", value: "--", type: 2, fixed: 0 },
          { name: "locType", display: "Loss of Control Type(s): ", value: "--", type: 2, fixed: 0 },
          { name: "locSeverity", display: "Loss of Control Severity: ", value: "--", type: 2, fixed: 0 },
          { name: "pof", display: "Phase of Flight: ", value: "--", type: 2, fixed: 0 },
          { name: "vrs", display: "VRS: ", value: "--", type: 2, fixed: 0 }

        ];

        var statusData;

        if (clock !== undefined && gaugeStatus !== undefined) {

          var currentTime = clock.currentTime;

          statusData = paras.map(item => {

            let statusObj = gaugeStatus[item.name].getValue(currentTime);
            if (typeof statusObj === 'object' && statusObj !== null) {
              let type = item.type;

              var statusValue = "--";
              if (statusObj.value == null) {
                statusValue = "None";
              }
              if (statusValue != "None") {
                if (type === 1) {
                  statusValue = String(parseFloat(statusObj.value).toFixed(item.fixed));
                }
                else
                  statusValue = String(statusObj.value);
              }

              if (item.name === "time") {
                let timeStr = String(statusObj.value);
                let timeStrNoZ = timeStr.replace('Z', ''); //Based on Brett's function in DataContext. The ISO time needs removing "Z" before using toHumanReadableDateStr()
                statusValue = dataContext.toHumanReadableDateStr(timeStrNoZ);
              }

              item.value = statusValue;

              if (item.name === "exceedanceSeverity" || item.name === "locSeverity") {
                if (item.value.includes("Low"))
                  item.class = 'font-color-yellow';
                else if (item.value.includes("Medium"))
                  item.class = 'font-color-orange';
                else if (item.value.includes("High"))
                  item.class = 'font-color-red';
                else
                  item.class = 'font-color-white';
              }
              else
                item.class = 'font-color-white';
            }
            return item;
          });

        }

        // console.log(statusData);


        var HTML = "<table class='status-table'>";
        for (var i = 0; i < statusData.length; i++) {

          //Based on Tim's suggestion, add a blank row after "heading"
          if (i == 6 || i == 21)
            HTML += "<tr class='status-th'><td class='status-td' width=180px>&nbsp;</td><td>&nbsp;</td></tr>";
          //HTML += "<tr class='status-th'><td class='status-td status-separate' width=180px>" + statusData[i].display + "</td><td class='status-td status-separate'>" + statusData[i].value + "</td></tr>";

          HTML += "<tr class='status-th'><td class='status-td' width=180px>" + statusData[i].display + "</td><td class='" + statusData[i].class + "'>" + statusData[i].value + "</td></tr>";
        }
        HTML += "</table>";
        document.getElementById("trackStatus").innerHTML = DOMPurify.sanitize(HTML);

      }
    });

    // viewer.clock.onTick.addEventListener(function(clock) {
    //     var tempCartesian = heliHPR.getValue(clock.currentTime);
    //     console.log(tempCartesian);

    //     if (tempCartesian === undefined) { return; }
    //     var tempTigerPosition = tiger.position.getValue(clock.currentTime);
    //     //console.log(tempTigerPosition);
    //     if (tempTigerPosition === undefined) { return; }

    //     var hpr = new Cesium.HeadingPitchRoll(tempCartesian.x, tempCartesian.y, tempCartesian.z);
    //     if (hpr === undefined) { return; }
    //     // console.log(hpr);

    //     tiger.orientation = Cesium.Transforms.headingPitchRollQuaternion(tiger.position.getValue(clock.currentTime), hpr);

    // });

  }


  useEffect(() => {

    let dataTarget = dataContext.filteredReducedData ? dataContext.filteredReducedData : dataContext.baseReducedData;
    if (dataTarget) {

      // Extract lookup table and data from track points or exceedance points if track points aren't available
      let trackDataExists = dataTarget.track_points && dataTarget.track_points.data && dataTarget.track_points.data.length > 0;
      let trackPointsTarget = trackDataExists ? dataTarget.track_points : dataTarget.exceedance_point || {};
      // console.log(trackPointsTarget);
      const { lookup = {}, data = [], geojson: geoJson = {} } = trackPointsTarget;

      startup(Cesium, lookup, data);
    }


  }, [dataContext.baseReducedData, dataContext.filteredReducedData]);


  return (
    <div ref={resizeDetectorRef} style={{ width: "100%", height: "100%" }}>
      
      <div id="cesiumContainer"  style={containerStyle}> </div>
        <div id="trackStatus" className="track-status">
        </div>
    </div>

  )
}

Playback3DMap.propTypes = propTypes;
Playback3DMap.defaultProps = defaultProps;

export default Playback3DMap;
